@import "../../../../libs/styles/main.scss";

.wrapper {
    display: flex;
    background-color: #F5F6FA;
    padding: $general-spacing-unit * 3;
    border-radius: $general-spacing-unit;

    &>div svg {
        height: $general-spacing-unit * 3;
        width: $general-spacing-unit * 3;
    }
}

.icon {
    margin-right: $general-spacing-unit;
    font-size: 24px;
    color: $color-secondary-enterprise;
}

.title {
    color: $color-secondary-enterprise;
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
}

.paragraph {
    font-size: 18px;
    line-height: 28px;
    text-align: left;
    color: $color-plantation;
    max-width: 500px;
    margin-bottom: 0;
}