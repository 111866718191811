@import "../../../../libs/styles/main.scss";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: max-content;

  @media(max-width: 768px) {
    min-width: min-content;
  }
}

.index {
  background-color: $color-bali-hai;
  color: $color-white;
  width: $general-spacing-unit * 5;
  min-width: $general-spacing-unit * 5;
  height: $general-spacing-unit * 5;
  line-height: $general-spacing-unit * 5;
  display: block;
  font-size: 20px;
  text-align: center;
  font-weight: $font-weight-semibold;
  border-radius: $general-spacing-unit * 5;
  margin-right: $general-spacing-unit * 1.5;

  &.active {
    background-color: $color-eastern-blue;
  }

  @include breakpoint("mobile-and-tablet") {
    width: $general-spacing-unit * 4;
    min-width: $general-spacing-unit * 4;
    height: $general-spacing-unit * 4;
    line-height: $general-spacing-unit * 4;
  }
}

.name {
  font-size: 18px;
  line-height: 1;
  font-weight: $font-weight-semibold;
  color: $color-bali-hai;

  &.active {
    color: $color-black;
  }

  @include breakpoint("mobile-and-tablet") {
    font-size: 16px;
  }
}

.secondaryLayout {
  .active:nth-child(1) {
    background-color: $color-primary-enterprise;
  }
}