@import "../../../../libs/styles/main.scss";

.wrapper {
  width: 100%;
  max-width: 1092px;
  overflow: hidden;

  @include breakpoint("mobile") {
    margin-top: 24px;
  }
}

.controls {
  margin-bottom: $general-spacing-unit * 2;
  display: flex;
  gap: $general-spacing-unit * 2;
  justify-content: center;
}

.button {
  border: 2px solid $color-white;
  background: transparent;
  height: 56px;
  width: 56px;
  border-radius: 56px;
  box-sizing: border-box;
  padding: 0;
  cursor: pointer;
  outline: none;
  transition: opacity ease 0.25s;
  transform: rotate(90deg);

  &[disabled] {
    opacity: 0.3;
    cursor: default;
  }

  &+& {
    transform: rotate(270deg);
  }
}