@import "../../../libs/styles/main.scss";

.button {
  height: 56px;
  line-height: 56px;
  border: none;
  padding: 0;
  min-width: 240px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: $color-eastern-blue;
  background: $color-jagged-ice;
  justify-content: center;
  width: 100%;

  &.liked {
    cursor: not-allowed;
    color: $color-white;
    background: $color-eastern-blue;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.50;
  }
  
  @include breakpoint("mobile") {
    min-width: min-content;
  }
}

.icon {
  margin-right: $general-spacing-unit;
  width: 22px;
  height: 20px;
}

.text {
  font-size: 18px;
  font-family: $font-source-sans;
  line-height: 1;
  font-weight: $font-weight-bold;
}
