.wrapper {
  display: flex;
  flex-direction: column;
  gap: .5rem;

  label {
    font-weight: 700;
    font-size: 12px;
    color: #222b45;
    text-transform: uppercase;
    text-align: left;
  }
}