@import "../../../../libs/styles/main.scss";

@mixin loaderDefaultStyle {
  border-radius: 5px;
  display: block;
  position: absolute;
  background: linear-gradient(225deg, $color-catskill-white, $color-catskill);
  background-size: 100% 200%;
  animation: backgroundColorAnimation 1s ease infinite;

  @keyframes backgroundColorAnimation {
    0% {
      background-position: 50% 0%;
    }

    50% {
      background-position: 50% 100%;
    }

    100% {
      background-position: 50% 0%;
    }
  }
}

.wrapper {
  display: block;
  width: 100%;
  position: relative;
  height: 205px;

  & + & {
    margin-top: $general-spacing-unit * 6;
  }

  @include breakpoint("mobile") {
    height: 175px;

    & + & {
      margin-top: $general-spacing-unit * 3;
    }
  }
}

.title {
  @include loaderDefaultStyle;

  width: 250px;
  top: 0;
  left: 0;
  height: 30px;

  @include breakpoint("mobile") {
    height: 20px;
  }
}

.company {
  @include loaderDefaultStyle;

  width: 40px;
  top: 45px;
  left: 0;
  height: 20px;

  @include breakpoint("mobile") {
    top: 30px;
  }
}

.date {
  @include loaderDefaultStyle;

  width: 140px;
  top: 45px;
  left: 50px;
  height: 20px;

  @include breakpoint("mobile") {
    top: 30px;
  }
}

.description {
  @include loaderDefaultStyle;

  width: 96%;
  top: 75px;
  left: 0;
  height: 60px;

  @include breakpoint("mobile") {
    top: 70px;
    width: 100%;
  }
}

.location {
  @include loaderDefaultStyle;

  width: 175px;
  top: 160px;
  left: 0;
  height: 30px;

  @include breakpoint("mobile") {
    top: 145px;
    width: 125px;
  }
}

.salary {
  @include loaderDefaultStyle;

  width: 100px;
  top: 160px;
  left: 185px;
  height: 30px;

  @include breakpoint("mobile") {
    top: 145px;
    width: 75px;
    left: 135px;
  }
}

.button {
  @include loaderDefaultStyle;

  width: 170px;
  top: 145px;
  right: 80px;
  height: 60px;

  @include breakpoint("mobile") {
    height: 30px;
    width: 100px;
    right: 0;
  }
}

.share {
  @include loaderDefaultStyle;

  border-radius: 100%;
  top: 145px;
  right: 0;
  width: 60px;
  height: 60px;

  @include breakpoint("mobile") {
    top: 0;
    width: 45px;
    height: 45px;
  }
}
