@import "../../libs/styles/main.scss";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
}

.title {
  width: 9rem;
  font-size: 32px;
  color: $color-cloud-burst;
  font-weight: 700;
}

.share {
  display: flex;
  align-items: center;
  gap: .25rem;
  color: #1B9B9E;
  font-weight: 700;
  cursor: pointer;
}
