@import "../../../../libs/styles/main.scss";

.wrapper {
  background: $color-swans-down;
  padding: $general-spacing-unit * 2 $general-spacing-unit * 3 $general-spacing-unit * 5;
  border-radius: 10px;
  box-sizing: border-box;
  max-width: 1092px;
  height: 313px;
}

.illustration {
  margin: 0 auto;
  display: block;
  height: 111px;
}

.text {
  font-size: 16px;
  color: $color-cloud-burst;
  letter-spacing: 0;
  text-align: center;
  line-height: 22px;
  font-weight: $font-weight-bold;
  margin: $general-spacing-unit * 1.5 auto;
  max-width: 300px;
}

.link {
  font-size: 18px;
  color: $color-surfie-green;
  border: 2px solid $color-surfie-green;
  letter-spacing: 0;
  text-align: center;
  font-weight: $font-weight-bold;
  text-decoration: none;
  display: block;
  border-radius: 5px;
  line-height: 20px;
  padding: $general-spacing-unit * 2;
  transition: all .3s;

  &:hover {
    background: $color-surfie-green;
    color: $color-white;
  }
}

.secondaryLayout {
  background: #438ecc33;

  .link {
    color: $color-primary-enterprise;
    border: 2px solid $color-primary-enterprise;
    
    &:hover {
      border: 2px solid $color-secondary-enterprise;
      background-color: $color-secondary-enterprise;
      color: $color-white;
    }
  }
}