@import "../../libs/styles/main.scss";

.wrapper {
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 56px;
  box-sizing: border-box;
  max-width: 1092px;
  margin: 70px auto;
  margin-bottom: 0;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 2px 20px 0 rgba(27, 155, 158, 0.1), 0 2px 20px 0 rgba(151, 151, 151, 0.1);


  @include breakpoint("mobile-and-tablet") {
    flex-direction: column;
    margin: 0;
    align-items: center;
  }

  @include breakpoint("mobile") {
    margin: $general-spacing-unit * 3 $general-spacing-unit * 4;
    padding: 32px 24px;
  }
}

.leftColumn {
  margin-right: 40px;
  max-width: 450px;

  &>span,
  &>h2 b {
    color: #438FCC;
  }

  @include breakpoint("mobile-and-tablet") {
    width: auto;
    max-width: max-content;
    margin-right: 0;

    &>span {
      text-align: center;
    }
  }
}

.rightColumn {
  flex: 1;
  margin-left: 40px;
  position: relative;

  @include breakpoint("mobile-and-tablet") {
    margin: 0 0 40px;
  }

  @include breakpoint("mobile") {
    margin-top: 30px;
  }
}

.image {
  object-fit: cover;
  object-position: center;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  flex: 1;
  width: 47vw;

  @include breakpoint("mobile-and-tablet") {
    position: static;
    width: 100%;
    height: auto;
  }

  @include breakpoint("tablet") {
    height: 40vw;
  }
}

.description {
  font-size: 18px;
  line-height: 28px;
  color: #253C45;
  max-width: 500px;

  @include breakpoint("mobile-and-tablet") {
    text-align: center;
  }
}

.buttonsWrapper {
  display: flex;
  flex-direction: column;
  gap: $general-spacing-unit * 2;

  @include breakpoint("mobile-and-tablet") {
    align-items: center;
  }
}

.selectBtn {
  background: transparent;
  border: 0;
  text-align: left;
  cursor: pointer;
  width: max-content;
  font-size: 20px;
  font-weight: 600;
  font-family: $font-source-sans;
  color: #6C7894;
  transition: all .1s;
  display: flex;
  align-items: center;
  gap: 4px;

  &:hover,
  &.active {
    font-weight: 700;
    color: #438FCC
  }
}