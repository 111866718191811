@import "../../../../../libs/styles/main.scss";

.wrapper {
  background: $color-white;
  box-sizing: border-box;
  padding: $general-spacing-unit * 4 $general-spacing-unit * 3;
  box-shadow: 0 2px 20px 0 rgba(27, 155, 158, 0.1),
    0 2px 20px 0 rgba(151, 151, 151, 0.1);
  border-radius: $general-spacing-unit * 2.5;
  margin: $general-spacing-unit * 3 auto;
  max-width: 1092px;
  width: calc(100% - $general-spacing-unit * 6);

  @include breakpoint("mobile") {
    max-width: 438px;
    margin: $general-spacing-unit * 3 auto;
    padding: $general-spacing-unit * 4 $general-spacing-unit * 3;
  }
}