@import "../../libs/styles/main.scss";

.location {
  font-size: 13px;
  color: #253c45;
  line-height: 13px;
  line-height: 32px;
  border-radius: 16px;
  font-weight: 600;
  padding: 0 16px;
  display: block;
  background-color: #f0f7f7;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  width: max-content;
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;
    min-width: max-content;
    vertical-align: middle;
    display: inline-block;
  }

  @include breakpoint("mobile") {
    flex: none;
    box-sizing: border-box;
    max-width: 100%;
  }
}

.icon {
  margin-top: 8px;
  margin-right: 4px;
  margin-bottom: -3px;
}