@import "../../../../libs/styles/main.scss";

.wrapper {
}

.innerWrapper {
  &.twoColumns ul {
    grid-template-columns: 1fr 1fr;

    @include breakpoint("mobile") {
      grid-template-columns: 1fr;
    }
  }

  &.threeColumns ul {
    grid-template-columns: 1fr 1fr 1fr;

    @include breakpoint("mobile") {
      grid-template-columns: 1fr;
    }
  }

  ul {
    padding: 0 !important;
    margin: 0;
    display: grid;
    row-gap: $general-spacing-unit * 2;
    column-gap: $general-spacing-unit * 2;
  }

  ul li {
    display: block;
    margin: 0;
    font-size: 18px;
    line-height: 32px;
    font-weight: $font-weight-regular;
    color: $color-cloud-burst;
  }

  a {
    color: $color-eastern-blue;
    font-weight: $font-weight-semibold;
  }
}
