@import "../../../../libs/styles/main.scss";

.wrapper {
  text-align: center;
  margin: $general-spacing-unit * 2 0 $general-spacing-unit * 3;
  line-height: $general-spacing-unit * 3.5;
  font-size: 18px;

  & + & {
    margin-top: 0;
  }

  a {
    color: $color-eastern-blue;
    font-weight: 700;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
