@import "../../libs/styles/main.scss";

.wrapper {
  position: relative;
  z-index: 1;
}

.label {
  display: block;
  font-weight: 700;
  font-size: 12px;
  color: $color-cloud-burst;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: $general-spacing-unit;
}

.input {
  font-family: $font-source-sans;
  font-weight: 600;
  font-size: 18px;
  color: $color-cloud-burst;
  line-height: 24px;
  border-radius: 10px;
  border: 1px solid #e4e9f2;
  height: 56px;
  padding: 0 $general-spacing-unit * 2;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  background: $color-lilac;

  &::placeholder {
    color: $color-bali-hai;
  }

  &:focus {
    border-color: $color-eastern-blue;
  }

  &.filled:focus {
    background-color: $color-white;
    border-color: $color-eastern-blue;
  }

  &.invalid {
    border-color: $color-valencia;
  }
}

.error {
  display: block;
  font-size: 12px;
  color: $color-valencia;
  line-height: 16px;
  position: absolute;
  right: 0;
  top: 0;
}
