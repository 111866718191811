@import "../../libs/styles/main.scss";

.wrapper {
  width: 1092px;
  margin: 0 auto;
  padding: 120px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;

  @include breakpoint("tablet") {
    width: 75%;
    flex-direction: column-reverse;
    text-align: center;
    padding: 80px 0;
  }

  @include breakpoint("mobile") {
    width: calc(100vw - 80px);
    flex-direction: column-reverse;
    text-align: center;
    padding: $general-spacing-unit * 5 0;
    flex-wrap: wrap;
  }

  &.inverse {
    flex-direction: row-reverse;

    @include breakpoint("mobile-and-tablet") {
      flex-direction: column-reverse;
    }

    img {
      @include breakpoint("tablet-and-desktop") {
        margin-left: 0;
        margin-right: $general-spacing-unit * 10;
      }
    }
  }
}

.descriptionWrapper {
  font-weight: 500;
  font-size: 18px;
  color: #253c45;
  line-height: 28px;
  margin-top: 24px;

  @include breakpoint("mobile") {
    padding: 0 $general-spacing-unit;
  }
}

.actionWrapper {
  margin-top: $general-spacing-unit * 4;
  width: max-content;

  @include breakpoint("tablet") {
    max-width: 288px;
    margin-left: auto;
    margin-right: auto;
  }

  @include breakpoint("mobile") {
    max-width: 288px;
    margin-left: auto;
    margin-right: auto;
  }
}

.image {
  width: calc(1092px / 2 - #{$general-spacing-unit * 6});
  margin-left: $general-spacing-unit * 10;

  @include breakpoint("desktop") {
    margin-left: 56px;
  }

  @include breakpoint("tablet") {
    width: 75%;
    height: auto;
    margin-bottom: $general-spacing-unit * 7;
  }

  @include breakpoint(mobile) {
    width: 100%;
    height: auto;
    margin: 0 0 32px;
  }
}