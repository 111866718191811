@import "../../../../libs/styles/main.scss";

.confirmButton {
  font-weight: 600;
  font-size: 18px;
  font-family: $font-source-sans;
  width: 100%;
  border: 0;
  background: transparent;
  height: 56px;
  line-height: 56px;
  cursor: pointer;

  span {
    color: $color-eastern-blue;

    &:hover {
      text-decoration: underline;
    }
  }
}

@include breakpoint("mobile") {
  .confirmButton {
    height: max-content;
    margin-top: 20px;
    line-height: initial;
  }
}