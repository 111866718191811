@import "../../../../libs/styles/main.scss";

.wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: max-content;
    margin: 0 auto;
    gap: $general-spacing-unit * 3;

    &>button {
        margin-top: $general-spacing-unit * 2;
        padding: 0;
        flex: 0;
    }

    &>button:nth-child(1) {
        border: solid 2px $color-secondary-enterprise;
        color: $color-secondary-enterprise;
        min-width: 239px;

        &:hover {
            background-color: $color-secondary-enterprise;
        }
    }

    &>button:nth-child(2) {
        border: none;
        color: $color-secondary-enterprise;

        &:hover {
            background-color: transparent;
            color: $color-secondary-enterprise;
            text-decoration: underline;
        }
    }

}

.secondaryLayout {
    &>button:nth-child(1) {
        border: solid 2px $color-white;
        color: $color-white;

        &:hover {
            background-color: $color-white;
            color: $color-primary-enterprise;
        }
    }

    &>button:nth-child(2) {
        border: none;
        color: $color-white;

        &:hover {
            background-color: transparent;
            color: $color-white;
        }
    }
}

@include breakpoint("tablet-and-desktop-2") {
    .wrapper {
        flex-direction: column;
        gap: 0;
        width: 100%;
    }
}

@include breakpoint("mobile-and-tablet-2") {
    .wrapper {
        flex-direction: column;
        gap: 0;
        width: 100%;
    }
}

@include breakpoint("mobile") {
    .wrapper {
        flex-direction: column;
        gap: 0;
        width: 100%;
    }
}