@import "../../libs/styles/main.scss";

.wrapper {
  padding: 0 24px 24px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 0 2px 20px 0 rgba(27, 155, 158, 0.1),
    0 2px 20px 0 rgba(151, 151, 151, 0.1);
}

.image {
  border-radius: 10px 10px 0 0;
  display: block;
  margin: 0 -24px 24px;
  height: 172px;
  width: calc(100% + 48px);
  z-index: 0;
  object-fit: cover;
}

.category {
  font-weight: 700;
  font-size: 13px;
  color: #1b9b9e;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 8px;
  display: block;
}

.title {
  font-size: 24px;
  line-height: 32px;
  color: #222b45;
  margin: 0 0 16px 0;
}

.infoWrapper {
  margin: 0 0 8px;
  font-size: 13px;
}

.source {
  color: #1b9b9e;
  font-weight: 600;
}

.date {
  font-weight: 500;
  color: #222b45;
}

.date:before {
  margin: 0 0.25em;
  content: "|";
}

.descriptionWrapper {
  flex: 1;
}

.description {
  display: none;
  display: -webkit-box;
  text-overflow: ellipsis;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  font-size: 16px;
  color: #253c45;
  line-height: 24px;
  margin: 0 0 $general-spacing-unit * 2;
  overflow: hidden;
  box-orient: vertical;
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
}

.footer {
  display: flex;
  flex-direction: row;
}
