@import "../../libs/styles/main.scss";

.wrapper {
    border-bottom: solid 1px $color-bali-hai;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1092px;
    margin: 0 auto;

    @include breakpoint("tablet") {
        width: 75%;
        max-width: 75%;
    }

}

.text {
    font-size: 40px;
    line-height: 50px;
    color: #438FCC;
    font-weight: 700;
    margin: 0;
    text-align: center;


    @include breakpoint("tablet") {
        font-size: 32px;
    }

    @include breakpoint("mobile") {
        font-size: 20px;
        line-height: 30px;
    }
}

.author {
    font-size: 16px;
    color: $color-bali-hai;
    font-weight: 700;
    margin-bottom: $general-spacing-unit;
    margin-right: $general-spacing-unit * 9;
    align-self: flex-end;
    letter-spacing: 2.67px;


    @include breakpoint("tablet") {
        align-self: auto;
        margin: $general-spacing-unit 0;
    }

    @include breakpoint("mobile") {
        align-self: auto;
        margin: $general-spacing-unit 0;
        letter-spacing: 2.67px;
    }
}