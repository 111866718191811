@import "../../../../libs/styles/main.scss";

.forgotPasswordLink {
  text-align: right;
  display: block;
  margin: $general-spacing-unit 0 $general-spacing-unit * 2;
  font-weight: 700;
  color: $color-eastern-blue;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
