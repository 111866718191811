@import "../../../../libs/styles/main.scss";

.wrapper {
  border-radius: 10px;
  background: $color-white;
  box-shadow: 0 2px 20px 0 rgba(27, 155, 158, 0.1),
    0 2px 20px 0 rgba(151, 151, 151, 0.1);
  padding: $general-spacing-unit * 2.5;
  box-sizing: border-box;
  margin: $general-spacing-unit * 2 auto $general-spacing-unit * 2;
  max-width: 1092px;

  h1,
  h2,
  h3 {
    margin: 0 0 $general-spacing-unit * 4;
    color: $color-cloud-burst;
  }

  h1,
  h2 {
    font-size: 32px;
    line-height: 32px;
  }

  h3 {
    font-size: 24px;
    line-height: 24px;
  }

  ol,
  ul {
    padding-left: $general-spacing-unit * 2;
  }

  li,
  p {
    font-size: 18px;
    line-height: 24px;
    color: $color-cloud-burst;
    font-weight: $font-weight-semibold;
    margin: $general-spacing-unit * 2 0;
  }

  @media screen and (max-width: 1080px) {
    & {
      margin: $general-spacing-unit * 2 $general-spacing-unit * 3;
    }
  }
}

.error {
  border: 1px solid $color-valencia;
}