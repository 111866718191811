@import "../../../../libs/styles/main.scss";

.wrapper {
    display: flex;
    flex-direction: column;
    gap: $general-spacing-unit * 3;
    max-width: 740px;
}

.firstSection {
    display: flex;
    flex-direction: column;
    gap: $general-spacing-unit * 2;
}

.paymentMethod {
    display: flex;
    flex-wrap: wrap;
    gap: $general-spacing-unit * 2 $general-spacing-unit * 4;
}

.secondSection {
    display: flex;
    flex-direction: column;
    gap: $general-spacing-unit * 3;
}

.creditCardDetails {
    display: flex;
    gap: $general-spacing-unit * 3;
    flex-wrap: wrap;

    fieldset:first-child {
        flex: 2;
        min-width: 220px;
    }

    fieldset:not(fieldset:first-child) {
        flex: 1;
        min-width: 130px;
    }

    @include breakpoint("mobile") {
        fieldset {
            min-width: 100%;
        }
    }
}

.customerDetails {
    display: flex;
    gap: $general-spacing-unit * 3;
    flex-wrap: wrap;

    &>fieldset {
        flex: 1;
        min-width: 290px;
    }

    @include breakpoint("mobile") {
        &>fieldset {
            min-width: 100%;
        }
    }
}

.customerAddress {
    display: flex;
    flex-direction: column;
    gap: $general-spacing-unit * 3;

    h2 {
        margin: 0;
    }
}

.mainAddress {
    display: flex;
    flex-wrap: wrap;
    gap: $general-spacing-unit * 3;

    fieldset:first-child {
        flex: 3;
        min-width: 340px;
    }

    fieldset:not(fieldset:first-child) {
        flex: 1;
        min-width: 130px;
    }

    @include breakpoint("mobile") {

        fieldset,
        fieldset:first-child {
            min-width: 100%;
        }
    }
}

.addressDetails {
    display: flex;
    flex-direction: row;
    gap: $general-spacing-unit * 3;
    flex-wrap: wrap;

    &>* {
        flex: 1;
        min-width: 230px;
    }
}

.amount {
    display: flex;
    flex-direction: column;
    gap: $general-spacing-unit * 2;

    label {
        font-size: 24px;
        font-weight: bold;
    }
}

.thirdSection {
    display: flex;
    gap: $general-spacing-unit * 3;
    flex-direction: column;
}

.boletoDetails {
    display: flex;
    gap: $general-spacing-unit * 3;
    flex-wrap: wrap;

    &>fieldset {
        flex: 1;
        min-width: 260px;
    }
}

.fourthSection {
    display: flex;
    flex-direction: column;
    gap: $general-spacing-unit * 2;
}

.boletoDownload {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: $color-surfie-green;
    border: 3px solid $color-surfie-green;
    letter-spacing: 0;
    font-weight: 700;
    text-decoration: none;
    border-radius: 5px;
    padding: 16px;
    max-width: 320px;
    box-sizing: border-box;

    span {
        margin-left: $general-spacing-unit;
    }
}

.fifthSection {
    display: flex;

    @include breakpoint("tablet-and-below") {
        flex-direction: column;

        button:first-child {
            order: 1;
        }
    }
}

.btnSecondaryLayout {
    background-color: $color-primary-enterprise;
    border-color: $color-primary-enterprise;

    &:hover {
        background-color: $color-secondary-enterprise;
        border-color: $color-secondary-enterprise;
    }
}