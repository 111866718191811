@import "../../../../libs/styles/main.scss";

.wrapper {
  display: flex;
  border-radius: 20px;
  background-color: $color-white;
  background-position: right center;
  background-repeat: no-repeat;
  padding: $general-spacing-unit * 7;
  box-shadow: 0 2px 20px 0 rgba(27, 155, 158, 0.1),
    0 2px 20px 0 rgba(151, 151, 151, 0.1);
  max-width: 1092px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;

  & input,
  & textarea {
    &:focus {
      border: solid 1px $color-primary-enterprise;
    }
  }

  @include breakpoint("tablet") {
    background-size: 0 0;
    margin: 0;
    flex-direction: column;
  }

  @include breakpoint("mobile") {
    background-size: 0 0;
    padding: $general-spacing-unit * 3;
    margin: $general-spacing-unit * 4 $general-spacing-unit * 3;
    flex-direction: column;
  }
}

.formWrapper {
  max-width: 560px;
  margin-top: $general-spacing-unit * 4;
}

.form {
  flex: 1.5;

  &>h2 b {
    color: $color-primary-enterprise;
  }

  & button {
    background-color: $color-secondary-enterprise;
    border-color: $color-secondary-enterprise;
  }

  & button:hover {
    background-color: $color-secondary-enterprise-hover;
    border-color: $color-secondary-enterprise-hover;
  }
}

.activity {
  flex: 1;
  margin-left: $general-spacing-unit * 4;

  &>h2 b {
    color: $color-primary-enterprise;
  }

  @include breakpoint("tablet") {
    margin: $general-spacing-unit * 4 0;
  }

  @include breakpoint("mobile") {
    margin: $general-spacing-unit * 4 0;
  }
}

.description {
  font-size: 18px;
  line-height: 28px;
  color: $color-cloud-burst;

  @include breakpoint("tablet") {
    font-size: 16px;
    line-height: 24px;
  }

  @include breakpoint("mobile") {
    text-align: center;
  }
}

.socialMediaWrapper {
  display: flex;
  flex-direction: column;
  margin-top: $general-spacing-unit * 4;

  &>span {
    font-size: 24px;
    line-height: 24px;
    color: $color-cloud-burst;
    font-weight: 600;
  }

  @include breakpoint("tablet") {
    align-items: flex-start;
    margin-top: $general-spacing-unit * 4;
  }

  @include breakpoint("mobile") {
    align-items: center;
  }
}

.socialMediaButtons {
  display: flex;
  margin-top: $general-spacing-unit * 3;
  gap: $general-spacing-unit;
}