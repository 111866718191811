@import "../../../libs/styles/main.scss";

.wrapper {
  position: relative;
}

.errorMessage {
  color: $color-valencia;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: calc(#{$general-spacing-unit} / 2);
  display: block;
}

.input {
  // visibility: hidden;
  opacity: 0;
  position: absolute;
  width: $general-spacing-unit * 2.75;
  height: $general-spacing-unit * 2.75;
  margin: 0;
  bottom: 9px;
  left: 0;
  appearance: none;
}

.label {
  display: flex;
  flex-direction: row;
  align-items: center;

  &:before {
    content: "";
    width: $general-spacing-unit * 2.75;
    height: $general-spacing-unit * 2.75;
    line-height: $general-spacing-unit * 2.75;
    box-sizing: border-box;
    display: block;
    margin-right: $general-spacing-unit * 2;
    border: 1px solid $color-eastern-blue;
    border-radius: 3px;
    font-weight: 900;
    color: $color-white;
    text-align: center;
    font-size: 14px;
    background-color: $color-white;
    transition: background-color ease 0.15s, border-color ease 0.15s;
    cursor: pointer;
  }

  &:hover:before {
    background-color: #bce2e3;
  }

  &.invalid {
    &:before {
      border-color: $color-valencia !important;
      background-color: rgba($color-valencia, 0.1);
    }

    &:hover:before {
      background-color: rgba($color-valencia, 0.3);
    }
  }

  .input:checked+&:before {
    content: "✓";
    background-color: $color-eastern-blue;
    border-color: $color-eastern-blue;
  }

  .input:focus+&:before {
    box-shadow: 0 0 0 1px $color-eastern-blue;
  }

  .input:checked+&:hover:before {
    background-color: $color-eastern-blue;
    border-color: $color-eastern-blue;
  }

  a {
    color: $color-eastern-blue;
    font-weight: 700;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.secondaryLayout {

  .label:before {
    border: 1px solid $color-secondary-enterprise;
  }

  .label:hover:before {
    background-color: #d6ecff;
  }

  .input:checked+.label:before {
    content: "✓";
    background-color: $color-secondary-enterprise;
    border-color: $color-secondary-enterprise;
  }

  .input:focus+.label:before {
    box-shadow: 0 0 0 1px $color-secondary-enterprise;
  }

  .input:checked+.label:hover:before {
    background-color: $color-secondary-enterprise;
    border-color: $color-secondary-enterprise;
  }

  a {
    color: $color-secondary-enterprise;
    font-weight: 700;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

@include breakpoint("mobile") {
  .label {
    &:hover:before {
      background-color: white;
    }
  }
}
.falseLabel {
  &.alternativeStyle {
    margin-left: $general-spacing-unit * -1;
    font-weight: $font-weight-semibold;
  }
}
