@import "../../../../libs/styles/main.scss";

.title {
  border-top: 1px solid $color-blue-haze;
  padding-top: $general-spacing-unit * 3;
  margin: $general-spacing-unit * 3 0 0 0;
  font-weight: $font-weight-bold;
  font-size: 20px;
  line-height: 1;
}
