@import "../../../../libs/styles/main.scss";

.wrapper {
    min-height: 85vh;
}

.moreContent {
    margin: 0 auto;
    max-width: 348px;
}

.terms {
    color: $color-primary-enterprise;
    text-decoration: none;
    font-weight: 700;
  
    &:hover {
      text-decoration: underline;
    }
}