@import "../../../../libs/styles/main.scss";

// .wrapper {
//     // max-width: 770px;
// }

.firstSection {
    display: flex;
    flex-direction: column;
    gap: $general-spacing-unit * 3;
}

.jobName {
    display: flex;
    gap: $general-spacing-unit * 3;

    @include breakpoint("tablet-and-below") {
        flex-direction: column;
    }
}

.jobIllustration {
    white-space: nowrap;
    overflow-x: auto;
    width: 100%;
    box-sizing: border-box;
    width: calc(100vw - 106px);
    max-width: 1044px;

    label {
        display: block;
        font-weight: 700;
        font-size: 12px;
        color: $color-cloud-burst;
        line-height: 16px;
        text-transform: uppercase;
        margin-bottom: $general-spacing-unit;
    }

    & .container {
        padding: $general-spacing-unit 0;
    }
}

.secondSection {
    display: flex;
    flex-direction: column;
    gap: $general-spacing-unit * 3;
}

.jobLocalization {
    display: flex;
    flex-direction: column;
    gap: $general-spacing-unit * 2;

    & .options {
        display: flex;
        gap: $general-spacing-unit * 4;
        justify-content: flex-start;
    }

    & .subtitle {
        color: $color-bali-hai;
        font-size: 13px;
        margin-left: 4px;

        & .error {
            color: red;
        }
    }

    & .error {
        color: red;
    }

    @include breakpoint("tablet-and-below") {
        & .options {
            display: flex;
            flex-direction: column;
            gap: $general-spacing-unit * 2;
        }
    }
}

.jobHour {
    max-width: calc(33% - 12px);
    min-width: max-content;

    @include breakpoint("tablet-and-below") {
        max-width: 100%;
    }
}

.jobState {
    display: flex;
    gap: $general-spacing-unit * 3;
    flex-wrap: wrap;

    &>* {
        flex: 1;
        min-width: max-content;
    }

    @include breakpoint("tablet-and-below") {
        flex-direction: column;
    }

    @include breakpoint("mobile") {
        &>* {
            width: 100%;
            min-width: inherit;
        }
    }
}

.thirdSection {
    display: flex;
    flex-direction: column;
    gap: $general-spacing-unit * 3;
}

.fourthSection,
.fifthSection {
    display: flex;
    flex-direction: column;
    gap: $general-spacing-unit * 3;
}

.fourthSection {
    &>div {
        max-width: calc(30% - 12px);
        min-width: max-content;
    }

    @include breakpoint("tablet-and-below") {
        &>div {
            max-width: 100%;
        }
    }
}

.jobLink {
    display: flex;
    flex-direction: column;
    gap: $general-spacing-unit * 2;

    & .options {
        display: flex;
        flex-direction: column;
        gap: $general-spacing-unit * 2;
    }

    & .radios {
        display: flex;
        gap: $general-spacing-unit * 3;
    }

    & fieldset {
        max-width: 358px;
    }
}

.sixtySection {
    display: flex;
    margin-top: $general-spacing-unit * 4;

    button {
        margin: 0;
    }

    @include breakpoint("tablet-and-below") {
        flex-direction: column;

        button[type="button"] {
            order: 1;
            margin-top: $general-spacing-unit * 2;
        }
    }
}

.btnSecondaryLayout {
    background-color: $color-primary-enterprise;
    border-color: $color-primary-enterprise;

    &:hover {
        background-color: $color-secondary-enterprise;
        border-color: $color-secondary-enterprise;
    }
}