@import "../../libs/styles/main.scss";

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  flex: 1;
  text-decoration: none;
  box-sizing: border-box;
  white-space: nowrap;
  background: #1b9b9e;
  color: #fff;
  height: 56px;
  line-height: 52px;
  padding: 0 56px;
  border: none;
  cursor: pointer;
  font-family: "Source Sans Pro";
  margin-top: 24px;
  width: 100%;
  border: 2px solid #1b9b9e;
  transition: color ease 0.15s, background-color ease 0.15s,
    border-color ease 0.15s;
}

.button:hover {
  border-color: darken($color-eastern-blue, 7.5%);
  background: darken($color-eastern-blue, 7.5%);
}

.button.outlined {
  background: transparent;
  color: #1b9b9e;
  border: 2px solid #1b9b9e;
  line-height: 52px;
}

.button.outlined:hover {
  background: #1b9b9e;
  color: #fff;
}

.button.disabled {
  cursor: not-allowed;
  opacity: 0.75;
}

.button.simple {
  color: $color-bali-hai;
  background: transparent;
  border-color: transparent;
}

.iconWrapper {
  align-items: center;
  display: flex;
  margin-right: $general-spacing-unit;
}