@import "../../libs/styles/main.scss";

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1092px;
  margin: 0 auto;
  box-sizing: border-box;

  @include breakpoint("tablet") {
    padding: 0 48px;
  }

  @include breakpoint("mobile") {
    flex-direction: column-reverse;
    text-align: center;
    padding: 0 32px;
  }

  a {
    display: inline-block;
    padding: 0 40px;
    margin-top: 24px;
  }
}

.title {
  font-weight: 700;
  font-size: 56px;
  line-height: 64px;
  color: #222b45;
  margin: 0 0 16px;

  b {
    color: #1b9b9e;
  }

  @include breakpoint("tablet") {
    font-size: 32px;
    line-height: 40px;
    margin: 0 0 8px;
  }

  @include breakpoint("mobile") {
    font-size: 32px;
    line-height: 40px;
  }
}

.description {
  font-weight: 500;
  font-size: 24px;
  color: #222b45;
  line-height: 32px;
  margin: 0 0 32px;

  @include breakpoint("tablet") {
    font-size: 16px;
    line-height: 24px;
  }

  @include breakpoint("mobile") {
    margin: 16px 0 0;
    font-size: 18px;
    line-height: 28px;
  }
}

.image {
  margin-left: 65px;
  height: 570px;
  width: 600px;
  object-fit: contain;

  @include breakpoint("tablet") {
    margin-left: 16px;
    height: 316px;
    width: 336px;
  }

  @include breakpoint("mobile") {
    width: 80vw;
    margin: 0 0 24px;
    height: auto;
  }
}