@import "../../../../libs/styles/main.scss";

/*
    TODO:
    1 - review styles when form components refactoring under development or done
    2 - apply form LABEL component in component for radio buttons
*/

.wrapper {
  max-width: 660px;
}

.radioButtonsFakeLabel {
  // TODO: 2
  display: block;
  font-weight: 700;
  font-size: 12px;
  color: $color-cloud-burst;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: $general-spacing-unit;
}

.radioWrapper {
  margin: 0 0 $general-spacing-unit * 3;

  // TODO: 1
  fieldset {
    display: grid !important;
    grid-template-columns: 1fr;
    row-gap: $general-spacing-unit * 1.5;

    div {
      margin: 0 !important;
    }
  }

  label {
    margin: 0;
    font-weight: $font-weight-semibold;
  }
}

.buttonsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: $general-spacing-unit * 1.5;
  padding-top: $general-spacing-unit * 3;

  @include breakpoint("mobile") {
    grid-template-columns: 1fr;
  }

  button {
    margin-top: 0;
  }
}
