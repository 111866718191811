.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: .25rem;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;

  svg {
    max-width: 1.5rem;
    max-height: 1.5rem;
  }
}