@import "../../libs/styles/main.scss";

.title {
  cursor: pointer;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #6c7894;
  padding-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @include breakpoint(mobile) {
    padding-top: 16px;
  }

  svg {
    transition: transform ease 0.5s;
    margin-left: 15px;
    min-width: 21px;
  }

  &.open svg {
    transform: rotate(135deg);
  }
}

.description {
  font-size: 18px;
  line-height: 28px;
  margin: 0;
  max-height: 0;
  transition: max-height ease 0.25s, color ease 0.25s, padding ease 0.25s;
  overflow: hidden;
  color: transparent;
  border-bottom: 2px solid #e4e9f2;
  padding-bottom: 24px;

  &.open {
    max-height: max-content;
    color: #253c45;
    padding-top: 24px;
  }
}