@import "../../../../libs/styles/main.scss";

.list {
  display: grid;
}

.buttonWrapper {
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  column-gap: $general-spacing-unit * 3;
  width: 100%;
  padding-top: $general-spacing-unit * 3;
}

.item {
  border-bottom: 1px solid $color-catskill-white;
  padding-bottom: $general-spacing-unit * 3;
  margin: $general-spacing-unit * 3 0 0;

  &:first-child {
    margin-top: 0;
  }
}

.text {
  margin: 0;
  font-size: 18px;
  line-height: 32px;
}

.editButton,
.removeButton {
  display: block;
  background: transparent;
  line-height: 32px;
  border: 1px solid transparent;
  font-family: $font-source-sans;
  font-weight: $font-weight-bold;
  cursor: pointer;
  border-radius: 5px;

  &[disabled] {
    opacity: 0.5;
  }
}

.removeButton {
  border-color: $color-valencia;
  color: $color-valencia;
}

.editButton {
  border-color: $color-eastern-blue;
  color: $color-eastern-blue;
}
