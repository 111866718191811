@import "../../libs/styles/main.scss";

.wrapper {
  display: block;
}

.label {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  font-size: 12px;
  color: $color-cloud-burst;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: $general-spacing-unit;
}

.iconWrapper {
  margin-left: $general-spacing-unit * 0.5;
}
