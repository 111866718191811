@import "../../libs/styles/main.scss";

.actionButtonWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin: $general-spacing-unit * 2 0 0;
}

.openFileLink {
  cursor: pointer;
  text-decoration: underline;
  color: #1b9b9e;
  font-weight: 600;
  font-size: 16px;
  background: none;
  border: none;
}
