@import "../../../../libs/styles/main.scss";

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include breakpoint("mobile") {
    flex-direction: column;

    & > *:nth-child(2) {
      margin-bottom: calc($general-spacing-unit * 2);
    }
  }
}
