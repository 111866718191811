@import "../../../../../libs/styles/main.scss";

.wrapper {
    display: flex;
    flex-direction: column;
    padding-top: $general-spacing-unit * 2;
    border-top: solid 1px $color-soft-white;
    margin-top: $general-spacing-unit * 4;
}

.title {
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
}

.call {
    margin: 8px 0;
}

.description,
.call {
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;

    a {
        color: $color-primary-enterprise;
        font-weight: 600;
        text-decoration: none;
    }
}

.id {
    margin: $general-spacing-unit * 2 0;

    b {
        color: $color-primary-enterprise;
    }
}