.wrapper {
  padding: 0 24px 24px;
  box-sizing: border-box;
  background-color: #ddf0ee;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 0 2px 20px 0 rgba(27, 155, 158, 0.1),
    0 2px 20px 0 rgba(151, 151, 151, 0.1);
}

.label {
  background: #1b9b9e;
  font-size: 13px;
  line-height: 20px;
  padding: 4px 16px;
  position: absolute;
  left: 24px;
  top: 0;
  z-index: 1;
  font-weight: 700;
  color: #fff;
  border-radius: 0 0 5px 5px;
}

.image {
  border-radius: 10px 10px 0 0;
  display: block;
  margin: 0 -24px 24px;
  height: 228px;
  width: calc(100% + 48px);
  z-index: 0;
  object-fit: cover;
}

.title {
  font-size: 24px;
  line-height: 32px;
  color: #222b45;
  margin: 0 0 8px 0;
}

.description {
  font-size: 16px;
  color: #253c45;
  line-height: 24px;
  margin: 0 0 24px;
  flex: 1;
}

.footer {
  display: flex;
  flex-direction: row;
}
