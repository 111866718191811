@import "../../../../libs/styles/main.scss";

.text {
  font-size: 18px !important;
  line-height: 32px !important;
  margin: 0 !important;
  color: $color-cloud-burst !important;
  font-weight: $font-weight-regular !important;

  span {
    font-weight: $font-weight-semibold !important;
  }
}

.openFileLink {
  cursor: pointer;
  text-decoration: underline;
  color: #1b9b9e;
  font-weight: 600;
  font-size: 16px;
  background: none;
  border: none;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.50;
  }
}