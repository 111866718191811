$icomoon-font-family: "icomoon-inklua" !default;
$icomoon-font-path: "fonts" !default;

$icon-inkoins: "\eaed";
$icon-cifrao: "\e900";
$icon-instagram: "\e901";
$icon-whatsapp: "\e902";
$icon-search: "\e903";
$icon-facebook: "\e904";
$icon-like-outline: "\eaee";
$icon-like-fill: "\eaef";
$icon-activity: "\e905";
$icon-alert-circle: "\e906";
$icon-alert-triangle: "\e907";
$icon-archive: "\e908";
$icon-arrow-back: "\e909";
$icon-arrow-circle-down: "\e90a";
$icon-arrow-circle-left: "\e90b";
$icon-arrow-circle-right: "\e90c";
$icon-arrow-circle-up: "\e90d";
$icon-arrow-down: "\e90e";
$icon-arrow-downward: "\e90f";
$icon-arrow-forward: "\e910";
$icon-arrow-ios-back: "\e911";
$icon-arrow-ios-downward: "\e912";
$icon-arrow-ios-forward: "\e913";
$icon-arrow-ios-upward: "\e914";
$icon-arrow-left: "\e915";
$icon-arrow-right: "\e916";
$icon-arrow-up: "\e917";
$icon-arrow-upward: "\e918";
$icon-arrowhead-down: "\e919";
$icon-arrowhead-left: "\e91a";
$icon-arrowhead-right: "\e91b";
$icon-arrowhead-up: "\e91c";
$icon-at: "\e91d";
$icon-attach-2: "\e91e";
$icon-attach: "\e91f";
$icon-award: "\e920";
$icon-backspace: "\e921";
$icon-bar-chart-2: "\e922";
$icon-bar-chart: "\e923";
$icon-battery: "\e924";
$icon-behance: "\e925";
$icon-bell-off: "\e926";
$icon-bell: "\e927";
$icon-bluetooth: "\e928";
$icon-book-open: "\e929";
$icon-book: "\e92a";
$icon-bookmark: "\e92b";
$icon-briefcase: "\e92c";
$icon-browser: "\e92d";
$icon-brush: "\e92e";
$icon-bulb: "\e92f";
$icon-calendar: "\e930";
$icon-camera: "\e931";
$icon-car: "\e932";
$icon-cast: "\e933";
$icon-charging: "\e934";
$icon-checkmark-circle-2: "\e935";
$icon-checkmark-circle: "\e936";
$icon-checkmark-square-2: "\e937";
$icon-checkmark-square: "\e938";
$icon-checkmark: "\e939";
$icon-chevron-down: "\e93a";
$icon-chevron-left: "\e93b";
$icon-chevron-right: "\e93c";
$icon-chevron-up: "\e93d";
$icon-clipboard: "\e93e";
$icon-clock: "\e93f";
$icon-close-circle: "\e940";
$icon-close-square: "\e941";
$icon-close: "\e942";
$icon-cloud-download: "\e943";
$icon-cloud-upload: "\e944";
$icon-code-download: "\e945";
$icon-code: "\e946";
$icon-collapse: "\e947";
$icon-color-palette: "\e948";
$icon-color-picker: "\e949";
$icon-compass: "\e94a";
$icon-copy: "\e94b";
$icon-corner-down-left: "\e94c";
$icon-corner-down-right: "\e94d";
$icon-corner-left-down: "\e94e";
$icon-corner-left-up: "\e94f";
$icon-corner-right-down: "\e950";
$icon-corner-right-up: "\e951";
$icon-corner-up-left: "\e952";
$icon-corner-up-right: "\e953";
$icon-credit-card: "\e954";
$icon-crop: "\e955";
$icon-cube: "\e956";
$icon-diagonal-arrow-left-down: "\e957";
$icon-diagonal-arrow-left-up: "\e958";
$icon-diagonal-arrow-right-down: "\e959";
$icon-diagonal-arrow-right-up: "\e95a";
$icon-done-all: "\e95b";
$icon-download: "\e95c";
$icon-droplet-off: "\e95d";
$icon-droplet: "\e95e";
$icon-edit-2: "\e95f";
$icon-edit: "\e960";
$icon-email: "\e961";
$icon-expand: "\e962";
$icon-external-link: "\e963";
$icon-eye-off-2: "\e964";
$icon-eye-off: "\e965";
$icon-eye: "\e966";
$icon-facebook1: "\e967";
$icon-file-add: "\e968";
$icon-file-remove: "\e969";
$icon-file-text: "\e96a";
$icon-file: "\e96b";
$icon-film: "\e96c";
$icon-flag: "\e96d";
$icon-flash-off: "\e96e";
$icon-flash: "\e96f";
$icon-flip-2: "\e970";
$icon-flip: "\e971";
$icon-folder-add: "\e972";
$icon-folder-remove: "\e973";
$icon-folder: "\e974";
$icon-funnel: "\e975";
$icon-gift: "\e976";
$icon-github: "\e977";
$icon-globe-2: "\e978";
$icon-globe-3: "\e979";
$icon-globe: "\e97a";
$icon-google: "\e97b";
$icon-grid: "\e97c";
$icon-hard-drive: "\e97d";
$icon-hash: "\e97e";
$icon-headphones: "\e97f";
$icon-heart: "\e980";
$icon-home: "\e981";
$icon-image-2: "\e982";
$icon-image: "\e983";
$icon-inbox: "\e984";
$icon-info: "\e985";
$icon-keypad: "\e986";
$icon-layers: "\e987";
$icon-layout: "\e988";
$icon-link-2: "\e989";
$icon-link: "\e98a";
$icon-linkedin: "\e98b";
$icon-list: "\e98c";
$icon-lock: "\e98d";
$icon-log-in: "\e98e";
$icon-log-out: "\e98f";
$icon-map: "\e990";
$icon-maximize: "\e991";
$icon-menu-2: "\e992";
$icon-menu-arrow: "\e993";
$icon-menu: "\e994";
$icon-message-circle: "\e995";
$icon-message-square: "\e996";
$icon-mic-off: "\e997";
$icon-mic: "\e998";
$icon-minimize: "\e999";
$icon-minus-circle: "\e99a";
$icon-minus-square: "\e99b";
$icon-minus: "\e99c";
$icon-monitor: "\e99d";
$icon-moon: "\e99e";
$icon-more-horizontal: "\e99f";
$icon-more-vertical: "\e9a0";
$icon-move: "\e9a1";
$icon-music: "\e9a2";
$icon-navigation-2: "\e9a3";
$icon-navigation: "\e9a4";
$icon-npm: "\e9a5";
$icon-options-2: "\e9a6";
$icon-options: "\e9a7";
$icon-pantone: "\e9a8";
$icon-paper-plane: "\e9a9";
$icon-pause-circle: "\e9aa";
$icon-people: "\e9ab";
$icon-percent: "\e9ac";
$icon-person-add: "\e9ad";
$icon-person-delete: "\e9ae";
$icon-person-done: "\e9af";
$icon-person-remove: "\e9b0";
$icon-person: "\e9b1";
$icon-phone-call: "\e9b2";
$icon-phone-missed: "\e9b3";
$icon-phone-off: "\e9b4";
$icon-phone: "\e9b5";
$icon-pie-chart-2: "\e9b6";
$icon-pie-chart: "\e9b7";
$icon-pin: "\e9b8";
$icon-play-circle: "\e9b9";
$icon-plus-circle: "\e9ba";
$icon-plus-square: "\e9bb";
$icon-plus: "\e9bc";
$icon-power: "\e9bd";
$icon-pricetags: "\e9be";
$icon-printer: "\e9bf";
$icon-question-mark-circle: "\e9c0";
$icon-question-mark: "\e9c1";
$icon-radio-button-off: "\e9c2";
$icon-radio-button-on: "\e9c3";
$icon-radio: "\e9c4";
$icon-recording: "\e9c5";
$icon-refresh: "\e9c6";
$icon-repeat: "\e9c7";
$icon-rewind-left: "\e9c8";
$icon-rewind-right: "\e9c9";
$icon-save: "\e9ca";
$icon-scissors: "\e9cb";
$icon-search1: "\e9cc";
$icon-settings-2: "\e9cd";
$icon-settings: "\e9ce";
$icon-shake: "\e9cf";
$icon-share: "\e9d0";
$icon-shield-off: "\e9d1";
$icon-shield: "\e9d2";
$icon-shopping-bag: "\e9d3";
$icon-shopping-cart: "\e9d4";
$icon-shuffle-2: "\e9d5";
$icon-shuffle: "\e9d6";
$icon-skip-back: "\e9d7";
$icon-skip-forward: "\e9d8";
$icon-slash: "\e9d9";
$icon-smartphone: "\e9da";
$icon-speaker: "\e9db";
$icon-square: "\e9dc";
$icon-star: "\e9dd";
$icon-stop-circle: "\e9de";
$icon-sun: "\e9df";
$icon-swap: "\e9e0";
$icon-sync: "\e9e1";
$icon-text: "\e9e2";
$icon-thermometer-minus: "\e9e3";
$icon-thermometer-plus: "\e9e4";
$icon-thermometer: "\e9e5";
$icon-toggle-left: "\e9e6";
$icon-toggle-right: "\e9e7";
$icon-trash-2: "\e9e8";
$icon-trash: "\e9e9";
$icon-trending-down: "\e9ea";
$icon-trending-up: "\e9eb";
$icon-tv: "\e9ec";
$icon-twitter: "\e9ed";
$icon-umbrella: "\e9ee";
$icon-undo: "\e9ef";
$icon-unlock: "\e9f0";
$icon-upload: "\e9f1";
$icon-video-off: "\e9f2";
$icon-video: "\e9f3";
$icon-volume-down: "\e9f4";
$icon-volume-mute: "\e9f5";
$icon-volume-off: "\e9f6";
$icon-volume-up: "\e9f7";
$icon-wifi-off: "\e9f8";
$icon-wifi: "\e9f9";
$icon-activity-outline: "\e9fa";
$icon-alert-circle-outline: "\e9fb";
$icon-alert-triangle-outline: "\e9fc";
$icon-archive-outline: "\e9fd";
$icon-arrow-back-outline: "\e9fe";
$icon-arrow-circle-down-outline: "\e9ff";
$icon-arrow-circle-left-outline: "\ea00";
$icon-arrow-circle-right-outline: "\ea01";
$icon-arrow-circle-up-outline: "\ea02";
$icon-arrow-down-outline: "\ea03";
$icon-arrow-downward-outline: "\ea04";
$icon-arrow-forward-outline: "\ea05";
$icon-arrow-ios-back-outline: "\ea06";
$icon-arrow-ios-downward-outline: "\ea07";
$icon-arrow-ios-forward-outline: "\ea08";
$icon-arrow-ios-upward-outline: "\ea09";
$icon-arrow-left-outline: "\ea0a";
$icon-arrow-right-outline: "\ea0b";
$icon-arrow-up-outline: "\ea0c";
$icon-arrow-upward-outline: "\ea0d";
$icon-arrowhead-down-outline: "\ea0e";
$icon-arrowhead-left-outline: "\ea0f";
$icon-arrowhead-right-outline: "\ea10";
$icon-arrowhead-up-outline: "\ea11";
$icon-at-outline: "\ea12";
$icon-attach-2-outline: "\ea13";
$icon-attach-outline: "\ea14";
$icon-award-outline: "\ea15";
$icon-backspace-outline: "\ea16";
$icon-bar-chart-2-outline: "\ea17";
$icon-bar-chart-outline: "\ea18";
$icon-battery-outline: "\ea19";
$icon-behance-outline: "\ea1a";
$icon-bell-off-outline: "\ea1b";
$icon-bell-outline: "\ea1c";
$icon-bluetooth-outline: "\ea1d";
$icon-book-open-outline: "\ea1e";
$icon-book-outline: "\ea1f";
$icon-bookmark-outline: "\ea20";
$icon-briefcase-outline: "\ea21";
$icon-browser-outline: "\ea22";
$icon-brush-outline: "\ea23";
$icon-bulb-outline: "\ea24";
$icon-calendar-outline: "\ea25";
$icon-camera-outline: "\ea26";
$icon-car-outline: "\ea27";
$icon-cast-outline: "\ea28";
$icon-charging-outline: "\ea29";
$icon-checkmark-circle-2-outline: "\ea2a";
$icon-checkmark-circle-outline: "\ea2b";
$icon-checkmark-outline: "\ea2c";
$icon-checkmark-square-2-outline: "\ea2d";
$icon-checkmark-square-outline: "\ea2e";
$icon-chevron-down-outline: "\ea2f";
$icon-chevron-left-outline: "\ea30";
$icon-chevron-right-outline: "\ea31";
$icon-chevron-up-outline: "\ea32";
$icon-clipboard-outline: "\ea33";
$icon-clock-outline: "\ea34";
$icon-close-circle-outline: "\ea35";
$icon-close-outline: "\ea36";
$icon-close-square-outline: "\ea37";
$icon-cloud-download-outline: "\ea38";
$icon-cloud-upload-outline: "\ea39";
$icon-code-download-outline: "\ea3a";
$icon-code-outline: "\ea3b";
$icon-collapse-outline: "\ea3c";
$icon-color-palette-outline: "\ea3d";
$icon-color-picker-outline: "\ea3e";
$icon-compass-outline: "\ea3f";
$icon-copy-outline: "\ea40";
$icon-corner-down-left-outline: "\ea41";
$icon-corner-down-right-outline: "\ea42";
$icon-corner-left-down-outline: "\ea43";
$icon-corner-left-up-outline: "\ea44";
$icon-corner-right-down-outline: "\ea45";
$icon-corner-right-up-outline: "\ea46";
$icon-corner-up-left-outline: "\ea47";
$icon-corner-up-right-outline: "\ea48";
$icon-credit-card-outline: "\ea49";
$icon-crop-outline: "\ea4a";
$icon-cube-outline: "\ea4b";
$icon-diagonal-arrow-left-down-outline: "\ea4c";
$icon-diagonal-arrow-left-up-outline: "\ea4d";
$icon-diagonal-arrow-right-down-outline: "\ea4e";
$icon-diagonal-arrow-right-up-outline: "\ea4f";
$icon-done-all-outline: "\ea50";
$icon-download-outline: "\ea51";
$icon-droplet-off-outline: "\ea52";
$icon-droplet-outline: "\ea53";
$icon-edit-2-outline: "\ea54";
$icon-edit-outline: "\ea55";
$icon-email-outline: "\ea56";
$icon-expand-outline: "\ea57";
$icon-external-link-outline: "\ea58";
$icon-eye-off-2-outline: "\ea59";
$icon-eye-off-outline: "\ea5a";
$icon-eye-outline: "\ea5b";
$icon-facebook-outline: "\ea5c";
$icon-file-add-outline: "\ea5d";
$icon-file-outline: "\ea5e";
$icon-file-remove-outline: "\ea5f";
$icon-file-text-outline: "\ea60";
$icon-film-outline: "\ea61";
$icon-flag-outline: "\ea62";
$icon-flash-off-outline: "\ea63";
$icon-flash-outline: "\ea64";
$icon-flip-2-outline: "\ea65";
$icon-flip-outline: "\ea66";
$icon-folder-add-outline: "\ea67";
$icon-folder-outline: "\ea68";
$icon-folder-remove-outline: "\ea69";
$icon-funnel-outline: "\ea6a";
$icon-gift-outline: "\ea6b";
$icon-github-outline: "\ea6c";
$icon-globe-2-outline: "\ea6d";
$icon-globe-outline: "\ea6e";
$icon-google-outline: "\ea6f";
$icon-grid-outline: "\ea70";
$icon-hard-drive-outline: "\ea71";
$icon-hash-outline: "\ea72";
$icon-headphones-outline: "\ea73";
$icon-heart-outline: "\ea74";
$icon-home-outline: "\ea75";
$icon-image-outline: "\ea76";
$icon-inbox-outline: "\ea77";
$icon-info-outline: "\ea78";
$icon-keypad-outline: "\ea79";
$icon-layers-outline: "\ea7a";
$icon-layout-outline: "\ea7b";
$icon-link-2-outline: "\ea7c";
$icon-link-outline: "\ea7d";
$icon-linkedin-outline: "\ea7e";
$icon-list-outline: "\ea7f";
$icon-loader-outline: "\ea80";
$icon-lock-outline: "\ea81";
$icon-log-in-outline: "\ea82";
$icon-log-out-outline: "\ea83";
$icon-map-outline: "\ea84";
$icon-maximize-outline: "\ea85";
$icon-menu-2-outline: "\ea86";
$icon-menu-arrow-outline: "\ea87";
$icon-menu-outline: "\ea88";
$icon-message-circle-outline: "\ea89";
$icon-message-square-outline: "\ea8a";
$icon-mic-off-outline: "\ea8b";
$icon-mic-outline: "\ea8c";
$icon-minimize-outline: "\ea8d";
$icon-minus-circle-outline: "\ea8e";
$icon-minus-outline: "\ea8f";
$icon-minus-square-outline: "\ea90";
$icon-monitor-outline: "\ea91";
$icon-moon-outline: "\ea92";
$icon-more-horizontal-outline: "\ea93";
$icon-more-vertical-outline: "\ea94";
$icon-move-outline: "\ea95";
$icon-music-outline: "\ea96";
$icon-navigation-2-outline: "\ea97";
$icon-navigation-outline: "\ea98";
$icon-npm-outline: "\ea99";
$icon-options-2-outline: "\ea9a";
$icon-options-outline: "\ea9b";
$icon-pantone-outline: "\ea9c";
$icon-paper-plane-outline: "\ea9d";
$icon-pause-circle-outline: "\ea9e";
$icon-people-outline: "\ea9f";
$icon-percent-outline: "\eaa0";
$icon-person-add-outline: "\eaa1";
$icon-person-delete-outline: "\eaa2";
$icon-person-done-outline: "\eaa3";
$icon-person-outline: "\eaa4";
$icon-person-remove-outline: "\eaa5";
$icon-phone-call-outline: "\eaa6";
$icon-phone-missed-outline: "\eaa7";
$icon-phone-off-outline: "\eaa8";
$icon-phone-outline: "\eaa9";
$icon-pie-chart-outline: "\eaaa";
$icon-pin-outline: "\eaab";
$icon-play-circle-outline: "\eaac";
$icon-plus-circle-outline: "\eaad";
$icon-plus-outline: "\eaae";
$icon-plus-square-outline: "\eaaf";
$icon-power-outline: "\eab0";
$icon-pricetags-outline: "\eab1";
$icon-printer-outline: "\eab2";
$icon-question-mark-circle-outline: "\eab3";
$icon-question-mark-outline: "\eab4";
$icon-radio-button-off-outline: "\eab5";
$icon-radio-button-on-outline: "\eab6";
$icon-radio-outline: "\eab7";
$icon-recording-outline: "\eab8";
$icon-refresh-outline: "\eab9";
$icon-repeat-outline: "\eaba";
$icon-rewind-left-outline: "\eabb";
$icon-rewind-right-outline: "\eabc";
$icon-save-outline: "\eabd";
$icon-scissors-outline: "\eabe";
$icon-search-outline: "\eabf";
$icon-settings-2-outline: "\eac0";
$icon-settings-outline: "\eac1";
$icon-shake-outline: "\eac2";
$icon-share-outline: "\eac3";
$icon-shield-off-outline: "\eac4";
$icon-shield-outline: "\eac5";
$icon-shopping-bag-outline: "\eac6";
$icon-shopping-cart-outline: "\eac7";
$icon-shuffle-2-outline: "\eac8";
$icon-shuffle-outline: "\eac9";
$icon-skip-back-outline: "\eaca";
$icon-skip-forward-outline: "\eacb";
$icon-slash-outline: "\eacc";
$icon-smartphone-outline: "\eacd";
$icon-speaker-outline: "\eace";
$icon-square-outline: "\eacf";
$icon-star-outline: "\ead0";
$icon-stop-circle-outline: "\ead1";
$icon-sun-outline: "\ead2";
$icon-swap-outline: "\ead3";
$icon-sync-outline: "\ead4";
$icon-text-outline: "\ead5";
$icon-thermometer-minus-outline: "\ead6";
$icon-thermometer-outline: "\ead7";
$icon-thermometer-plus-outline: "\ead8";
$icon-toggle-left-outline: "\ead9";
$icon-toggle-right-outline: "\eada";
$icon-trash-2-outline: "\eadb";
$icon-trash-outline: "\eadc";
$icon-trending-down-outline: "\eadd";
$icon-trending-up-outline: "\eade";
$icon-tv-outline: "\eadf";
$icon-twitter-outline: "\eae0";
$icon-umbrella-outline: "\eae1";
$icon-undo-outline: "\eae2";
$icon-unlock-outline: "\eae3";
$icon-upload-outline: "\eae4";
$icon-video-off-outline: "\eae5";
$icon-video-outline: "\eae6";
$icon-volume-down-outline: "\eae7";
$icon-volume-mute-outline: "\eae8";
$icon-volume-off-outline: "\eae9";
$icon-volume-up-outline: "\eaea";
$icon-wifi-off-outline: "\eaeb";
$icon-wifi-outline: "\eaec";

