@import "../../../../libs/styles/main.scss";

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 1092px;
  margin: 0 auto;

  @include breakpoint("mobile") {
    flex-direction: column;
    width: 100%;
  }
}

.sidebar {
  width: 350px;
  background: $color-aqua-haze;
  padding: $general-spacing-unit * 3;
  border-radius: 10px;
  box-sizing: border-box;
  position: relative;

  & i {
    font-size: 24px;
  }

  @include breakpoint("mobile") {
    border-radius: 0;
    width: 100%;
    margin-bottom: $general-spacing-unit;
  }
}

.sidebarTitle {
  font-size: 24px;
  color: $color-cloud-burst;
  line-height: 1;
  margin: 0 0 $general-spacing-unit * 4;
  width: max-content;
}

.content {
  width: 716px;

  @include breakpoint("mobile") {
    padding: 0 $general-spacing-unit * 4;
    box-sizing: border-box;
    width: 100vw;
  }
}

.closeButton {
  width: $general-spacing-unit * 3;
  height: $general-spacing-unit * 3;
  font-size: 0;
  position: absolute;
  right: $general-spacing-unit * 3;
  top: $general-spacing-unit * 3;
  background: transparent;
  border: none;

  svg {
    position: absolute;
    left: 4px;
    top: 3px;
    transform: rotate(45deg);
    width: $general-spacing-unit * 2;
    height: auto;
  }
}
