@import "../../libs/styles/main.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 50vh;
  padding: $general-spacing-unit * 10 $general-spacing-unit * 3;
  background-color: $color-white;

  @include breakpoint("small-tablet-bk") {
    padding: $general-spacing-unit * 4 0 0 0;
  }

  @include breakpoint("tablet-and-below") {
    padding: $general-spacing-unit * 4 0;
  }
}

.title {
  font-size: 56px;
  line-height: 64px;
  margin: 0 0 $general-spacing-unit * 4 0;

  &.secondaryLayout {
    &>b {
      color: $color-primary-enterprise;
    }
  }

  @include breakpoint("tablet") {
    font-size: 48px;
    line-height: 56px;
    margin-bottom: $general-spacing-unit * 3;
  }

  @include breakpoint("mobile") {
    font-size: 32px;
    line-height: 38px;
    margin-bottom: $general-spacing-unit;
  }
}

.description {
  font-size: 24px;
  line-height: 32px;
  color: $color-plantation;
  text-align: center;
  margin-bottom: $general-spacing-unit * 6;
  margin-top: 0;

  @include breakpoint("tablet") {
    margin-bottom: $general-spacing-unit * 5;
  }

  @include breakpoint("mobile") {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: $general-spacing-unit * 3;
  }
}

.filters {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 20vh;
  max-width: 1175px;
  ;

  @include breakpoint("small-tablet-bk") {
    position: inherit;
    max-width: 100%;
  }
}

.content {
  display: flex;
  width: 100%;
  box-sizing: border-box;

  @include breakpoint("tablet-and-below") {
    flex-direction: column;
  }
}