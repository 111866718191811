@import "../../libs/styles/main.scss";

.grid {
  max-width: 1092px;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

.row {
  display: inline-grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;

  @include breakpoint("desktop") {
    margin-bottom: $general-spacing-unit * 3;
  }

  @include breakpoint("tablet") {
    grid-template-columns: 1fr 1fr;
  }

  @include breakpoint("mobile") {
    grid-template-columns: 1fr;
  }
}

.column {
  box-sizing: border-box;
  width: 348px;

  @include breakpoint("mobile") {
    padding: 0;
  }
}
