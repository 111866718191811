@import "../../../../libs/styles/main.scss";

.button {
  position: relative;
  border: 2px solid $color-primary-enterprise;
  background: transparent;
  height: 56px;
  width: 56px;
  border-radius: 56px;
  box-sizing: border-box;
  padding: 0;
  cursor: pointer;
  margin-left: 24px;
  font-size: 0;
  transition: color ease 0.15s, background-color ease 0.15s;

  @include breakpoint("desktop") {
    &:hover {
      background: $color-primary-enterprise;
    }
  }

  img {
    width: 26px;
    height: 26px;
    position: absolute;
    top: 0;
    left: -$general-spacing-unit;
  }

}