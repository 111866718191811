.slick-list {
  margin: 0;
}

.slick-slide {
  opacity: 0;
  transition: opacity ease 0.25s;
}

.slick-active ~ .slick-slide {
  opacity: 1;
}

.slick-slide.slick-active {
  opacity: 1;
  transition-delay: 0.25s;
}

ul.slick-dots {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  position: static;
  margin-top: 72px;
  bottom: 0 !important;
}

ul.slick-dots li {
  display: block;
  margin: 0;
  width: auto;
  height: auto;
}

ul.slick-dots li button {
  width: 12px;
  display: block;
  height: 12px;
  background: #e4e9f2;
  border: none;
  border-radius: 12px;
  font-size: 0;
  margin: 0 4px;
  transition: width ease 0.5s, background-color ease 0.25s;
  padding: 0;
}

.slick-dots li.slick-active button {
  width: 32px;
  background: #1b9b9e;
}

.dot:focus {
  outline: none;
}

.slick-dots li button:before {
  display: none;
}

@media only screen and (max-width: 480px) {
  ul.slick-dots {
    margin-top: 40px;
  }
}
