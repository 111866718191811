@import "../../libs/styles/main.scss";

.container {
  position: relative;
}

.optionsContainer {
  position: absolute;
  background: $color-lilac;
  border-radius: 0.625rem;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0.25rem 0;
  top: 105%;
  transition: all 0.3s ease-in-out;
  width: 100%;
  border: 1px solid $color-green-ocean;
  max-height: 25rem;
  overflow-y: auto;
  z-index: 1;
}

.option {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.125rem;
  line-height: 2.5rem;
  overflow-x: hidden;
  padding: 0;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  padding: 0 1rem;

  &:hover {
    background: $color-white;
  }
}
