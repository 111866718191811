@import "../../libs/styles/main.scss";

.button {
  height: 56px;
  line-height: 56px;
  display: flex;
  align-items: center;
  border: 0;
  background: transparent;
  font-weight: 700;
  font-size: 18px;
  font-family: $font-source-sans;
  cursor: pointer;
  color: $color-plantation;
  transition: color ease 0.25s;

  &:hover {
    color: lighten($color-plantation, 15%);
  }

  img,
  svg {
    max-height: 22px;
    width: 22px;
    margin-right: $general-spacing-unit;
  }
}
