@import "../../../../libs/styles/main.scss";

.image {
  max-width: 318px;
  height: auto;
  margin: 0 auto 16px;
  display: block;
  outline: none;
  width: 100%;
}

.title {
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  color: $color-cloud-burst;
  margin: 0;

  b {
    color: $color-green-ocean;
  }
}

.redirectText {
  text-align: center;
  color: $color-cloud-burst;
  font-size: 16px;
  line-height: 26px;

  b {
    font-size: 18px;
    color: $color-green-ocean;
  }
}

.paragraph {
  text-align: center;
  font-size: 18px;
  line-height: 28px;
  color: $color-cloud-burst;
}
