@import "../../../../libs/styles/main.scss";

.wrapper {
  background: $color-white;
  max-width: 438px;
  box-sizing: border-box;
  padding: $general-spacing-unit * 5;
  box-shadow: 0 2px 20px 0 rgba(27, 155, 158, 0.1),
    0 2px 20px 0 rgba(151, 151, 151, 0.1);
  border-radius: $general-spacing-unit * 2.5;
  margin: $general-spacing-unit * 5 auto;

  @include breakpoint("mobile") {
    margin: $general-spacing-unit * 5 $general-spacing-unit * 4;
    padding: $general-spacing-unit * 3;
  }
}
