@import "../../libs/styles/main.scss";

.label {
  display: block;
}

.input {
  font-size: 16px;
  color: $color-cloud-burst;
  line-height: 22px;
  border: none;
  background: transparent;
  font-family: $font-source-sans;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 0;

  &:before {
    content: "";
    width: 22px;
    height: 22px;
    line-height: 22px;
    box-sizing: border-box;
    display: block;
    margin-right: $general-spacing-unit;
    border: 1px solid $color-blue-haze;
    border-radius: 3px;
    font-weight: 900;
    color: $color-white;
    text-align: center;
    font-size: 12px;
    transition: background-color ease 0.15s, border-color ease 0.15s;
  }

  &.active:before {
    content: "✓";
    background-color: $color-eastern-blue;
    border-color: $color-eastern-blue;
  }

  &.inactive:before {
    background-color: $color-lilac;
  }

  &.invalid:before,
  &.error:before {
    border-color: $color-valencia;
  }
}

.input.secondaryLayout {
  &.active:before {
    content: "✓";
    background-color: $color-secondary-enterprise;
    border-color: $color-secondary-enterprise;
  }
}