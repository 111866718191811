@import "../../libs/styles/main.scss";

.button {
  margin-top: $general-spacing-unit * 2;
  text-align: center;
  height: 56px;
  line-height: 56px;
  font-weight: 700;
  font-size: 18px;
  font-family: $font-source-sans;
  border: 0;
  cursor: pointer;
  width: 100%;
  color: $color-bali-hai;
  background: transparent;
  border-radius: 5px;
  transition: color ease 0.25s;

  &:hover {
    color: $color-plantation;
  }
}
