@import "../../../../libs/styles/main.scss";

.wrapper {
  display: block;
  margin: 0 auto;
  max-width: 1092px;
  width: calc(100% - $general-spacing-unit * 6);

  @include breakpoint("mobile-and-tablet") {
    // max-width: calc(100vw - $general-spacing-unit * 8);
  }
}

.container {
  display: inline-grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: $general-spacing-unit * 3;
  width: 100%;

  @include breakpoint("tablet") {
    grid-template-columns: 1fr 1fr;
  }

  @include breakpoint("tablet-and-below") {
    grid-template-columns: 1fr;
  }
}