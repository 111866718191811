@import "../../../libs/styles/main.scss";

.footer {
  padding: $general-spacing-unit * 4 0;
  border-top: 1px solid #efefef;
  margin: 0;
  box-sizing: border-box;
  font-size: 16px;
}

.footer p {
  font-weight: 700;
  font-size: 14px;
  color: $color-soft-dark;
  margin: 0 0 $general-spacing-unit * 3;

  a {
    text-decoration: none;
    color: #1b9b9e;
  }
}

.footer button {
  max-width: 320px;
}

.button {
  background: transparent;
  border: 0;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-weight: 700;
  padding: 0;
  color: #1b9b9e;
  cursor: pointer;
}


.buttonContainer {
  max-width: 360px;
}
