@import "../../../libs/styles/main.scss";

.wrapper {
  position: relative;
  z-index: 1;
  border: none;
  padding: 0;
  margin: 0;
}

.suffix {
  color: $color-bali-hai;
  font-size: 13px;
  display: block;
  margin: $general-spacing-unit 0 0;
}

.label {
  display: block;
  font-weight: 700;
  font-size: 12px;
  color: $color-cloud-burst;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: $general-spacing-unit;
}

.optionalFlag {
  font-size: 11px;
  line-height: 16px;
  font-weight: 600;
}

.input {
  opacity: 0;
  width: 100%;
  height: 100%;
  appearance: none;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

.fieldValue,
.placeholder {
  font-size: 18px;
  line-height: 1;

  font-weight: $font-weight-semibold;
  position: absolute;
  top: 50%;
  text-align: center;
  display: block;
  width: 100%;
  transform: translateY(-50%);
  z-index: 1;
  pointer-events: none;
}

.fieldValue {
  color: $color-cloud-burst;
}

.placeholder {
  color: $color-bali-hai;
}

.preview {
  position: relative;
  overflow: hidden;
  z-index: 1;
  height: 100px;
  background: $color-lilac center center no-repeat;
  background-size: cover;
  border: 1px dashed $color-catskill-white;
  border-radius: 10px;
  width: 100%;
  display: block;

  &.error {
    border-color: $color-valencia;
  }

  &.focused {
    border-color: $color-eastern-blue;
    border-style: solid;
  }
}

.errorMessage {
  display: block;
  font-size: 12px;
  color: $color-valencia;
  line-height: 16px;
  position: absolute;
  right: 0;
  top: 0;
}
