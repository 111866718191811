@import "../../libs/styles/main.scss";

.itemWrapper {
  margin-bottom: $general-spacing-unit * 3;
  display: flex;
  flex-direction: row;
}

.buttonWrapper {
  width: $general-spacing-unit * 22;

  @include breakpoint("mobile") {
    width: 100%;
  }
}