.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 72vh;
}

.form {
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 20px 0 #1b9b9e1a, 0 2px 20px 0 #9797971a;
  box-sizing: border-box;
  margin: 40px auto;
  max-width: 438px;
  padding: 40px;

  h2 {
    text-align: center;
    font-size: 2rem;
    line-height: 2.5rem;
    color: #222b45;
    margin-top: 0;
  
    b {
      color: #1B9B9E;
    }
  }
}

.inputBox {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}