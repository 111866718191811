@import "../../../../libs/styles/main.scss";

.wrapper {
  background: $color-white;
  padding: $general-spacing-unit * 2 $general-spacing-unit * 3 $general-spacing-unit * 5;
  border-radius: 10px;
  box-sizing: border-box;
  text-align: center;
  max-width: 1092px;
  margin: 0 auto;
  color: $color-cloud-burst;
  box-shadow: 0 2px 20px 0 rgba(27, 155, 158, 0.1),
    0 2px 20px 0 rgba(151, 151, 151, 0.1);
  height: 313px;
  max-width: 1092px;

  @include breakpoint("mobile") {
    padding: $general-spacing-unit * 4 $general-spacing-unit * 3;
  }

  p {
    font-size: 16px;
    line-height: 22px;
    max-width: 283px;
    margin: 0 auto;
  }
}

.illustration {
  margin: 0 auto;
  display: block;
  height: 111px;

  @include breakpoint("mobile") {
    width: 150px;
  }
}

.title {
  font-size: 16px;
  line-height: 26px;
  color: $color-cloud-burst;
  text-align: center;
  margin: $general-spacing-unit 0 $general-spacing-unit * 2;

  @include breakpoint("mobile") {
    font-size: 16px;
    line-height: 20px;
    margin: $general-spacing-unit 0 $general-spacing-unit * 2;
  }
}

.wrapperBanner {
  padding: $general-spacing-unit * 5;
  height: 460px;
  padding: 40px;
  width: calc(100% - 48px);
}

.illustrationBanner {
  height: 144px;
}

.titleBanner {
  font-size: 20px;
  line-height: 26px;
  color: $color-surfie-green;
  max-width: $general-spacing-unit * 50;
  margin: $general-spacing-unit * 2 auto;
}

.secondaryLayout {
  .titleBanner {
    color: $color-primary-enterprise;
  }
}