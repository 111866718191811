@import "../../libs/styles/main.scss";

.wrapper {
  border-radius: 10px;
  padding: $general-spacing-unit * 3;
  background: $color-white;
  box-shadow: 0 2px 20px 0 rgba(27, 155, 158, 0.1),
    0 2px 20px 0 rgba(151, 151, 151, 0.1);
  height: 100%;
  box-sizing: border-box;
}

.list {
  padding: 0;
  margin: 0;
}

.line {
  display: block;
  border-top: 1px solid $color-heavy-white;
  padding: $general-spacing-unit * 3 0;
}

.line:first-child {
  padding-top: 0;
  border: none;
}

.line:last-child {
  padding-bottom: 0;
}

.title {
  font-weight: 700;
  line-height: 40px;
  font-size: 24px;
  color: #1b9b9e;
  text-align: center;
  margin: 0 0 $general-spacing-unit * 4;
}

.positionTitle {
  font-weight: 700;
  font-size: 18px;
  color: #222b45;
  line-height: 24px;
  margin: 0 0 $general-spacing-unit;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.company {
  font-size: 13px;
  color: #1b9b9e;
  font-weight: 700;
}

.date {
  font-size: 13px;
  font-weight: 600;
  color: #222b45;
}

.date:before {
  margin: 0 0.25em;
  content: "|";
}

.footer {
  margin-top: $general-spacing-unit * 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footerLocation {
  max-width: calc(100% - 120px);
}
