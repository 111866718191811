@import "../../libs/styles/main.scss";

.location {
  font-size: 13px;
  color: #253c45;
  line-height: 13px;
  line-height: 32px;
  border-radius: 16px;
  font-weight: 600;
  padding: 0 16px;
  background-color: #f0f7f7;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  i {
    margin-right: 4px;
    font-size: 16px;
  }

  &.enterprise i {
    color: $color-secondary-enterprise;
  }

  &.default i {
    color: $color-surfie-green;
  }

  @include breakpoint("mobile") {
    flex: none;
    box-sizing: border-box;
    max-width: 100%;
  }
}