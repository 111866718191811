@import "../../libs/styles/main.scss";

.wrapper {
  position: relative;
  z-index: 0;

  svg {
    z-index: 1;
    pointer-events: none;
    position: absolute;
    right: $general-spacing-unit * 2;
    bottom: calc(($general-spacing-unit * 7) / 2);
    transform: translateY(50%);
  }
}

.label {
  display: block;
  font-weight: 700;
  font-size: 12px;
  color: $color-cloud-burst;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: $general-spacing-unit;
  text-align: left;
}

.field {
  height: 56px;
  font-weight: 600;
  font-size: 18px;
  color: $color-cloud-burst;
  line-height: 24px;
  border-radius: 10px;
  border: 1px solid #e4e9f2;
  background: $color-lilac;
  font-family: $font-source-sans;
  width: 100%;
  padding: 0 $general-spacing-unit * 2;
  appearance: none;
  outline: none;

  &:focus {
    border-color: $color-eastern-blue;
  }

  &.isInvalid {
    border-color: $color-valencia;
  }

  &.empty {
    color: $color-bali-hai;
  }
}

.error {
  display: block;
  font-size: 12px;
  color: $color-valencia;
  line-height: 16px;
  position: absolute;
  right: 0;
  top: 0;
}