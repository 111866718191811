@import '../../../../libs/styles/main.scss';

.wrapper {
  display: flex;
  flex-direction: column;
}

.firstSection {
  background: url('./assets/first-section-mobile.webp') no-repeat center;
  background-size: cover;
  box-sizing: border-box;
  height: 100vh;
  padding: $general-spacing-unit * 4;

  & > div {
    max-width: 1092px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: auto;
    width: 100%;
    height: 100%;

    & * {
      max-width: 330px;
    }

    & > h2 {
      font-size: 32px;
      line-height: 38px;
      font-weight: 900;
      text-align: center;
    }

    & > button {
      max-height: $general-spacing-unit * 8;
      width: 320px;
      border: solid 2px #326b99;
      background: transparent;
      color: #326b99;
    }

    & > button:hover {
      background-color: #326b99;
      border-color: #326b99;
      color: $color-white;
    }
  }
}

.firstSectionDescription {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  text-align: center;
  color: #222b45;
}

.secondSection {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin-left: 0;
  }

  & > section div:nth-child(1) span,
  & > section div:nth-child(1) h2 > b,
  & > h2,
  & > span b {
    color: #438fcc;
  }
}

.thirdSection,
.fourthSection,
.fifthSection {
  & > section div:nth-child(1) span,
  & > section div:nth-child(1) h2 > b,
  & > h2,
  & > span b {
    color: #438fcc;
  }
}

.sixtySection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #438fcc;
  border-top: solid 16px #e7df46;
  padding: $general-spacing-unit * 7 $general-spacing-unit * 5;

  & * {
    color: $color-white;
  }

  & > span {
    max-width: 950px;
  }

  & > button {
    max-height: $general-spacing-unit * 8;
    width: 320px;
    border: solid 2px $color-white;
    background: transparent;
  }

  & > button:hover {
    background-color: #326b99;
    border-color: #326b99;
  }
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-top: 10rem;

  &>h2 b,
  &>span {
      color: $color-primary-enterprise;
  }
}

@include breakpoint('mobile') {
  .firstSection {
    height: calc(100vh - 80px);
    justify-content: center;
  }
}

@include breakpoint('mobile-and-tablet-2') {
  .firstSection {
    padding: $general-spacing-unit * 7;

    & > div {
      align-items: center;

      & * {
        max-width: 600px;
      }
    }
  }
}

@include breakpoint('tablet-and-desktop-2') {
  .firstSection {
    height: calc(100vh - 80px);
    padding: $general-spacing-unit * 10;
    background: url('./assets/first-section.webp') no-repeat center;
    background-size: cover;

    & > div {
      & * {
        max-width: 45vw;
      }

      & > h2 {
        font-size: 40px;
        line-height: 50px;
        text-align: left;
      }

      & > button {
        max-width: 320px;
        margin-top: 40px;
      }
    }
  }

  .firstSectionDescription {
    font-size: 18px;
    line-height: 24px;
    text-align: left;
    margin: 0;
  }

  .secondSection {
    padding-top: $general-spacing-unit * 10;

    & > h2,
    & > span {
      display: inline-block;
    }

    img {
      margin-left: $general-spacing-unit * 7;
    }
  }

  .thirdSection {
    img {
      margin-right: $general-spacing-unit * 10;
    }
  }

  .contactSection {
    padding: $general-spacing-unit * 10 $general-spacing-unit * 5;
  }
}

@include breakpoint('desktop') {
  .firstSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 80px);
    background: url('./assets/first-section.webp') no-repeat center;
    background-size: cover;
    padding: $general-spacing-unit * 20;
    align-items: flex-start;
    box-sizing: border-box;

    & > div {
      & * {
        max-width: $general-spacing-unit * 73;
      }

      & > h2 {
        font-size: 56px;
        line-height: 66px;
        font-weight: 900;
        margin-bottom: $general-spacing-unit * 3;
        text-align: left;
      }

      & > button {
        max-height: $general-spacing-unit * 8;
        max-width: 320px;
        margin-top: $general-spacing-unit * 5;
      }
    }
  }

  .firstSectionDescription {
    font-size: 24px;
    line-height: 32px;
    color: #222b45;
    font-weight: 600;
    margin: 0;
    text-align: left;
  }

  .secondSection,
  .thirdSection,
  .fourthSection,
  .fifthSection {
    & > section {
      max-width: 1092px;
      width: 100%;
    }

    & > section div:nth-child(1) div:nth-child(1) {
      width: max-content;
    }
  }

  .contactSection {
    padding: $general-spacing-unit * 10 $general-spacing-unit * 5;
  }
}
