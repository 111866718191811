@import "../../libs/styles/main.scss";

.wrapper {
  border-radius: 20px;
  background-color: #fff;
  background-position: right center;
  background-repeat: no-repeat;
  padding: 56px;
  box-shadow: 0 2px 20px 0 rgba(27, 155, 158, 0.1),
    0 2px 20px 0 rgba(151, 151, 151, 0.1);
  max-width: 1092px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;

  @include breakpoint("tablet") {
    background-size: 0 0;
    margin: 0 $general-spacing-unit * 10;
    margin-bottom: $desktop-vertical-spacing;
  }

  @include breakpoint("mobile") {
    background-size: 0 0;
    padding: $general-spacing-unit * 3;
    margin: $general-spacing-unit * 4 $general-spacing-unit * 3;
  }
}

.formWrapper {
  max-width: 560px;
  margin-top: $general-spacing-unit * 4;
}