@import "../../libs/styles/main.scss";

.button {
  height: 38px;
  line-height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border: 2px solid transparent;
  transition: opacity ease 0.15s;
  text-decoration: none;

  @include breakpoint("desktop") {
    &:hover {
      opacity: 0.5;
    }
  }

  svg {
    width: $general-spacing-unit * 3;
    height: auto;
  }
}

.linkText {
  margin-left: $general-spacing-unit;
  font-weight: 600;
}