@import "../../../../libs/styles/main.scss";

.wrapper {
  margin: $general-spacing-unit * 5 auto $general-spacing-unit * 4;
  text-align: center;

  @include breakpoint("mobile") {
    margin: 0;
    padding: $general-spacing-unit * 3;
  }
}

.title {
  font-size: 48px;
  line-height: 1;
  margin: 0 0 $general-spacing-unit * 4;

  @include breakpoint("mobile") {
    display: none;
  }

  b {
    color: $color-eastern-blue;
  }
}

.subtitle {
  font-size: 32px;
  line-height: 1;
  margin: $general-spacing-unit * 3 0 $general-spacing-unit * 2;

  @include breakpoint("mobile") {
    margin-top: $general-spacing-unit * 4;
    margin-bottom: $general-spacing-unit;
  }

  b {
    color: $color-eastern-blue;
  }
}

.illustration {
  display: block;
  margin: 0 auto;

  @include breakpoint("mobile") {
    width: 100%;
  }
}

.message {
  font-size: 20px;
  color: $color-cloud-burst;
}

.buttonWrapper {
  width: 300px;
  margin: 0 auto;
}

.secondaryLayout {
  b {
    color: #438FCC;
  }

  button,
  button:hover {
    background-color: #438FCC;
    border-color: #438FCC;
  }
}