@import "../../../../libs/styles/main.scss";

.title {
  color: $color-cloud-burst;
  margin: 0 0 $general-spacing-unit * 4;
  font-size: 32px;
  line-height: 1;
  outline: none;

  b {
    color: $color-eastern-blue;
  }
}
