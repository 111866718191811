@import "../../../../libs/styles/main.scss";

.confirmButton {
  font-weight: 600;
  font-size: 18px;
  font-family: $font-source-sans;
  width: 100%;
  border: 0;
  background: transparent;
  height: 56px;
  line-height: 56px;
  cursor: pointer;

  span {
    color: $color-primary-enterprise;

    &:hover {
      text-decoration: underline;
    }
  }
}

.wrapper {
  h2>b,
  .confirmButton span {
    color: $color-primary-enterprise;
  }

}