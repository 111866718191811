@import "../../libs/styles/main.scss";

.container {
  min-height: calc(100vh - 390px);
  display: flex;
  align-items: center;
}

.wrapper {
  width: 32px;
  height: 32px;
  animation: spin 0.75s linear infinite;
  display: block;
  margin: 0 auto;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    50% {
      opacity: 0.9;
    }

    100% {
      transform: rotate(360deg);
    }
  }
}