@import "../../../../libs/styles/main.scss";

.wrapper {
  position: absolute;
  right: $general-spacing-unit * 3;
  top: 75px;
  transform: translateY(-50%);
  z-index: 2;
}

.title {
  border: none;
  background: transparent;
  font-weight: $font-weight-bold;
  font-family: $font-source-sans;
  font-size: 20px;
  color: $color-eastern-blue;
  line-height: 20px;
  text-decoration: underline;
  text-transform: lowercase;
  position: relative;
  z-index: 0;
  padding: 0;
  line-height: 40px;
  cursor: pointer;

  svg {
    margin-left: $general-spacing-unit * 0.5;
  }
}

.optionList {
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 1;
  background: $color-white;
  border-radius: 5px;
  width: 150px;
  padding: $general-spacing-unit 0;
  box-shadow: 0 2px 20px 0 rgba(27, 155, 158, 0.1),
    0 2px 20px 0 rgba(151, 151, 151, 0.1);
}

.optionButton {
  font-family: $font-source-sans;
  font-size: 12px;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: $general-spacing-unit;
  display: block;
  width: 100%;
  text-align: left;
}
