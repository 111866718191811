@import "../../../../libs/styles/main.scss";

$iconHeight: 48px;

.container {
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    height: $iconHeight;
    width: auto;
    margin-right: $general-spacing-unit;
  }
}

.bigText {
  font-size: 24px;
  line-height: 120%;
  font-weight: $font-weight-bold;
  color: $color-salmon;
  display: block;
}

.smallText {
  display: block;
  font-size: 18px;
  line-height: 1;
  font-weight: $font-weight-bold;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.redText {
    color: $color-valencia;
  }

  svg {
    height: $iconHeight;
    width: auto;
  }
}
