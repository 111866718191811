@import "../../../../libs/styles/main.scss";

.wrapper {
  box-shadow: 0 2px 20px 0 rgba(27, 155, 158, 0.1),
    0 2px 20px 0 rgba(151, 151, 151, 0.1);
  border-radius: 10px;
  background: $color-white;
  padding: $general-spacing-unit * 5 $general-spacing-unit * 3
    $general-spacing-unit * 3;
  box-sizing: border-box;
  position: relative;
}

.title {
  font-size: 24px;
  line-height: 120%;
  font-weight: $font-weight-bold;
  margin: 0 0 $general-spacing-unit;
  color: $color-cloud-burst;
  max-width: 85%;
}

.subtitle {
  font-size: 16px;
  color: $color-cloud-burst;
  line-height: 1;
  font-weight: $font-weight-bold;
  margin: $general-spacing-unit 0 $general-spacing-unit * 2;
}

.description {
  font-size: 16px;
  line-height: 26px;
  color: $color-cloud-burst;
  margin: $general-spacing-unit * 2 0;
}
