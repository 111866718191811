@import "../../../libs/styles/main.scss";

.wrapper {
  margin: $general-spacing-unit * 3 auto $general-spacing-unit * 8;
  color: $color-soft-dark;
  padding-left: $general-spacing-unit * 6;

  @include breakpoint("mobile") {
    padding: 0;
    margin: 0;
  }
}

.preffix {
  font-size: 16px;
  margin: 0;
  font-weight: $font-weight-bold;
  color: $color-soft-dark;
  display: block;
  text-transform: uppercase;
}

.name {
  margin-top: $general-spacing-unit * 2;
  font-size: 16px;
  display: block;
}
