@import "../../../../libs/styles/main.scss";

$iconHeight: 48px;

.wrapper {
  color: $color-white;
  padding: $general-spacing-unit * 3 $general-spacing-unit * 2;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.taken {
    background-color: $color-salmon;
  }

  &.blocked {
    background-color: $color-valencia;
  }
}

.iconWrapper {
  margin-right: $general-spacing-unit * 2;

  svg {
    width: 48px;
    height: auto;
  }
}

.text {
  font-size: 20px;
  line-height: $general-spacing-unit * 3;
  font-weight: $font-weight-bold;
}
