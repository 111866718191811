@import "../../libs/styles/main.scss";

.item {
  display: block;
}

.button {
  background: transparent;
  height: $general-spacing-unit * 6;
  line-height: $general-spacing-unit * 6;
  font-size: 18px;
  color: $color-cloud-burst;
  padding: 0 $general-spacing-unit * 2;
  font-family: $font-source-sans;
  border: none;
  width: 100%;
  cursor: pointer;
  text-align: left;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;

  &:hover {
    background: $color-lilac;
  }
}
