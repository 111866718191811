@import "../../../../libs/styles/main.scss";

.form {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: $general-spacing-unit * 2;

  p {
    margin: 0;
    font-size: 18px;
    line-height: 28px;
  }

  button {
    margin: 0;
  }
}

.modalWrapper {
  background-color: #1B9B9E;
  border: solid 2px #fff;

  & p {
    font-weight: 400;
    text-align: center;
    font-size: 20px;
    margin-top: 0;
    color: #fff;
    line-height: 28px;
  }
}

.modalSubmitButton {
  border-color: #fff !important;
  color: #fff!important;
}

.modalSkipButton {
  color: #E9E9E9 !important;
}

@include breakpoint("mobile") {
  .form {
    button {
      padding: 0;
    }
  }
}