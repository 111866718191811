@import "../../../../libs/styles/main.scss";

.button {
  background: $color-swans-down;
  color: $color-plantation;
  font-size: 13px;
  border: none;
  line-height: $general-spacing-unit * 4;
  border-radius: $general-spacing-unit * 2;
  font-family: $font-source-sans;
  font-weight: $font-weight-semibold;
  padding: 0 $general-spacing-unit * 2 0 $general-spacing-unit * 3.5;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin: $general-spacing-unit * 0.5;

  svg {
    position: absolute;
    left: $general-spacing-unit * 1.5;
    top: 11px;
    transform: rotate(45deg);
    width: 12px;
    height: auto;
  }
}
