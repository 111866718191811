@import "../../../../libs/styles/main.scss";

.button {
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  font-size: 0;
  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  position: relative;
  padding-left: $general-spacing-unit * 2;
  margin-left: $general-spacing-unit * 2;

  @include breakpoint("mobile") {
    width: max-content;
    align-items: flex-end;

    button {
      width: max-content;
    }
  }

  &:before {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    content: "";
    background: rgba($color-white, 0.5);
    display: block;
    width: 2px;
    height: $general-spacing-unit * 3;
    border-radius: 2px;
    margin-right: $general-spacing-unit;

    @include breakpoint("mobile") {
      margin-right: calc(#{$general-spacing-unit} + 38px);
    }
  }

  i {
    background: $color-white;
    width: $general-spacing-unit * 2;
    height: 2px;
    border-radius: 2px;
    display: block;
    margin: 1.5px 0;
    transition: opacity ease 0.15s;
  }

  &:hover i {
    opacity: 0.5;
  }
}