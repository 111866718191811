@import "../../../../libs/styles/main.scss";

.position {
  display: grid;
  row-gap: $general-spacing-unit * 2;
  column-gap: $general-spacing-unit * 2;

  @include breakpoint("mobile") {
    grid-template-columns: 1fr;
  }

  @include breakpoint("tablet-and-desktop") {
    grid-template-columns: 1fr 1fr 1fr;

    [data-name="level"] {
      grid-column: span 2;
    }
  }

  & + & {
    border-top: 1px solid $color-blue-haze;
    margin-top: $general-spacing-unit * 3;
    padding-top: $general-spacing-unit * 3;
  }
}
