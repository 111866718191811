$general-spacing-unit: 8px;

$desktop-vertical-spacing: 72px;

$mobile-side-spacing: 56px;
$mobile-vertical-spacing: 72px;

$color-black: #000;
$color-plantation: #253c45;
$color-surfie-green: #137784;
$color-eastern-blue: #1b9b9e;
$color-green-ocean: #009ea0;
$color-swans-down: #ddf0ee;
$color-valencia: #d72e2f;
$color-cloud-burst: #222b45;
$color-salmon: #fc846e;
$color-soft-dark: #202b47;
$color-bali-hai: #8893ab;
$color-light-grey: #8f9bb3;
$color-soft-white: #e4e9f2;
$color-soft-grey: #c5cee0;
$color-jagged-ice: #c5e8e6;
$color-edward: #a6b1b1;
$color-blue-haze: #c5cee0;
$color-catskill-white: #e4e9f2;
$color-aqua-haze: #f0f7f7;
$color-catskill: #fbfcfd;
$color-lilac: #f7f9fc;
$color-white: #fff;
$color-rock-blue: #8f9bb3;
$color-light-grey: #8f9bb3;
$color-heavy-white: #edf1f7;

$color-first-shadow-section: rgba(27, 155, 158, 0.1);
$color-second-shadow-section: rgba(151, 151, 151, 0.1);

$color-ocean: #f0f7f7;

$color-primary-enterprise: #438fcc;
$color-secondary-enterprise: #326b99;
$color-secondary-enterprise-hover: #234b6c;

$font-weight-regular: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$font-source-sans: "Source Sans Pro", -apple-system, BlinkMacSystemFont,
  "Segoe UI", "Roboto", "Ubuntu", sans-serif;
