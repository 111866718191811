@import "../../libs/styles/main.scss";

.wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-aqua-haze;
    padding: $general-spacing-unit * 3 $general-spacing-unit * 7;
    box-sizing: border-box;

    & button {
        max-width: 288px;
        margin: 0;
        padding: 0;
    }
}

.title {
    line-height: 24px;
    text-align: left;
    margin: 0 0 $general-spacing-unit * 4;
}