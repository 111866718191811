@import "../../libs/styles/main.scss";

.wrapper {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity ease 0.25s;
  opacity: 0;
  visibility: hidden;
  z-index: 3;
  overflow-y: auto;

  @include breakpoint("mobile") {
    align-items: flex-start;
  }

  &.visible {
    opacity: 1;
    visibility: visible;
  }
}

.container {
  background: $color-white;
  width: 480px;
  border-radius: 20px;
  overflow: hidden;
  padding: $general-spacing-unit * 5;
  box-sizing: border-box;

  @include breakpoint("mobile") {
    width: calc(100vw - $general-spacing-unit * 5);
    margin-top: $general-spacing-unit * 3;
    margin-bottom: $general-spacing-unit * 3;
  }
}

.header {
  font-family: $font-source-sans;
  font-size: 24px;
  line-height: 1;
  margin: 0 0 $general-spacing-unit * 3;
  font-weight: $font-weight-bold;
  color: $color-eastern-blue;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;

  @include breakpoint("tablet-and-desktop") {
    position: relative;
  }
}

.content {
  font-size: 16px;
  font-weight: $font-weight-bold;
  line-height: 24px;
  color: $color-cloud-burst;
}

.title {
  text-align: center;
  display: block;
  flex: 1;
}

.closeButton {
  border: none;
  background: transparent;
  width: 22px;
  height: 22px;
  padding: 0;
  transform: rotate(45deg);
  cursor: pointer;

  svg {
    width: 100%;
    height: auto;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @include breakpoint("tablet-and-desktop") {
    position: absolute;
    right: 0;
    top: 0;
  }

  @include breakpoint("mobile") {
    position: absolute;
    top: $general-spacing-unit * 5;
    right: $general-spacing-unit * 5;
    z-index: 3;
    background: white;
  }
}
