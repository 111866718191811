@import "../../libs/styles/main.scss";

.wrapper {
  position: relative;
  padding: 0;
  border: none;
  margin: 0;
  width: 100%;

  &.inverse {
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    @include breakpoint(mobile) {
      flex-direction: column;
    }

    > div:first-child {
      margin-right: $general-spacing-unit * 3;

      @include breakpoint(mobile) {
        margin-right: 0;
        margin-bottom: $general-spacing-unit;
      }
    }
  }
}

.label {
  display: block;
  font-weight: 700;
  font-size: 12px;
  color: $color-cloud-burst;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: $general-spacing-unit;

  &.forRadio {
    font-size: 18px;
    text-transform: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    cursor: pointer;
    margin-bottom: 0;
    white-space: nowrap;

    &:before,
    &:after {
      content: "";
      display: block;
      border-radius: 100%;
    }

    &:before {
      width: $general-spacing-unit * 3;
      height: $general-spacing-unit * 3;
      box-sizing: border-box;
      margin-right: 8px;
      background-color: #f6f7f9;
      border: 1px solid #c5cee0;
    }

    &:after {
      position: absolute;
      left: 5px;
      top: 5px;
      width: $general-spacing-unit * 1.75;
      height: $general-spacing-unit * 1.75;
    }

    &.active {
      &:before {
        border-color: $color-eastern-blue;
      }

      &:after {
        background-color: $color-eastern-blue;
      }
    }
  }
}

.optionalFlag {
  font-size: 11px;
  line-height: 16px;
  font-weight: 600;
}

.error {
  display: block;
  font-size: 12px;
  color: $color-valencia;
  line-height: 16px;
  margin-top: 2px;
  position: absolute;
  right: 0;
  top: 0;

  &.isRadioError {
    position: static;
    margin-top: $general-spacing-unit * -0.5;
    margin-bottom: $general-spacing-unit;
    right: unset;
    top: unset;
  }
}

.field {
  font-family: $font-source-sans;
  height: 156px;
  font-weight: 600;
  font-size: 18px;
  color: $color-cloud-burst;
  line-height: 24px;
  border-radius: 10px;
  border: 1px solid #e4e9f2;
  background: $color-lilac;
  background-repeat: no-repeat;
  background-position: calc(100% - #{$general-spacing-unit * 2})
    $general-spacing-unit * 2;
  outline: none;
  width: 100%;
  padding: $general-spacing-unit * 1.75 $general-spacing-unit * 2;
  // padding-right: $general-spacing-unit * 5;
  box-sizing: border-box;
  resize: vertical;
  min-height: 56px;

  &[type] {
    padding: 0 $general-spacing-unit * 2;
    // padding-right: $general-spacing-unit * 5;
    height: 56px;
    line-height: 20px;
  }

  &.invalid,
  &.invalid:focus {
    border-color: $color-valencia;
  }

  &::placeholder {
    color: #8893ab;
  }

  &:focus {
    background-color: $color-white;
    border-color: $color-eastern-blue;
  }

  &.disabled {
    cursor: not-allowed;
  }
}

.radioList {
  display: flex;
  column-gap: $general-spacing-unit * 5;

  @include breakpoint("mobile") {
    grid-template-columns: 1fr;
    row-gap: $general-spacing-unit * 2;
  }
}

.radio {
  // visibility: hidden;
  position: absolute;
  top: -999px;
  left: -999px;
  opacity: 0;

  &:focus {
    & + label {
      &:before {
        border-color: $color-eastern-blue;
      }

      &:after {
        background-color: $color-eastern-blue;
      }
    }
  }
}

.iconWrapper {
  position: absolute;
  right: 0;
  top: 24px;
  height: 56px;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;
  background: transparent;
  pointer-events: none;
  outline-color: $color-eastern-blue;

  &[aria-label] {
    pointer-events: initial;
    cursor: pointer;
  }
}
