@import "../../../../libs/styles/main.scss";

.button {
  cursor: pointer;
  border: 2px solid $color-surfie-green;
  box-sizing: border-box;
  border-radius: 5px;
  background: transparent;
  padding: 0;
  font-family: $font-source-sans;
  color: $color-surfie-green;
  font-weight: $font-weight-bold;
  display: flex;
  align-items: center;

  &.alignCenter {
    margin-left: auto;
    margin-right: auto;
  }

  &.small {
    line-height: $general-spacing-unit * 4;
    padding-left: $general-spacing-unit * 3;
    padding-right: $general-spacing-unit * 3;
    font-size: 13px;
    margin-top: $general-spacing-unit * 3;
    margin-bottom: $general-spacing-unit * 3;
  }

  &.default {
    line-height: $general-spacing-unit * 7;
    padding-left: $general-spacing-unit * 8;
    padding-right: $general-spacing-unit * 8;
    font-size: 18px;
  }

  &.disabled {
    cursor: default;
  }

  &:hover {
    background-color: $color-surfie-green;
    color: $color-white;
  }
}

.iconWrapper {
  display: flex;
  margin-right: $general-spacing-unit;
}