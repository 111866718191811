@import "../../libs/styles/main.scss";

.wrapper {
  width: 1092px;
  max-width: 100vw;
  margin: 64px auto;

  @include breakpoint("tablet") {
    padding: 0 32px;
    box-sizing: border-box;
  }

  @include breakpoint("mobile") {
    padding: 0;
  }
}
