@import "../../../../../libs/styles/main.scss";

.emailChangeMessage {
  font-size: 13px;
  color: $color-bali-hai;
  margin: $general-spacing-unit 0 $general-spacing-unit * 3;

  a {
    color: $color-primary-enterprise;
    font-weight: 700;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
