@import "../../../../libs/styles/main.scss";

.wrapper {
  border-bottom: 1px solid $color-bali-hai;
  margin: $general-spacing-unit * 3 0;
  position: relative;
}

.title {
  color: $color-surfie-green;
  line-height: 1;
  font-size: 18px;
  margin: 0 0 $general-spacing-unit;
  width: max-content;

  @include breakpoint("mobile") {
    margin-bottom: $general-spacing-unit * 2.5;

    &.open {
      margin-bottom: $general-spacing-unit * 0.5;
    }
  }
}

.button {
  display: none;

  @include breakpoint("mobile") {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 0;
    height: $general-spacing-unit * 3;
    width: $general-spacing-unit * 3;
    padding: 0;
    background: none;
    border: none;
  }
}

.childrenWrapper {
  overflow: hidden;

  @include breakpoint("mobile") {
    &.open {
      max-height: 9999vh;
    }

    &.closed {
      max-height: 0;
    }
  }
}
