@import "../../libs/styles/main.scss";

.list {
  border: 1px solid $color-eastern-blue;
  border-top: none;
  padding: 0;
  margin: 0;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
  max-height: $general-spacing-unit * 6 * 3 + $general-spacing-unit;
  overflow-y: auto;
  position: absolute;
  top: calc(100% - $general-spacing-unit);
  padding-top: $general-spacing-unit;
  left: 0;
  z-index: 3;
  width: 100%;
  background: $color-white;
  box-sizing: border-box;

  &.visible {
    opacity: 1;
    visibility: visible;
  }
}
