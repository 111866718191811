@import "../../libs/styles/main.scss";

.subtitle {
  font-weight: 700;
  font-size: 16px;
  color: #1b9b9e;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 0 0 16px;
  display: block;

  @include breakpoint(mobile) {
    margin-bottom: 8px;
    font-size: 13px;
    line-height: 16px;
  }
}
