@import "../../../libs/styles/main.scss";

.wrapper {
    display: flex;
    flex-direction: column;
    border-bottom: solid 1px $color-catskill-white;
    width: 100%;
    text-align: left;
    padding-bottom: $general-spacing-unit * 3;
    margin-bottom: $general-spacing-unit * 5;

    @include breakpoint ("small-tablet-bk") {
        &:last-child {
            margin-bottom: 0;
        }
    }

    &.secondaryLayout {
        .pretension b {
            color: #438FCC;
        }

        a {
            background-color: #326B99;
            border-color: #326B99;
        }
    }
}

.title {
    font-weight: 700;
    font-size: 24px;
    line-height: 18px;
    color: $color-cloud-burst;
    margin-bottom: $general-spacing-unit * 3;

    @include breakpoint ("mobile") {
        font-size: 18px;
        margin-bottom: $general-spacing-unit * 2;
    }
}

.description {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &>a {
        max-width: 167px;
        font-weight: 700;
    }
}

.details {
    display: flex;
    flex-direction: column;
}

.pretension {
    font-size: 16px;
    line-height: 21px;
    font-weight: 700;
    margin-right: $general-spacing-unit * 3;
    color: $color-cloud-burst;

    b {
        color: $color-eastern-blue;
    }

    @include breakpoint ("mobile") {
        font-size: 13px;
        line-height: 18px;
    }
}