@import "../../libs/styles/main.scss";

.wrapper {
  &.visible {
    display: block;
    opacity: 1;
    visibility: visible;
  }

  &.hidden {
    display: none;
    opacity: 0;
    visibility: hidden;
  }

}