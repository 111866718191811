@import "../../libs/styles/main.scss";

.wrapper {
  display: flex;
  flex-direction: column;

  button + button {
    margin: $general-spacing-unit * 2 0 0;
  }

  &.inverse {
    flex-direction: column-reverse;

    button + button {
      margin: 0 0 $general-spacing-unit * 2 0;
    }
  }
}

.separator {
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  color: $color-cloud-burst;
  position: relative;
  text-align: center;
  margin: $general-spacing-unit * 3 0;

  &:before {
    content: "";
    height: 1px;
    background: $color-cloud-burst;
    width: 100%;
    display: block;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    z-index: 0;
  }

  span {
    background: $color-white;
    z-index: 1;
    display: inline-block;
    padding: 0 20px;
    position: relative;
    margin: 0 !important;
  }
}
