@import "../../../../libs/styles/main.scss";

.list {
  padding: 0;
  margin: 0;
}

.item {
  display: grid;
  grid-template-areas:
    "recruiter date"
    "status status"
    "note note";
  font-size: 18px !important;
  line-height: 140% !important;
  font-weight: $font-weight-regular !important;
  grid-row-start: 2;
  margin: 0 !important;
  row-gap: $general-spacing-unit * 0.5;
  padding: $general-spacing-unit * 3 0;
  border-bottom: 1px solid $color-catskill-white;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }

  b {
    font-weight: $font-weight-semibold !important;
  }
}

.recruiterName {
  grid-area: recruiter;
  font-weight: $font-weight-bold !important;
  padding-bottom: $general-spacing-unit;
}

.date {
  grid-area: date;
  text-align: right;
  font-weight: $font-weight-bold !important;
  padding-bottom: $general-spacing-unit;
}

.status {
  grid-area: status;
}

.note {
  grid-area: note;
}
