@import "../../../../libs/styles/main.scss";

.wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: align-items;
}

.steps {
  font-size: 22px;
  font-weight: $font-weight-bold;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  line-height: 68px;
  width: 68px;
}

.textWrapper {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-left: $general-spacing-unit * 5;
}

.currentStep {
  text-align: right;
  margin: 0 0 $general-spacing-unit;
  font-weight: $font-weight-bold;
  font-size: 24px;
  line-height: 24px;
  color: $color-cloud-burst;
}

.nextStep {
  text-align: right;
  margin: 0;
  font-weight: $font-weight-semibold;
  font-size: 14px;
  line-height: 14px;
  color: $color-cloud-burst;
}

.path {}

.circle {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
}