@import "../../libs/styles/main.scss";

.separator {
  background: $color-blue-haze;
  height: 1px;
  width: 100%;
  max-width: 1092px;
  margin: $general-spacing-unit * 6 auto;

  @include breakpoint("mobile-and-tablet") {
    max-width: calc(100% - #{$general-spacing-unit * 8});
  }
}
