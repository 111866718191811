@import "../../libs/styles/main.scss";

.link {
  text-decoration: none;
  text-align: center;
  transition: color ease 0.25s, opacity ease 0.25s;
  height: 56px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  opacity: 1;
  margin: $general-spacing-unit * 2 0;

  &:hover {
    opacity: 0.9;
  }

  @include breakpoint("mobile") {
    opacity: 1;
  }
}

.text {
  display: block;
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  color: $color-white;
  margin-left: $general-spacing-unit;
}

.icon {
  max-height: 22px;
  max-width: 24px;
  display: block;
}

.loadingMessage {
  color: $color-white;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: $general-spacing-unit * 2;
  padding-left: $general-spacing-unit * 2;
  border-left: 2px solid rgba($color-white, 0.4);
  font-size: 18px;
  line-height: 1;

  &.active {
    border: none;
    padding-left: 0;
    margin-left: 0;
  }

  img,
  svg {
    margin-right: $general-spacing-unit;
  }
}
