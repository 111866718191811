@import "../../../../libs/styles/main.scss";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &>h1 {
    margin-right: $general-spacing-unit * 2.5;
  }

  @include breakpoint("tablet") {
    justify-content: flex-start;
  }

  @include breakpoint("mobile") {
    flex-wrap: wrap;

    &>button {
      line-height: inherit;
      height: inherit;
      order: 3;
    }
  }
}