@import "../../../../libs/styles/main.scss";

.wrapper {
    display: flex;
    flex-direction: column;
    max-width: 360px;
    border-radius: 20px;
    padding-bottom: $general-spacing-unit * 5;
    overflow: hidden;
    box-sizing: border-box;
    background: $color-white;
    width: 100%;
}

.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: $general-spacing-unit * 5 24px 8px;
    border-radius: 20px;
    clip-path: polygon(50% 0%, 100% 0, 100% 89%, 50% 100%, 0 89%, 0 0);
    transform: translateY(-2px);
    font-size: 24px;

    &.primary {
        background-color: #042F4D;

        &>i {
            color: $color-white;
        }
    }

    &.secondary {
        background-color: #10636E;

        i {
            color: $color-white;
        }
    }

    &.third {
        background-color: #FFC300;

        & * {
            color: #06426B;
        }
    }

    &.fourth {
        background-color: #22BAB0;

        & * {
            color: #0B3B37;
        }
    }
}

.title {
    font-size: 40px;
    line-height: 48px;
    color: $color-white;
    max-width: 240px;
    text-align: center;
    margin: 0;
}

.paragraph {
    font-size: 16px;
    line-height: 22px;
    color: $color-white;
    text-align: center;
    margin-bottom: $general-spacing-unit * 2;
}

.body {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: 0;
}

.card {
    border-bottom: dashed 2px;
    width: 100%;
    display: flex;
    padding: 24px 14px 0;
    box-sizing: border-box;

    i {
        font-size: 24px;
    }

    &.primary {
        color: #042F4D;
        border-color: #042F4D;

        i {
            color: #638AE7;
        }
    }

    &.secondary {
        color: #10636E;
        border-color: #10636E;

        i {
            color: #28B8AE;
        }
    }

    &.third {
        color: #06426B;
        border-color: #FFC300;

        i {
            color: #5312B3;
        }
    }

    &.fourth {
        color: #0B3B37;

       i {
            color: #22BAB0;
        }
    }
}

.card:nth-child(1) {
    min-height: 148px;
    padding-top: 0;
}

.card:nth-child(2) {
    min-height: 105px;
}

.card:nth-child(3) {
    min-height: 126px;
}

.card:nth-child(4) {
    min-height: 148px;
}

.card:nth-child(5) {
    min-height: 105px;
}

.card:nth-child(6) {
    border-bottom: none;
}

.actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    gap: $general-spacing-unit * 3;
}

.btnFirstAction {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 18px 10px;
    gap: 4px;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    border-radius: 5px;
    cursor: pointer;
    border: 0;

    & i {
        font-size: 20px;
    }

    &.primary {
        background-color: #042F4D;
        color: white;
    }

    &.secondary {
        background-color: #10636E;
        color: white;
    }

    &.third {
        background-color: #FFC300;
        color: #06426B;
    }

    &.fourth {
        background-color: #22BAB0;
        color: white;
    }
}

.btnSecondAction {
    font-size: 16px;
    font-weight: 600;
    background-color: transparent;
    border: 0;
    cursor: pointer;

    &.primary {
        color: #042F4D;
    }

    &.secondary {
        color: #10636E;
    }

    &.third {
        color: #06426B;
    }
}

.icon {
    &>svg {
        width: 24px;
        height: 24px;
    }
}

.cardTitle {
    margin-left: 4px;

    &>span {
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
    }

    &>p {
        color: #042F4D;
        text-align: left;
    }
}