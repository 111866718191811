@import "../../libs/styles/main.scss";

.list {
  padding: 0;
  margin: 0;
  color: $color-soft-dark;

  li {
    display: block;
    font-size: 16px;
    margin: $general-spacing-unit 0;
    position: relative;
    padding-left: $general-spacing-unit * 2.5;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  b {
    font-weight: $font-weight-semibold;
  }

  svg {
    max-width: $general-spacing-unit * 2;
    max-height: $general-spacing-unit * 2;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: $general-spacing-unit;
    transform: translate(-50%, -50%);
  }
}
