@import "../../../../libs/styles/main.scss";

.form {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: $general-spacing-unit * 3;

  button {
    margin: 0;
  }
}
