@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?rrs4sx');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?rrs4sx#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?rrs4sx') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?rrs4sx') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?rrs4sx##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-inkoins {
  &:before {
    content: $icon-inkoins; 
  }
}
.icon-cifrao {
  &:before {
    content: $icon-cifrao; 
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram; 
  }
}
.icon-whatsapp {
  &:before {
    content: $icon-whatsapp; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-like-outline {
  &:before {
    content: $icon-like-outline; 
  }
}
.icon-like-fill {
  &:before {
    content: $icon-like-fill; 
  }
}
.icon-activity {
  &:before {
    content: $icon-activity; 
  }
}
.icon-alert-circle {
  &:before {
    content: $icon-alert-circle; 
  }
}
.icon-alert-triangle {
  &:before {
    content: $icon-alert-triangle; 
  }
}
.icon-archive {
  &:before {
    content: $icon-archive; 
  }
}
.icon-arrow-back {
  &:before {
    content: $icon-arrow-back; 
  }
}
.icon-arrow-circle-down {
  &:before {
    content: $icon-arrow-circle-down; 
  }
}
.icon-arrow-circle-left {
  &:before {
    content: $icon-arrow-circle-left; 
  }
}
.icon-arrow-circle-right {
  &:before {
    content: $icon-arrow-circle-right; 
  }
}
.icon-arrow-circle-up {
  &:before {
    content: $icon-arrow-circle-up; 
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down; 
  }
}
.icon-arrow-downward {
  &:before {
    content: $icon-arrow-downward; 
  }
}
.icon-arrow-forward {
  &:before {
    content: $icon-arrow-forward; 
  }
}
.icon-arrow-ios-back {
  &:before {
    content: $icon-arrow-ios-back; 
  }
}
.icon-arrow-ios-downward {
  &:before {
    content: $icon-arrow-ios-downward; 
  }
}
.icon-arrow-ios-forward {
  &:before {
    content: $icon-arrow-ios-forward; 
  }
}
.icon-arrow-ios-upward {
  &:before {
    content: $icon-arrow-ios-upward; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up; 
  }
}
.icon-arrow-upward {
  &:before {
    content: $icon-arrow-upward; 
  }
}
.icon-arrowhead-down {
  &:before {
    content: $icon-arrowhead-down; 
  }
}
.icon-arrowhead-left {
  &:before {
    content: $icon-arrowhead-left; 
  }
}
.icon-arrowhead-right {
  &:before {
    content: $icon-arrowhead-right; 
  }
}
.icon-arrowhead-up {
  &:before {
    content: $icon-arrowhead-up; 
  }
}
.icon-at {
  &:before {
    content: $icon-at; 
  }
}
.icon-attach-2 {
  &:before {
    content: $icon-attach-2; 
  }
}
.icon-attach {
  &:before {
    content: $icon-attach; 
  }
}
.icon-award {
  &:before {
    content: $icon-award; 
  }
}
.icon-backspace {
  &:before {
    content: $icon-backspace; 
  }
}
.icon-bar-chart-2 {
  &:before {
    content: $icon-bar-chart-2; 
  }
}
.icon-bar-chart {
  &:before {
    content: $icon-bar-chart; 
  }
}
.icon-battery {
  &:before {
    content: $icon-battery; 
  }
}
.icon-behance {
  &:before {
    content: $icon-behance; 
  }
}
.icon-bell-off {
  &:before {
    content: $icon-bell-off; 
  }
}
.icon-bell {
  &:before {
    content: $icon-bell; 
  }
}
.icon-bluetooth {
  &:before {
    content: $icon-bluetooth; 
  }
}
.icon-book-open {
  &:before {
    content: $icon-book-open; 
  }
}
.icon-book {
  &:before {
    content: $icon-book; 
  }
}
.icon-bookmark {
  &:before {
    content: $icon-bookmark; 
  }
}
.icon-briefcase {
  &:before {
    content: $icon-briefcase; 
  }
}
.icon-browser {
  &:before {
    content: $icon-browser; 
  }
}
.icon-brush {
  &:before {
    content: $icon-brush; 
  }
}
.icon-bulb {
  &:before {
    content: $icon-bulb; 
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar; 
  }
}
.icon-camera {
  &:before {
    content: $icon-camera; 
  }
}
.icon-car {
  &:before {
    content: $icon-car; 
  }
}
.icon-cast {
  &:before {
    content: $icon-cast; 
  }
}
.icon-charging {
  &:before {
    content: $icon-charging; 
  }
}
.icon-checkmark-circle-2 {
  &:before {
    content: $icon-checkmark-circle-2; 
  }
}
.icon-checkmark-circle {
  &:before {
    content: $icon-checkmark-circle; 
  }
}
.icon-checkmark-square-2 {
  &:before {
    content: $icon-checkmark-square-2; 
  }
}
.icon-checkmark-square {
  &:before {
    content: $icon-checkmark-square; 
  }
}
.icon-checkmark {
  &:before {
    content: $icon-checkmark; 
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down; 
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left; 
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right; 
  }
}
.icon-chevron-up {
  &:before {
    content: $icon-chevron-up; 
  }
}
.icon-clipboard {
  &:before {
    content: $icon-clipboard; 
  }
}
.icon-clock {
  &:before {
    content: $icon-clock; 
  }
}
.icon-close-circle {
  &:before {
    content: $icon-close-circle; 
  }
}
.icon-close-square {
  &:before {
    content: $icon-close-square; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-cloud-download {
  &:before {
    content: $icon-cloud-download; 
  }
}
.icon-cloud-upload {
  &:before {
    content: $icon-cloud-upload; 
  }
}
.icon-code-download {
  &:before {
    content: $icon-code-download; 
  }
}
.icon-code {
  &:before {
    content: $icon-code; 
  }
}
.icon-collapse {
  &:before {
    content: $icon-collapse; 
  }
}
.icon-color-palette {
  &:before {
    content: $icon-color-palette; 
  }
}
.icon-color-picker {
  &:before {
    content: $icon-color-picker; 
  }
}
.icon-compass {
  &:before {
    content: $icon-compass; 
  }
}
.icon-copy {
  &:before {
    content: $icon-copy; 
  }
}
.icon-corner-down-left {
  &:before {
    content: $icon-corner-down-left; 
  }
}
.icon-corner-down-right {
  &:before {
    content: $icon-corner-down-right; 
  }
}
.icon-corner-left-down {
  &:before {
    content: $icon-corner-left-down; 
  }
}
.icon-corner-left-up {
  &:before {
    content: $icon-corner-left-up; 
  }
}
.icon-corner-right-down {
  &:before {
    content: $icon-corner-right-down; 
  }
}
.icon-corner-right-up {
  &:before {
    content: $icon-corner-right-up; 
  }
}
.icon-corner-up-left {
  &:before {
    content: $icon-corner-up-left; 
  }
}
.icon-corner-up-right {
  &:before {
    content: $icon-corner-up-right; 
  }
}
.icon-credit-card {
  &:before {
    content: $icon-credit-card; 
  }
}
.icon-crop {
  &:before {
    content: $icon-crop; 
  }
}
.icon-cube {
  &:before {
    content: $icon-cube; 
  }
}
.icon-diagonal-arrow-left-down {
  &:before {
    content: $icon-diagonal-arrow-left-down; 
  }
}
.icon-diagonal-arrow-left-up {
  &:before {
    content: $icon-diagonal-arrow-left-up; 
  }
}
.icon-diagonal-arrow-right-down {
  &:before {
    content: $icon-diagonal-arrow-right-down; 
  }
}
.icon-diagonal-arrow-right-up {
  &:before {
    content: $icon-diagonal-arrow-right-up; 
  }
}
.icon-done-all {
  &:before {
    content: $icon-done-all; 
  }
}
.icon-download {
  &:before {
    content: $icon-download; 
  }
}
.icon-droplet-off {
  &:before {
    content: $icon-droplet-off; 
  }
}
.icon-droplet {
  &:before {
    content: $icon-droplet; 
  }
}
.icon-edit-2 {
  &:before {
    content: $icon-edit-2; 
  }
}
.icon-edit {
  &:before {
    content: $icon-edit; 
  }
}
.icon-email {
  &:before {
    content: $icon-email; 
  }
}
.icon-expand {
  &:before {
    content: $icon-expand; 
  }
}
.icon-external-link {
  &:before {
    content: $icon-external-link; 
  }
}
.icon-eye-off-2 {
  &:before {
    content: $icon-eye-off-2; 
  }
}
.icon-eye-off {
  &:before {
    content: $icon-eye-off; 
  }
}
.icon-eye {
  &:before {
    content: $icon-eye; 
  }
}
.icon-facebook1 {
  &:before {
    content: $icon-facebook1; 
  }
}
.icon-file-add {
  &:before {
    content: $icon-file-add; 
  }
}
.icon-file-remove {
  &:before {
    content: $icon-file-remove; 
  }
}
.icon-file-text {
  &:before {
    content: $icon-file-text; 
  }
}
.icon-file {
  &:before {
    content: $icon-file; 
  }
}
.icon-film {
  &:before {
    content: $icon-film; 
  }
}
.icon-flag {
  &:before {
    content: $icon-flag; 
  }
}
.icon-flash-off {
  &:before {
    content: $icon-flash-off; 
  }
}
.icon-flash {
  &:before {
    content: $icon-flash; 
  }
}
.icon-flip-2 {
  &:before {
    content: $icon-flip-2; 
  }
}
.icon-flip {
  &:before {
    content: $icon-flip; 
  }
}
.icon-folder-add {
  &:before {
    content: $icon-folder-add; 
  }
}
.icon-folder-remove {
  &:before {
    content: $icon-folder-remove; 
  }
}
.icon-folder {
  &:before {
    content: $icon-folder; 
  }
}
.icon-funnel {
  &:before {
    content: $icon-funnel; 
  }
}
.icon-gift {
  &:before {
    content: $icon-gift; 
  }
}
.icon-github {
  &:before {
    content: $icon-github; 
  }
}
.icon-globe-2 {
  &:before {
    content: $icon-globe-2; 
  }
}
.icon-globe-3 {
  &:before {
    content: $icon-globe-3; 
  }
}
.icon-globe {
  &:before {
    content: $icon-globe; 
  }
}
.icon-google {
  &:before {
    content: $icon-google; 
  }
}
.icon-grid {
  &:before {
    content: $icon-grid; 
  }
}
.icon-hard-drive {
  &:before {
    content: $icon-hard-drive; 
  }
}
.icon-hash {
  &:before {
    content: $icon-hash; 
  }
}
.icon-headphones {
  &:before {
    content: $icon-headphones; 
  }
}
.icon-heart {
  &:before {
    content: $icon-heart; 
  }
}
.icon-home {
  &:before {
    content: $icon-home; 
  }
}
.icon-image-2 {
  &:before {
    content: $icon-image-2; 
  }
}
.icon-image {
  &:before {
    content: $icon-image; 
  }
}
.icon-inbox {
  &:before {
    content: $icon-inbox; 
  }
}
.icon-info {
  &:before {
    content: $icon-info; 
  }
}
.icon-keypad {
  &:before {
    content: $icon-keypad; 
  }
}
.icon-layers {
  &:before {
    content: $icon-layers; 
  }
}
.icon-layout {
  &:before {
    content: $icon-layout; 
  }
}
.icon-link-2 {
  &:before {
    content: $icon-link-2; 
  }
}
.icon-link {
  &:before {
    content: $icon-link; 
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin; 
  }
}
.icon-list {
  &:before {
    content: $icon-list; 
  }
}
.icon-lock {
  &:before {
    content: $icon-lock; 
  }
}
.icon-log-in {
  &:before {
    content: $icon-log-in; 
  }
}
.icon-log-out {
  &:before {
    content: $icon-log-out; 
  }
}
.icon-map {
  &:before {
    content: $icon-map; 
  }
}
.icon-maximize {
  &:before {
    content: $icon-maximize; 
  }
}
.icon-menu-2 {
  &:before {
    content: $icon-menu-2; 
  }
}
.icon-menu-arrow {
  &:before {
    content: $icon-menu-arrow; 
  }
}
.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-message-circle {
  &:before {
    content: $icon-message-circle; 
  }
}
.icon-message-square {
  &:before {
    content: $icon-message-square; 
  }
}
.icon-mic-off {
  &:before {
    content: $icon-mic-off; 
  }
}
.icon-mic {
  &:before {
    content: $icon-mic; 
  }
}
.icon-minimize {
  &:before {
    content: $icon-minimize; 
  }
}
.icon-minus-circle {
  &:before {
    content: $icon-minus-circle; 
  }
}
.icon-minus-square {
  &:before {
    content: $icon-minus-square; 
  }
}
.icon-minus {
  &:before {
    content: $icon-minus; 
  }
}
.icon-monitor {
  &:before {
    content: $icon-monitor; 
  }
}
.icon-moon {
  &:before {
    content: $icon-moon; 
  }
}
.icon-more-horizontal {
  &:before {
    content: $icon-more-horizontal; 
  }
}
.icon-more-vertical {
  &:before {
    content: $icon-more-vertical; 
  }
}
.icon-move {
  &:before {
    content: $icon-move; 
  }
}
.icon-music {
  &:before {
    content: $icon-music; 
  }
}
.icon-navigation-2 {
  &:before {
    content: $icon-navigation-2; 
  }
}
.icon-navigation {
  &:before {
    content: $icon-navigation; 
  }
}
.icon-npm {
  &:before {
    content: $icon-npm; 
  }
}
.icon-options-2 {
  &:before {
    content: $icon-options-2; 
  }
}
.icon-options {
  &:before {
    content: $icon-options; 
  }
}
.icon-pantone {
  &:before {
    content: $icon-pantone; 
  }
}
.icon-paper-plane {
  &:before {
    content: $icon-paper-plane; 
  }
}
.icon-pause-circle {
  &:before {
    content: $icon-pause-circle; 
  }
}
.icon-people {
  &:before {
    content: $icon-people; 
  }
}
.icon-percent {
  &:before {
    content: $icon-percent; 
  }
}
.icon-person-add {
  &:before {
    content: $icon-person-add; 
  }
}
.icon-person-delete {
  &:before {
    content: $icon-person-delete; 
  }
}
.icon-person-done {
  &:before {
    content: $icon-person-done; 
  }
}
.icon-person-remove {
  &:before {
    content: $icon-person-remove; 
  }
}
.icon-person {
  &:before {
    content: $icon-person; 
  }
}
.icon-phone-call {
  &:before {
    content: $icon-phone-call; 
  }
}
.icon-phone-missed {
  &:before {
    content: $icon-phone-missed; 
  }
}
.icon-phone-off {
  &:before {
    content: $icon-phone-off; 
  }
}
.icon-phone {
  &:before {
    content: $icon-phone; 
  }
}
.icon-pie-chart-2 {
  &:before {
    content: $icon-pie-chart-2; 
  }
}
.icon-pie-chart {
  &:before {
    content: $icon-pie-chart; 
  }
}
.icon-pin {
  &:before {
    content: $icon-pin; 
  }
}
.icon-play-circle {
  &:before {
    content: $icon-play-circle; 
  }
}
.icon-plus-circle {
  &:before {
    content: $icon-plus-circle; 
  }
}
.icon-plus-square {
  &:before {
    content: $icon-plus-square; 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}
.icon-power {
  &:before {
    content: $icon-power; 
  }
}
.icon-pricetags {
  &:before {
    content: $icon-pricetags; 
  }
}
.icon-printer {
  &:before {
    content: $icon-printer; 
  }
}
.icon-question-mark-circle {
  &:before {
    content: $icon-question-mark-circle; 
  }
}
.icon-question-mark {
  &:before {
    content: $icon-question-mark; 
  }
}
.icon-radio-button-off {
  &:before {
    content: $icon-radio-button-off; 
  }
}
.icon-radio-button-on {
  &:before {
    content: $icon-radio-button-on; 
  }
}
.icon-radio {
  &:before {
    content: $icon-radio; 
  }
}
.icon-recording {
  &:before {
    content: $icon-recording; 
  }
}
.icon-refresh {
  &:before {
    content: $icon-refresh; 
  }
}
.icon-repeat {
  &:before {
    content: $icon-repeat; 
  }
}
.icon-rewind-left {
  &:before {
    content: $icon-rewind-left; 
  }
}
.icon-rewind-right {
  &:before {
    content: $icon-rewind-right; 
  }
}
.icon-save {
  &:before {
    content: $icon-save; 
  }
}
.icon-scissors {
  &:before {
    content: $icon-scissors; 
  }
}
.icon-search1 {
  &:before {
    content: $icon-search1; 
  }
}
.icon-settings-2 {
  &:before {
    content: $icon-settings-2; 
  }
}
.icon-settings {
  &:before {
    content: $icon-settings; 
  }
}
.icon-shake {
  &:before {
    content: $icon-shake; 
  }
}
.icon-share {
  &:before {
    content: $icon-share; 
  }
}
.icon-shield-off {
  &:before {
    content: $icon-shield-off; 
  }
}
.icon-shield {
  &:before {
    content: $icon-shield; 
  }
}
.icon-shopping-bag {
  &:before {
    content: $icon-shopping-bag; 
  }
}
.icon-shopping-cart {
  &:before {
    content: $icon-shopping-cart; 
  }
}
.icon-shuffle-2 {
  &:before {
    content: $icon-shuffle-2; 
  }
}
.icon-shuffle {
  &:before {
    content: $icon-shuffle; 
  }
}
.icon-skip-back {
  &:before {
    content: $icon-skip-back; 
  }
}
.icon-skip-forward {
  &:before {
    content: $icon-skip-forward; 
  }
}
.icon-slash {
  &:before {
    content: $icon-slash; 
  }
}
.icon-smartphone {
  &:before {
    content: $icon-smartphone; 
  }
}
.icon-speaker {
  &:before {
    content: $icon-speaker; 
  }
}
.icon-square {
  &:before {
    content: $icon-square; 
  }
}
.icon-star {
  &:before {
    content: $icon-star; 
  }
}
.icon-stop-circle {
  &:before {
    content: $icon-stop-circle; 
  }
}
.icon-sun {
  &:before {
    content: $icon-sun; 
  }
}
.icon-swap {
  &:before {
    content: $icon-swap; 
  }
}
.icon-sync {
  &:before {
    content: $icon-sync; 
  }
}
.icon-text {
  &:before {
    content: $icon-text; 
  }
}
.icon-thermometer-minus {
  &:before {
    content: $icon-thermometer-minus; 
  }
}
.icon-thermometer-plus {
  &:before {
    content: $icon-thermometer-plus; 
  }
}
.icon-thermometer {
  &:before {
    content: $icon-thermometer; 
  }
}
.icon-toggle-left {
  &:before {
    content: $icon-toggle-left; 
  }
}
.icon-toggle-right {
  &:before {
    content: $icon-toggle-right; 
  }
}
.icon-trash-2 {
  &:before {
    content: $icon-trash-2; 
  }
}
.icon-trash {
  &:before {
    content: $icon-trash; 
  }
}
.icon-trending-down {
  &:before {
    content: $icon-trending-down; 
  }
}
.icon-trending-up {
  &:before {
    content: $icon-trending-up; 
  }
}
.icon-tv {
  &:before {
    content: $icon-tv; 
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter; 
  }
}
.icon-umbrella {
  &:before {
    content: $icon-umbrella; 
  }
}
.icon-undo {
  &:before {
    content: $icon-undo; 
  }
}
.icon-unlock {
  &:before {
    content: $icon-unlock; 
  }
}
.icon-upload {
  &:before {
    content: $icon-upload; 
  }
}
.icon-video-off {
  &:before {
    content: $icon-video-off; 
  }
}
.icon-video {
  &:before {
    content: $icon-video; 
  }
}
.icon-volume-down {
  &:before {
    content: $icon-volume-down; 
  }
}
.icon-volume-mute {
  &:before {
    content: $icon-volume-mute; 
  }
}
.icon-volume-off {
  &:before {
    content: $icon-volume-off; 
  }
}
.icon-volume-up {
  &:before {
    content: $icon-volume-up; 
  }
}
.icon-wifi-off {
  &:before {
    content: $icon-wifi-off; 
  }
}
.icon-wifi {
  &:before {
    content: $icon-wifi; 
  }
}
.icon-activity-outline {
  &:before {
    content: $icon-activity-outline; 
  }
}
.icon-alert-circle-outline {
  &:before {
    content: $icon-alert-circle-outline; 
  }
}
.icon-alert-triangle-outline {
  &:before {
    content: $icon-alert-triangle-outline; 
  }
}
.icon-archive-outline {
  &:before {
    content: $icon-archive-outline; 
  }
}
.icon-arrow-back-outline {
  &:before {
    content: $icon-arrow-back-outline; 
  }
}
.icon-arrow-circle-down-outline {
  &:before {
    content: $icon-arrow-circle-down-outline; 
  }
}
.icon-arrow-circle-left-outline {
  &:before {
    content: $icon-arrow-circle-left-outline; 
  }
}
.icon-arrow-circle-right-outline {
  &:before {
    content: $icon-arrow-circle-right-outline; 
  }
}
.icon-arrow-circle-up-outline {
  &:before {
    content: $icon-arrow-circle-up-outline; 
  }
}
.icon-arrow-down-outline {
  &:before {
    content: $icon-arrow-down-outline; 
  }
}
.icon-arrow-downward-outline {
  &:before {
    content: $icon-arrow-downward-outline; 
  }
}
.icon-arrow-forward-outline {
  &:before {
    content: $icon-arrow-forward-outline; 
  }
}
.icon-arrow-ios-back-outline {
  &:before {
    content: $icon-arrow-ios-back-outline; 
  }
}
.icon-arrow-ios-downward-outline {
  &:before {
    content: $icon-arrow-ios-downward-outline; 
  }
}
.icon-arrow-ios-forward-outline {
  &:before {
    content: $icon-arrow-ios-forward-outline; 
  }
}
.icon-arrow-ios-upward-outline {
  &:before {
    content: $icon-arrow-ios-upward-outline; 
  }
}
.icon-arrow-left-outline {
  &:before {
    content: $icon-arrow-left-outline; 
  }
}
.icon-arrow-right-outline {
  &:before {
    content: $icon-arrow-right-outline; 
  }
}
.icon-arrow-up-outline {
  &:before {
    content: $icon-arrow-up-outline; 
  }
}
.icon-arrow-upward-outline {
  &:before {
    content: $icon-arrow-upward-outline; 
  }
}
.icon-arrowhead-down-outline {
  &:before {
    content: $icon-arrowhead-down-outline; 
  }
}
.icon-arrowhead-left-outline {
  &:before {
    content: $icon-arrowhead-left-outline; 
  }
}
.icon-arrowhead-right-outline {
  &:before {
    content: $icon-arrowhead-right-outline; 
  }
}
.icon-arrowhead-up-outline {
  &:before {
    content: $icon-arrowhead-up-outline; 
  }
}
.icon-at-outline {
  &:before {
    content: $icon-at-outline; 
  }
}
.icon-attach-2-outline {
  &:before {
    content: $icon-attach-2-outline; 
  }
}
.icon-attach-outline {
  &:before {
    content: $icon-attach-outline; 
  }
}
.icon-award-outline {
  &:before {
    content: $icon-award-outline; 
  }
}
.icon-backspace-outline {
  &:before {
    content: $icon-backspace-outline; 
  }
}
.icon-bar-chart-2-outline {
  &:before {
    content: $icon-bar-chart-2-outline; 
  }
}
.icon-bar-chart-outline {
  &:before {
    content: $icon-bar-chart-outline; 
  }
}
.icon-battery-outline {
  &:before {
    content: $icon-battery-outline; 
  }
}
.icon-behance-outline {
  &:before {
    content: $icon-behance-outline; 
  }
}
.icon-bell-off-outline {
  &:before {
    content: $icon-bell-off-outline; 
  }
}
.icon-bell-outline {
  &:before {
    content: $icon-bell-outline; 
  }
}
.icon-bluetooth-outline {
  &:before {
    content: $icon-bluetooth-outline; 
  }
}
.icon-book-open-outline {
  &:before {
    content: $icon-book-open-outline; 
  }
}
.icon-book-outline {
  &:before {
    content: $icon-book-outline; 
  }
}
.icon-bookmark-outline {
  &:before {
    content: $icon-bookmark-outline; 
  }
}
.icon-briefcase-outline {
  &:before {
    content: $icon-briefcase-outline; 
  }
}
.icon-browser-outline {
  &:before {
    content: $icon-browser-outline; 
  }
}
.icon-brush-outline {
  &:before {
    content: $icon-brush-outline; 
  }
}
.icon-bulb-outline {
  &:before {
    content: $icon-bulb-outline; 
  }
}
.icon-calendar-outline {
  &:before {
    content: $icon-calendar-outline; 
  }
}
.icon-camera-outline {
  &:before {
    content: $icon-camera-outline; 
  }
}
.icon-car-outline {
  &:before {
    content: $icon-car-outline; 
  }
}
.icon-cast-outline {
  &:before {
    content: $icon-cast-outline; 
  }
}
.icon-charging-outline {
  &:before {
    content: $icon-charging-outline; 
  }
}
.icon-checkmark-circle-2-outline {
  &:before {
    content: $icon-checkmark-circle-2-outline; 
  }
}
.icon-checkmark-circle-outline {
  &:before {
    content: $icon-checkmark-circle-outline; 
  }
}
.icon-checkmark-outline {
  &:before {
    content: $icon-checkmark-outline; 
  }
}
.icon-checkmark-square-2-outline {
  &:before {
    content: $icon-checkmark-square-2-outline; 
  }
}
.icon-checkmark-square-outline {
  &:before {
    content: $icon-checkmark-square-outline; 
  }
}
.icon-chevron-down-outline {
  &:before {
    content: $icon-chevron-down-outline; 
  }
}
.icon-chevron-left-outline {
  &:before {
    content: $icon-chevron-left-outline; 
  }
}
.icon-chevron-right-outline {
  &:before {
    content: $icon-chevron-right-outline; 
  }
}
.icon-chevron-up-outline {
  &:before {
    content: $icon-chevron-up-outline; 
  }
}
.icon-clipboard-outline {
  &:before {
    content: $icon-clipboard-outline; 
  }
}
.icon-clock-outline {
  &:before {
    content: $icon-clock-outline; 
  }
}
.icon-close-circle-outline {
  &:before {
    content: $icon-close-circle-outline; 
  }
}
.icon-close-outline {
  &:before {
    content: $icon-close-outline; 
  }
}
.icon-close-square-outline {
  &:before {
    content: $icon-close-square-outline; 
  }
}
.icon-cloud-download-outline {
  &:before {
    content: $icon-cloud-download-outline; 
  }
}
.icon-cloud-upload-outline {
  &:before {
    content: $icon-cloud-upload-outline; 
  }
}
.icon-code-download-outline {
  &:before {
    content: $icon-code-download-outline; 
  }
}
.icon-code-outline {
  &:before {
    content: $icon-code-outline; 
  }
}
.icon-collapse-outline {
  &:before {
    content: $icon-collapse-outline; 
  }
}
.icon-color-palette-outline {
  &:before {
    content: $icon-color-palette-outline; 
  }
}
.icon-color-picker-outline {
  &:before {
    content: $icon-color-picker-outline; 
  }
}
.icon-compass-outline {
  &:before {
    content: $icon-compass-outline; 
  }
}
.icon-copy-outline {
  &:before {
    content: $icon-copy-outline; 
  }
}
.icon-corner-down-left-outline {
  &:before {
    content: $icon-corner-down-left-outline; 
  }
}
.icon-corner-down-right-outline {
  &:before {
    content: $icon-corner-down-right-outline; 
  }
}
.icon-corner-left-down-outline {
  &:before {
    content: $icon-corner-left-down-outline; 
  }
}
.icon-corner-left-up-outline {
  &:before {
    content: $icon-corner-left-up-outline; 
  }
}
.icon-corner-right-down-outline {
  &:before {
    content: $icon-corner-right-down-outline; 
  }
}
.icon-corner-right-up-outline {
  &:before {
    content: $icon-corner-right-up-outline; 
  }
}
.icon-corner-up-left-outline {
  &:before {
    content: $icon-corner-up-left-outline; 
  }
}
.icon-corner-up-right-outline {
  &:before {
    content: $icon-corner-up-right-outline; 
  }
}
.icon-credit-card-outline {
  &:before {
    content: $icon-credit-card-outline; 
  }
}
.icon-crop-outline {
  &:before {
    content: $icon-crop-outline; 
  }
}
.icon-cube-outline {
  &:before {
    content: $icon-cube-outline; 
  }
}
.icon-diagonal-arrow-left-down-outline {
  &:before {
    content: $icon-diagonal-arrow-left-down-outline; 
  }
}
.icon-diagonal-arrow-left-up-outline {
  &:before {
    content: $icon-diagonal-arrow-left-up-outline; 
  }
}
.icon-diagonal-arrow-right-down-outline {
  &:before {
    content: $icon-diagonal-arrow-right-down-outline; 
  }
}
.icon-diagonal-arrow-right-up-outline {
  &:before {
    content: $icon-diagonal-arrow-right-up-outline; 
  }
}
.icon-done-all-outline {
  &:before {
    content: $icon-done-all-outline; 
  }
}
.icon-download-outline {
  &:before {
    content: $icon-download-outline; 
  }
}
.icon-droplet-off-outline {
  &:before {
    content: $icon-droplet-off-outline; 
  }
}
.icon-droplet-outline {
  &:before {
    content: $icon-droplet-outline; 
  }
}
.icon-edit-2-outline {
  &:before {
    content: $icon-edit-2-outline; 
  }
}
.icon-edit-outline {
  &:before {
    content: $icon-edit-outline; 
  }
}
.icon-email-outline {
  &:before {
    content: $icon-email-outline; 
  }
}
.icon-expand-outline {
  &:before {
    content: $icon-expand-outline; 
  }
}
.icon-external-link-outline {
  &:before {
    content: $icon-external-link-outline; 
  }
}
.icon-eye-off-2-outline {
  &:before {
    content: $icon-eye-off-2-outline; 
  }
}
.icon-eye-off-outline {
  &:before {
    content: $icon-eye-off-outline; 
  }
}
.icon-eye-outline {
  &:before {
    content: $icon-eye-outline; 
  }
}
.icon-facebook-outline {
  &:before {
    content: $icon-facebook-outline; 
  }
}
.icon-file-add-outline {
  &:before {
    content: $icon-file-add-outline; 
  }
}
.icon-file-outline {
  &:before {
    content: $icon-file-outline; 
  }
}
.icon-file-remove-outline {
  &:before {
    content: $icon-file-remove-outline; 
  }
}
.icon-file-text-outline {
  &:before {
    content: $icon-file-text-outline; 
  }
}
.icon-film-outline {
  &:before {
    content: $icon-film-outline; 
  }
}
.icon-flag-outline {
  &:before {
    content: $icon-flag-outline; 
  }
}
.icon-flash-off-outline {
  &:before {
    content: $icon-flash-off-outline; 
  }
}
.icon-flash-outline {
  &:before {
    content: $icon-flash-outline; 
  }
}
.icon-flip-2-outline {
  &:before {
    content: $icon-flip-2-outline; 
  }
}
.icon-flip-outline {
  &:before {
    content: $icon-flip-outline; 
  }
}
.icon-folder-add-outline {
  &:before {
    content: $icon-folder-add-outline; 
  }
}
.icon-folder-outline {
  &:before {
    content: $icon-folder-outline; 
  }
}
.icon-folder-remove-outline {
  &:before {
    content: $icon-folder-remove-outline; 
  }
}
.icon-funnel-outline {
  &:before {
    content: $icon-funnel-outline; 
  }
}
.icon-gift-outline {
  &:before {
    content: $icon-gift-outline; 
  }
}
.icon-github-outline {
  &:before {
    content: $icon-github-outline; 
  }
}
.icon-globe-2-outline {
  &:before {
    content: $icon-globe-2-outline; 
  }
}
.icon-globe-outline {
  &:before {
    content: $icon-globe-outline; 
  }
}
.icon-google-outline {
  &:before {
    content: $icon-google-outline; 
  }
}
.icon-grid-outline {
  &:before {
    content: $icon-grid-outline; 
  }
}
.icon-hard-drive-outline {
  &:before {
    content: $icon-hard-drive-outline; 
  }
}
.icon-hash-outline {
  &:before {
    content: $icon-hash-outline; 
  }
}
.icon-headphones-outline {
  &:before {
    content: $icon-headphones-outline; 
  }
}
.icon-heart-outline {
  &:before {
    content: $icon-heart-outline; 
  }
}
.icon-home-outline {
  &:before {
    content: $icon-home-outline; 
  }
}
.icon-image-outline {
  &:before {
    content: $icon-image-outline; 
  }
}
.icon-inbox-outline {
  &:before {
    content: $icon-inbox-outline; 
  }
}
.icon-info-outline {
  &:before {
    content: $icon-info-outline; 
  }
}
.icon-keypad-outline {
  &:before {
    content: $icon-keypad-outline; 
  }
}
.icon-layers-outline {
  &:before {
    content: $icon-layers-outline; 
  }
}
.icon-layout-outline {
  &:before {
    content: $icon-layout-outline; 
  }
}
.icon-link-2-outline {
  &:before {
    content: $icon-link-2-outline; 
  }
}
.icon-link-outline {
  &:before {
    content: $icon-link-outline; 
  }
}
.icon-linkedin-outline {
  &:before {
    content: $icon-linkedin-outline; 
  }
}
.icon-list-outline {
  &:before {
    content: $icon-list-outline; 
  }
}
.icon-loader-outline {
  &:before {
    content: $icon-loader-outline; 
  }
}
.icon-lock-outline {
  &:before {
    content: $icon-lock-outline; 
  }
}
.icon-log-in-outline {
  &:before {
    content: $icon-log-in-outline; 
  }
}
.icon-log-out-outline {
  &:before {
    content: $icon-log-out-outline; 
  }
}
.icon-map-outline {
  &:before {
    content: $icon-map-outline; 
  }
}
.icon-maximize-outline {
  &:before {
    content: $icon-maximize-outline; 
  }
}
.icon-menu-2-outline {
  &:before {
    content: $icon-menu-2-outline; 
  }
}
.icon-menu-arrow-outline {
  &:before {
    content: $icon-menu-arrow-outline; 
  }
}
.icon-menu-outline {
  &:before {
    content: $icon-menu-outline; 
  }
}
.icon-message-circle-outline {
  &:before {
    content: $icon-message-circle-outline; 
  }
}
.icon-message-square-outline {
  &:before {
    content: $icon-message-square-outline; 
  }
}
.icon-mic-off-outline {
  &:before {
    content: $icon-mic-off-outline; 
  }
}
.icon-mic-outline {
  &:before {
    content: $icon-mic-outline; 
  }
}
.icon-minimize-outline {
  &:before {
    content: $icon-minimize-outline; 
  }
}
.icon-minus-circle-outline {
  &:before {
    content: $icon-minus-circle-outline; 
  }
}
.icon-minus-outline {
  &:before {
    content: $icon-minus-outline; 
  }
}
.icon-minus-square-outline {
  &:before {
    content: $icon-minus-square-outline; 
  }
}
.icon-monitor-outline {
  &:before {
    content: $icon-monitor-outline; 
  }
}
.icon-moon-outline {
  &:before {
    content: $icon-moon-outline; 
  }
}
.icon-more-horizontal-outline {
  &:before {
    content: $icon-more-horizontal-outline; 
  }
}
.icon-more-vertical-outline {
  &:before {
    content: $icon-more-vertical-outline; 
  }
}
.icon-move-outline {
  &:before {
    content: $icon-move-outline; 
  }
}
.icon-music-outline {
  &:before {
    content: $icon-music-outline; 
  }
}
.icon-navigation-2-outline {
  &:before {
    content: $icon-navigation-2-outline; 
  }
}
.icon-navigation-outline {
  &:before {
    content: $icon-navigation-outline; 
  }
}
.icon-npm-outline {
  &:before {
    content: $icon-npm-outline; 
  }
}
.icon-options-2-outline {
  &:before {
    content: $icon-options-2-outline; 
  }
}
.icon-options-outline {
  &:before {
    content: $icon-options-outline; 
  }
}
.icon-pantone-outline {
  &:before {
    content: $icon-pantone-outline; 
  }
}
.icon-paper-plane-outline {
  &:before {
    content: $icon-paper-plane-outline; 
  }
}
.icon-pause-circle-outline {
  &:before {
    content: $icon-pause-circle-outline; 
  }
}
.icon-people-outline {
  &:before {
    content: $icon-people-outline; 
  }
}
.icon-percent-outline {
  &:before {
    content: $icon-percent-outline; 
  }
}
.icon-person-add-outline {
  &:before {
    content: $icon-person-add-outline; 
  }
}
.icon-person-delete-outline {
  &:before {
    content: $icon-person-delete-outline; 
  }
}
.icon-person-done-outline {
  &:before {
    content: $icon-person-done-outline; 
  }
}
.icon-person-outline {
  &:before {
    content: $icon-person-outline; 
  }
}
.icon-person-remove-outline {
  &:before {
    content: $icon-person-remove-outline; 
  }
}
.icon-phone-call-outline {
  &:before {
    content: $icon-phone-call-outline; 
  }
}
.icon-phone-missed-outline {
  &:before {
    content: $icon-phone-missed-outline; 
  }
}
.icon-phone-off-outline {
  &:before {
    content: $icon-phone-off-outline; 
  }
}
.icon-phone-outline {
  &:before {
    content: $icon-phone-outline; 
  }
}
.icon-pie-chart-outline {
  &:before {
    content: $icon-pie-chart-outline; 
  }
}
.icon-pin-outline {
  &:before {
    content: $icon-pin-outline; 
  }
}
.icon-play-circle-outline {
  &:before {
    content: $icon-play-circle-outline; 
  }
}
.icon-plus-circle-outline {
  &:before {
    content: $icon-plus-circle-outline; 
  }
}
.icon-plus-outline {
  &:before {
    content: $icon-plus-outline; 
  }
}
.icon-plus-square-outline {
  &:before {
    content: $icon-plus-square-outline; 
  }
}
.icon-power-outline {
  &:before {
    content: $icon-power-outline; 
  }
}
.icon-pricetags-outline {
  &:before {
    content: $icon-pricetags-outline; 
  }
}
.icon-printer-outline {
  &:before {
    content: $icon-printer-outline; 
  }
}
.icon-question-mark-circle-outline {
  &:before {
    content: $icon-question-mark-circle-outline; 
  }
}
.icon-question-mark-outline {
  &:before {
    content: $icon-question-mark-outline; 
  }
}
.icon-radio-button-off-outline {
  &:before {
    content: $icon-radio-button-off-outline; 
  }
}
.icon-radio-button-on-outline {
  &:before {
    content: $icon-radio-button-on-outline; 
  }
}
.icon-radio-outline {
  &:before {
    content: $icon-radio-outline; 
  }
}
.icon-recording-outline {
  &:before {
    content: $icon-recording-outline; 
  }
}
.icon-refresh-outline {
  &:before {
    content: $icon-refresh-outline; 
  }
}
.icon-repeat-outline {
  &:before {
    content: $icon-repeat-outline; 
  }
}
.icon-rewind-left-outline {
  &:before {
    content: $icon-rewind-left-outline; 
  }
}
.icon-rewind-right-outline {
  &:before {
    content: $icon-rewind-right-outline; 
  }
}
.icon-save-outline {
  &:before {
    content: $icon-save-outline; 
  }
}
.icon-scissors-outline {
  &:before {
    content: $icon-scissors-outline; 
  }
}
.icon-search-outline {
  &:before {
    content: $icon-search-outline; 
  }
}
.icon-settings-2-outline {
  &:before {
    content: $icon-settings-2-outline; 
  }
}
.icon-settings-outline {
  &:before {
    content: $icon-settings-outline; 
  }
}
.icon-shake-outline {
  &:before {
    content: $icon-shake-outline; 
  }
}
.icon-share-outline {
  &:before {
    content: $icon-share-outline; 
  }
}
.icon-shield-off-outline {
  &:before {
    content: $icon-shield-off-outline; 
  }
}
.icon-shield-outline {
  &:before {
    content: $icon-shield-outline; 
  }
}
.icon-shopping-bag-outline {
  &:before {
    content: $icon-shopping-bag-outline; 
  }
}
.icon-shopping-cart-outline {
  &:before {
    content: $icon-shopping-cart-outline; 
  }
}
.icon-shuffle-2-outline {
  &:before {
    content: $icon-shuffle-2-outline; 
  }
}
.icon-shuffle-outline {
  &:before {
    content: $icon-shuffle-outline; 
  }
}
.icon-skip-back-outline {
  &:before {
    content: $icon-skip-back-outline; 
  }
}
.icon-skip-forward-outline {
  &:before {
    content: $icon-skip-forward-outline; 
  }
}
.icon-slash-outline {
  &:before {
    content: $icon-slash-outline; 
  }
}
.icon-smartphone-outline {
  &:before {
    content: $icon-smartphone-outline; 
  }
}
.icon-speaker-outline {
  &:before {
    content: $icon-speaker-outline; 
  }
}
.icon-square-outline {
  &:before {
    content: $icon-square-outline; 
  }
}
.icon-star-outline {
  &:before {
    content: $icon-star-outline; 
  }
}
.icon-stop-circle-outline {
  &:before {
    content: $icon-stop-circle-outline; 
  }
}
.icon-sun-outline {
  &:before {
    content: $icon-sun-outline; 
  }
}
.icon-swap-outline {
  &:before {
    content: $icon-swap-outline; 
  }
}
.icon-sync-outline {
  &:before {
    content: $icon-sync-outline; 
  }
}
.icon-text-outline {
  &:before {
    content: $icon-text-outline; 
  }
}
.icon-thermometer-minus-outline {
  &:before {
    content: $icon-thermometer-minus-outline; 
  }
}
.icon-thermometer-outline {
  &:before {
    content: $icon-thermometer-outline; 
  }
}
.icon-thermometer-plus-outline {
  &:before {
    content: $icon-thermometer-plus-outline; 
  }
}
.icon-toggle-left-outline {
  &:before {
    content: $icon-toggle-left-outline; 
  }
}
.icon-toggle-right-outline {
  &:before {
    content: $icon-toggle-right-outline; 
  }
}
.icon-trash-2-outline {
  &:before {
    content: $icon-trash-2-outline; 
  }
}
.icon-trash-outline {
  &:before {
    content: $icon-trash-outline; 
  }
}
.icon-trending-down-outline {
  &:before {
    content: $icon-trending-down-outline; 
  }
}
.icon-trending-up-outline {
  &:before {
    content: $icon-trending-up-outline; 
  }
}
.icon-tv-outline {
  &:before {
    content: $icon-tv-outline; 
  }
}
.icon-twitter-outline {
  &:before {
    content: $icon-twitter-outline; 
  }
}
.icon-umbrella-outline {
  &:before {
    content: $icon-umbrella-outline; 
  }
}
.icon-undo-outline {
  &:before {
    content: $icon-undo-outline; 
  }
}
.icon-unlock-outline {
  &:before {
    content: $icon-unlock-outline; 
  }
}
.icon-upload-outline {
  &:before {
    content: $icon-upload-outline; 
  }
}
.icon-video-off-outline {
  &:before {
    content: $icon-video-off-outline; 
  }
}
.icon-video-outline {
  &:before {
    content: $icon-video-outline; 
  }
}
.icon-volume-down-outline {
  &:before {
    content: $icon-volume-down-outline; 
  }
}
.icon-volume-mute-outline {
  &:before {
    content: $icon-volume-mute-outline; 
  }
}
.icon-volume-off-outline {
  &:before {
    content: $icon-volume-off-outline; 
  }
}
.icon-volume-up-outline {
  &:before {
    content: $icon-volume-up-outline; 
  }
}
.icon-wifi-off-outline {
  &:before {
    content: $icon-wifi-off-outline; 
  }
}
.icon-wifi-outline {
  &:before {
    content: $icon-wifi-outline; 
  }
}

