@import "../../../../libs/styles/main.scss";

.button {
  padding: $general-spacing-unit;
  border: 2px solid transparent;
  border-radius: 10px;
  background: $color-white;
  cursor: pointer;
  position: relative;
  transition: border ease 0.25s;
  // overflow: hidden;
  // width: 258px + $general-spacing-unit * 2;

  // @include breakpoint("mobile") {
  //   width: 200px;
  // }

  &+& {
    margin-left: $general-spacing-unit * 2;
  }

  &.checked {
    border-color: $color-eastern-blue;
  }

  &.unchecked {
    border-color: $color-catskill-white;
  }

  &.unchecked:hover {
    border-color: $color-edward;
  }
}

.image {
  width: 100%;
  height: 150px;
  display: block;
  pointer-events: none;
}

.checkmark {
  color: $color-white;
  background: $color-eastern-blue;
  font-family: $font-source-sans;
  font-weight: $font-weight-bold;
  font-size: 18px;
  position: absolute;
  left: -1px;
  bottom: -1px;
  padding: $general-spacing-unit * 0.75 $general-spacing-unit;
  border-bottom-left-radius: 10px;
}

.secondaryLayout {
  &.checked {
    border-color: $color-primary-enterprise;
  }

  .checkmark {
    background-color: $color-primary-enterprise;
  }
}