@import "../../libs/styles/main.scss";

.wrapper {
  width: 100%;
}

.title {
  font-size: 32px;
  margin: 0 0 $general-spacing-unit * 3;

  @include breakpoint("mobile") {
    text-align: center;
  }

  b {
    color: $color-eastern-blue;

    @include breakpoint("mobile") {
      display: block;
    }
  }
}

.form {
  width: 100%;
  display: inline-grid;
  grid-template-columns: 40% 1fr 250px;
  column-gap: $general-spacing-unit * 2;

  @include breakpoint("mobile-and-tablet") {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: $general-spacing-unit * 3;

    > button {
      margin-top: 0;
    }
  }
}

.buttonWrapper {
  width: 100%;
  overflow: hidden;
}
