@import "../../libs/styles/main.scss";

$header-height: 80px;

.spacer {
  height: $header-height;
  width: 1px;
  display: block;
}

.wrapper {
  background: #fbfcfd;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: $color-catskill;
  z-index: 3;
  box-shadow: 0 2px 20px 0 rgba(27, 155, 158, 0);
  transition: box-shadow ease 0.25s;

  &.floating {
    box-shadow: 0 2px 20px 0 rgba(27, 155, 158, 0.1);
  }
}

.container {
  height: $header-height;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  box-sizing: border-box;
  width: 1092px;

  @include breakpoint("mobile") {
    padding: 0 24px;
    width: auto;
  }

  @include breakpoint(tablet) {
    width: 100%;
    padding: 0 $general-spacing-unit * 3;
  }
}

.socialMediaWrapper {
  display: flex;
  flex-direction: row;

  svg {
    width: 24px;
    height: 24px;
  }
}