@import "../../libs/styles/main.scss";

.wrapper {
  background: #1b9b9e;
  padding: 64px 0;
  margin: 40px 0 0;

  @include breakpoint("tablet") {
    padding: $general-spacing-unit * 6 0;
  }

  @include breakpoint("mobile") {
    padding: $general-spacing-unit * 3 0;
  }
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 1092px;
  max-width: 100vw;
  margin: 0 auto;
  box-sizing: border-box;

  @include breakpoint("tablet") {
    padding: 0 $general-spacing-unit * 5;
  }

  @include breakpoint("mobile") {
    padding: 0 $general-spacing-unit * 3;
  }
}

.firstColumn {
  @include breakpoint("tablet") {
    width: auto;
    margin-right: $general-spacing-unit * 7;
  }

  @include breakpoint("mobile") {
    width: 100%;
    margin-bottom: 16px;
  }
}

.secondColumn {
  flex: 1;
  padding: 0 120px;

  @include breakpoint("mobile-and-tablet") {
    padding: 0;
  }
}

.logo {
  @include breakpoint("mobile-and-tablet") {
    width: 116px;
  }
}

.credits {
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  margin: 12px 0;

  @include breakpoint("mobile-and-tablet") {
    font-size: 13px;
    line-height: 16px;
  }
}

.sociaMediaWrapper {
  display: flex;
  flex-direction: row;

  a {
    margin-left: 8px;
  }
}
