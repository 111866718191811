@import "../../libs/styles/main.scss";

.wrapper {
  background: #1b9b9e;
  padding: 64px 0;
  margin: 40px 0 0;

  @include breakpoint(tablet) {
    padding: $general-spacing-unit * 6 0;
  }

  @include breakpoint("mobile") {
    padding: $general-spacing-unit * 3 0;
  }
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 1092px;
  max-width: 100vw;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;

  @include breakpoint(tablet) {
    padding: 0 $general-spacing-unit * 5;
  }

  @include breakpoint("mobile") {
    padding: $general-spacing-unit * 6;
  }
}

.firstColumn {
  @include breakpoint(tablet) {
    width: auto;
    margin-right: $general-spacing-unit * 7;
  }

  @include breakpoint(mobile) {
    width: 100%;
    margin-bottom: 16px;
  }
}

.secondColumn {
  flex: 1;
  padding: 0 120px;

  @include breakpoint("mobile-and-tablet") {
    width: 100%;
    padding: 0;
  }
}

.logo {}

.credits {
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  margin: 12px 0;
}

.menu {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  li {
    display: block;
    width: 50%;
    margin: $general-spacing-unit 0;
  }

  a {
    font-weight: 600;
    font-size: 18px;
    color: #fff;
    text-decoration: none;
  }
}

.links {
  font-weight: 600;
  font-size: 13px;
  text-align: right;
  color: #fff;
  margin: 12px 0 0;

  @include breakpoint("mobile") {
    margin-top: $general-spacing-unit * 4;
    width: calc(100vw - #{$general-spacing-unit * 12});
    text-align: left;

    br {
      display: none;
    }
  }

  a {
    color: #fff;
    text-decoration: none;
  }
}

.sociaMediaWrapper {
  display: flex;
  flex-direction: row;

  @include breakpoint("mobile") {
    position: absolute;
    top: $general-spacing-unit * 6;
    right: $general-spacing-unit * 6;
  }

  a {
    margin-left: 8px;
  }
}

@media only screen and (max-width: 1279px) {
  .logo {
    width: 116px;
  }

  .credits {
    font-size: 13px;
    line-height: 16px;
  }
}