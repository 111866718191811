@import "../../../../libs/styles/main.scss";

.banner section {
    padding-top: $general-spacing-unit * 9;

    h2 {
        font-size: 70px;
        line-height: 80px;

        b {
            color: $color-primary-enterprise;
        }
    }

    p {
        font-size: 24px;
        line-height: 34px;
    }

    @include breakpoint("mobile") {
        padding-top: $general-spacing-unit * 5;

        h2 {
            font-size: 32px;
            line-height: 38px;
            text-align: center;
            margin: auto;
        }

        p {
            font-size: 18px;
            line-height: 24px;
        }
    }

}

.mention {
    margin-bottom: $general-spacing-unit * 12;

    @include breakpoint("tablet") {
        margin: 0 auto $general-spacing-unit * 12;
    }

    @include breakpoint("mobile") {
        margin: 0 auto $general-spacing-unit * 6;
    }
}

.talker section,
.call section {
    padding: 0;
    margin-bottom: $general-spacing-unit * 15;

    span,
    h2 b {
        color: $color-primary-enterprise;
    }

    @include breakpoint("tablet") {
        padding-bottom: $general-spacing-unit * 7;
        margin-bottom: 0;
    }

    @include breakpoint("mobile") {
        padding-bottom: $general-spacing-unit * 7;
        margin-bottom: 0;

        &>div:nth-child(1) {
            div:last-child {
                max-width: 100%;
                margin: 0;
                display: flex;
                justify-content: center;
            }
        }
    }
}

.talker {
    @include breakpoint("mobile") {
        scroll-margin: 100px;
    }
}

.call section {
    &>div:nth-child(1) {
        &>div:last-child {
            margin: 0;
        }
    }

    @include breakpoint("mobile") {
        flex-direction: column;
        padding-bottom: 0px;
    }
}

.mailTo {
    display: flex;
    align-items: center;

    svg {
        height: 25px;
    }

    a {
        text-decoration: none;
        font-size: 18px;
        font-weight: 700;
        color: $color-plantation;
    }

    @include breakpoint("mobile") {
        flex-direction: column;
        width: 75%;
        margin: 0 auto 55px;

        &>div {
            display: flex;
        }

        p {
            font-weight: 500;
            font-size: 18px;
            color: $color-plantation;
            line-height: 28px;
            text-align: center;
        }
    }
}

.mentionFooter {
    padding: $general-spacing-unit * 8 0px;
    background-color: #438fcc42;
    margin-bottom: -$general-spacing-unit * 5;

    section {
        border-bottom: none;
    }

    p {
        color: $color-cloud-burst;
    }

    span {
        margin: 0;
        color: $color-primary-enterprise;
    }

    @include breakpoint("mobile") {
        padding: $general-spacing-unit * 3 0;

        span {
            margin: $general-spacing-unit 0;
            color: $color-bali-hai;
        }
    }
}

.buttonCTA {
    border-radius: 5px;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    text-decoration: none;
    display: block;
    border: 2px solid $color-primary-enterprise;
    background: $color-primary-enterprise;
    color: $color-white;
    box-sizing: border-box;
    white-space: nowrap;
    transition: color ease 0.15s, background-color ease 0.15s;
    height: 56px;
    line-height: 52px;
    min-width: 288px;
    font-family: $font-source-sans;
    cursor: pointer;

    @include breakpoint("mobile") {
        max-width: 100%;
    }
}

section {
    @include breakpoint("tablet") {
        &>div:nth-child(2) {
            img {
                margin-right: 0 !important;
                margin-left: 0 !important;
            }
        }
    }

    @include breakpoint("mobile") {
        &>div:nth-child(2) {
            img {
                margin: 0;
            }
        }
    }
}