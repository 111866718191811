@import "../../libs/styles/main.scss";

.wrapper {
    background-color: $color-white;
    border-radius: 20px;
    box-shadow: 0 2px 20px 0 rgb(27 155 158 / 10%), 0 2px 20px 0 rgb(151 151 151 / 10%);
    text-align: left;
    padding: $general-spacing-unit * 5;

    @include breakpoint("small-tablet-bk") {
        border-radius: 0;
        z-index: 2;
        overflow: scroll;
        position: relative;
        min-width: auto;
    }

    @include breakpoint("mobile") {
        padding: $general-spacing-unit * 7 $general-spacing-unit * 4 $general-spacing-unit * 4;
        min-height: 40vh;
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $general-spacing-unit * 4;

    @include breakpoint("mobile") {
        margin-bottom: $general-spacing-unit * 2;
    }
}

.title {
    font-size: 32px;
    line-height: 38px;
    margin: 0;
}

.pretension {
    display: flex;
    flex-direction: column;
    max-width: max-content;
    margin-bottom: $general-spacing-unit * 3;

    span {
        font-size: 16px;
        line-height: 21px;
        font-weight: 700;
        margin-right: 3px;
        color: $color-cloud-burst;

        b {
            color: $color-eastern-blue;
        }
    }
}

.resume {
    font-size: 18px;
    line-height: 23px;
    color: $color-soft-dark;
    margin-bottom: $general-spacing-unit * 4;
}

.skills {
    display: flex;
    flex-direction: column;
    padding: 0;

    li {
        list-style: none;
        margin-bottom: $general-spacing-unit;
    }

    span {
        margin-left: 5px;
        font-weight: 700;
        line-height: 23px;
        font-size: 18px;
    }

    b {
        margin-left: 5px;
        font-weight: 400;
    }
}

.updated {
    margin-top: $general-spacing-unit;
    font-size: 18px;
    line-height: 23px;
}

.backButton {
    max-width: 350px;
    padding: 0;

    @include breakpoint("mobile") {
        background: transparent;
        border: 0;
        color: grey;
        margin: 0;

        &:hover {
            background-color: transparent;
        }
    }
}

.closeButton {
    padding: 0;
    background: transparent;
    margin: 0;
    display: flex;
    border: 0;
    cursor: pointer;

    @include breakpoint("mobile") {
        position: absolute;
        top: 11px;
        right: 11px;
    }
}