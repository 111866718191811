@import "../../libs/styles/main.scss";

.container {
  display: block;
  width: 1092px;
  max-width: 100vw;
  margin: $general-spacing-unit * 5 auto 0;

  @include breakpoint("mobile") {
    max-width: calc(100vw - $general-spacing-unit * 4);
  }
}

.wrapper {
  // padding: $general-spacing-unit * 5 $general-spacing-unit * 5 0;
  padding: $general-spacing-unit * 5;
  box-sizing: border-box;
  border-radius: 24px;
  box-shadow: 0 2px 20px 0 rgba(27, 155, 158, 0.1),
    0 2px 20px 0 rgba(151, 151, 151, 0.1);
  background: #fff;
  display: inline-grid;
  grid-template-columns: auto 1fr;
  column-gap: $general-spacing-unit * 3;

  @include breakpoint("mobile") {
    padding: 0;
    grid-template-columns: 1fr;
  }
}

.column {
  &.content {
    display: grid;
    row-gap: $general-spacing-unit * 3;

    @include breakpoint("mobile") {
      display: grid;
      padding: $general-spacing-unit * 3 $general-spacing-unit * 2;
    }
  }

  &.sidebar {
    width: 370px;

    @include breakpoint("mobile") {
      width: 100%;
    }
  }
}

.shareSection {
  padding-left: $general-spacing-unit * 6;

  button {
    margin-left: 0;
  }
}

.shareTitle {
  font-size: 16px;
  margin: 0 0 $general-spacing-unit * 2;
  font-weight: $font-weight-bold;
  color: $color-soft-dark;
  display: block;
  text-transform: uppercase;
}

.detail {
  flex: 1;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  color: $color-cloud-burst;
  text-align: center;
  padding-left: 24px;
}

.descriptionWrapper {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  text-align: left !important;
  color: $color-cloud-burst;
  text-align: left;

  p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.wrapper h1 {
  font-weight: 700;
  font-size: 24px;
  color: $color-surfie-green;
  margin: 0;
  height: max-content;
}

.date {
  margin: 0;
  font-size: 16px;
  color: $color-cloud-burst;
  height: max-content;

  span {
    color: $color-eastern-blue;
  }

  b {
    font-weight: 700;

    @include breakpoint("mobile") {
      &:first-child {
        display: inline-block;
        margin-bottom: $general-spacing-unit;
      }
    }
  }
}

.illustration {
  display: block;
  width: 100%;
  height: auto;
  margin: $general-spacing-unit * 11 0 $general-spacing-unit * 3;
}

.backButton {
  background: transparent;
  border: 0;
  height: 56px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  float: right;
  color: $color-eastern-blue;
  font-family: $font-source-sans;
  font-size: 16px;
  line-height: 1;
  font-weight: $font-weight-semibold;

  svg {
    margin-right: $general-spacing-unit;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;

  button {
    margin-top: 0;
  }
}

.modalContent span {
  font-weight: 400;
  text-align: center;
  margin-bottom: $general-spacing-unit * 3;
  padding: 0 $general-spacing-unit * 3;
}

.modalContent button {
  cursor: pointer;
}

.sectionWrapper {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: $color-cloud-burst;

  p:last-child {
    margin-bottom: 0;
  }
}

.sectionTitle {
  margin: 0 0 $general-spacing-unit * 2;
  color: $color-surfie-green;
  font-size: 16px;
  font-weight: $font-weight-bold;
}
