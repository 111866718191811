@import "../../libs/styles/main.scss";

.wrapper {
  text-align: center;
  padding: $general-spacing-unit * 10 $general-spacing-unit * 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 50vh;

  @include breakpoint("small-tablet-bk") {
    padding: $general-spacing-unit * 4 0 0 0;
  }
}

.modalContainer {
  width: 100%;
}

.header {
  font-size: 56px;
  line-height: 64px;
  color: $color-eastern-blue;
  margin: 0 0 $general-spacing-unit * 4 0;

  b {
    color: $color-cloud-burst;
  }

  @include breakpoint("tablet") {
    font-size: 48px;
    line-height: 56px;
    margin-bottom: $general-spacing-unit * 3;
  }

  @include breakpoint("mobile") {
    font-size: 32px;
    line-height: 38px;
    margin-bottom: $general-spacing-unit;
  }
}

.description {
  font-size: 24px;
  line-height: 32px;
  color: $color-plantation;
  text-align: center;
  margin-bottom: $general-spacing-unit * 6;
  margin-top: 0;

  @include breakpoint("tablet") {
    margin-bottom: $general-spacing-unit * 5;
  }

  @include breakpoint("mobile") {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: $general-spacing-unit * 3;
  }
}

.filters {
  max-width: 1175px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 20vh;

  @include breakpoint("small-tablet-bk") {
    position: inherit;
    max-width: 100%;
  }
}

.content {
  width: 100%;
  display: flex;

  @include breakpoint("mobile") {
    flex-direction: column;
  }
}

// details modal
.headerDetails {
  margin: 0 0 30px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  h1 {
    font-size: 16px;
    margin: 0;
    flex: 1 1;
  }

  button {
    margin: 0;
  }

  button:nth-child(1) {
    background: transparent;
    border: 0;
    cursor: pointer;
    width: 56px;
  }

  @include breakpoint ("mobile") {
    padding: 0 16px;

    button:nth-child(1) {
      width: max-content;
    }
  }
}