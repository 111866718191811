@import "../../../../libs/styles/main.scss";

.url {
  border-radius: 5px;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  flex: 1;
  text-decoration: none;
  display: block;
  border: 2px solid $color-primary-enterprise;
  box-sizing: border-box;
  white-space: nowrap;
  transition: color ease 0.15s, background-color ease 0.15s;

  @include breakpoint("mobile") {
    max-width: 100%;
  }
}

.fill {
  background: $color-primary-enterprise;
  color: #fff;
}

.fill:hover {
  border-color: darken($color-primary-enterprise, 7.5%);
  background: darken($color-primary-enterprise, 7.5%);
}

.outline {
  background: transparent;
  color: $color-primary-enterprise;
}

.outline:hover {
  border-color: darken($color-primary-enterprise, 7.5%);
  background: darken($color-primary-enterprise, 7.5%);
  color: #fff;
}

.small {
  height: 32px;
  line-height: 28px;
  flex: 0;
  font-size: 13px;
  padding: 0 16px;
}

.medium {
  height: 56px;
  line-height: 52px;
}

.inline {
  flex: 0;
  display: inline-block;
  padding: 0 96px;

  @include breakpoint("mobile") {
    padding: 0 $general-spacing-unit;
    min-width: 100%;
  }
}