@import "../../../../libs/styles/main.scss";

.wrapper {
  position: absolute;
  right: $general-spacing-unit * 3;
  top: $general-spacing-unit * 5;
  z-index: 1;

  &:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
}

.status {
  svg {
    max-width: 28px;
    height: auto;
  }
}

.tooltip {
  position: absolute;
  left: 50%;
  bottom: 100%;
  width: 200px;
  background: $color-cloud-burst;
  color: $color-white;
  padding: 0 $general-spacing-unit;
  height: 40px;
  font-size: 12px;
  line-height: 40px;
  text-align: center;
  border-radius: 5px;
  font-weight: $font-weight-semibold;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, -10px);

  @include breakpoint("mobile") {
    display: none;
  }

  &:after {
    content: "";
    width: 10px;
    height: 10px;
    background: $color-cloud-burst;
    display: block;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: rotate(45deg);
    margin-top: -5px;
    margin-left: -5px;
  }
}
