@import "../../libs/styles/main.scss";

.inputFileContent {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  position: relative;

  & > p {
    display: block;
    font-weight: 700;
    font-size: 12px;
    color: $color-cloud-burst;
    line-height: 16px;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: $general-spacing-unit;
  }

  & input[type="file"] {
    display: none;
  }

  & span:nth-child(3) {
    font-size: .75rem;
  }
}

.inputFile {
  border: dashed .125rem rgb(158, 158, 158);
  border-radius: .75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f9fc;
  height: 5rem;
  width: 100%;

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer;

    span {
      font-size: 1rem;
      color: $color-cloud-burst;
      font-weight: 600;
      opacity: .5;
    }
  }
}

.uploadedFile {
  border-color: $color-green-ocean;

  label span {
    color: $color-green-ocean;
    opacity: .8;
    text-align: center;

    p {
      color: $color-cloud-burst;
      margin: .2rem 0 0;
      font-size: .8rem;
      opacity: .5;
    }
  }
}

.viewFileButton {
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  flex-grow: 1;

  span {
    font-size: 1rem;
    color: $color-cloud-burst;
    font-weight: 600;
    opacity: .4;
    text-align: center;
  }
}

.fileList {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: .5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
  padding-bottom: 4px;
  overflow: auto;

  & button {
    flex-direction: column;
  }

  & span {
    font-size: .75rem;
    text-decoration: underline;
  }

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f191;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: blue;
  }
}

.inputFileMinimized {
  border: dashed .125rem rgb(158, 158, 158);
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding: .75rem;
  cursor: pointer;
  background: transparent;
  position: relative;

  &>i {
    font-size: 2rem;
  }

  &>input {
    position: absolute;
    width: 100%;
    overflow: hidden;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}