@import "../../../../libs/styles/main.scss";

.wrapper {
  display: flex;
  width: 100%;
  background: $color-white;
  border-radius: 10px;
  padding: $general-spacing-unit * 5 $general-spacing-unit * 3;
  box-sizing: border-box;
  box-shadow: 0 2px 20px 0 rgba(27, 155, 158, 0.1),
    0 2px 20px 0 rgba(151, 151, 151, 0.1);
  flex-direction: column;
  justify-content: space-between;
  height: 313px;
}

.details {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.dateAndSubscribers {
  font-weight: $font-weight-semibold;
}

.date {
  font-weight: $font-weight-semibold;
  font-size: 13px;
}

.subscribers {
  font-weight: $font-weight-semibold;
  color: $color-eastern-blue;
  font-size: 13px;

  &:before {
    content: " - ";
    color: $color-cloud-burst;
  }

}

.secondaryLayout {
  color: $color-primary-enterprise;
}

.chipsWrapper {
  display: flex;
  gap: $general-spacing-unit * 2;
  flex: 1;
  align-items: flex-end;

  span {
    height: max-content;
    max-width: 140px;
  }
}

.title {
  font-size: 24px;
  line-height: 32px;
  color: $color-cloud-burst;
  margin: 0 0 $general-spacing-unit * 2;
}

.link {
  color: $color-white;
  font-size: 18px;
  letter-spacing: 0;
  text-align: center;
  border-radius: 5px;
  display: block;
  line-height: 56px;
  height: 56px;
  text-decoration: none;
  font-weight: $font-weight-bold;
  margin-top: $general-spacing-unit * 2;

  &.valid {
    background: $color-eastern-blue;
  }

  &.invalid {
    background: $color-edward;
    pointer-events: none;
    cursor: default;
  }
}

.secondaryLayout {
  &.valid {
    background: $color-primary-enterprise;
  }
}