@import "../../libs/styles/main.scss";

.wrapper {
    width: 68%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $general-spacing-unit * 3;
    box-sizing: border-box;

    &>button {
        width: 350px;
    }

    &.secondaryLayout {
        &>button {
            border-color: $color-secondary-enterprise;
            color: $color-secondary-enterprise;
        }

        &>button:hover {
            border-color: $color-secondary-enterprise;
            background-color: $color-secondary-enterprise;
            color: $color-white;
        }

    }

    @include breakpoint("tablet-and-below") {
        width: 100%;
        padding: $general-spacing-unit * 5 $general-spacing-unit * 4;

        &.secondaryLayout {
            &>button:hover {
                border-color: $color-secondary-enterprise;
                color: $color-secondary-enterprise;
                background-color: transparent;
            }

        }

        &>button {
            width: 100%;
            max-width: 350px;
        }
    }
}