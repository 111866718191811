@import "../../../../libs/styles/main.scss";

.wrapper {
  text-align: center;
  color: $color-white;
  background: rgba($color-black, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity ease 0.25s;

  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.title {
  margin: $general-spacing-unit * 3 0 $general-spacing-unit;
  font-size: 32px;
  line-height: 38px;
}

.message {
  margin: $general-spacing-unit 0 0;
  font-size: 18px;
  line-height: 28px;
}

.iconWrapper {
  width: 32px;
  height: 32px;
  animation: spin 0.75s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    50% {
      opacity: 0.9;
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
