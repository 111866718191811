@import "../../../../libs/styles/main.scss";

.wrapper {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity ease 0.25s;
    z-index: 3;
    opacity: 1;
    visibility: visible;
}

.container {
    display: flex;
    background: $color-white;
    width: max-content;
    border-radius: 10px;
    overflow: hidden;
    padding: $general-spacing-unit * 3 $general-spacing-unit * 4 $general-spacing-unit * 3 0;
    box-sizing: border-box;
    margin: 0 $general-spacing-unit * 3;

    @include breakpoint("tablet-and-below") {
        padding: $general-spacing-unit * 3;
    }
}

.image {
    height: 350px;

    @include breakpoint("tablet-and-below") {
        display: none;
    }
}

.description {
    max-width: 440px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: $general-spacing-unit *3;
    position: relative;

    button {
        flex: 0;
        max-width: 250px;
        align-self: flex-end;
        margin: 0;
        position: absolute;
        bottom: 0;
    }

    @include breakpoint("tablet-and-below") {
        max-width: 320px;

        button {
            position: inherit;
            max-width: 100%;
        }
    }
}

.title {
    font-size: 48px;
    line-height: 53px;
    color: $color-eastern-blue;
    margin: 0;

    @include breakpoint("tablet-and-below") {
        font-size: 32px;
        line-height: 40px;
        text-align: center;
    }
}

.text {
    font-size: 22px;
    line-height: 32px;

    @include breakpoint("tablet-and-below") {
        font-size: 20px;
        line-height: 30px;
        text-align: center;
    }
}

.goNext {
    border: 0;
    background: transparent;
    color: #1B9B9E;
    font-size: 18px;
    font-weight: bold;
    justify-self: flex-end;
    text-align: end;
    cursor: pointer;
    position: absolute;
    bottom: 0;

    @include breakpoint("tablet-and-below") {
        margin-top: $general-spacing-unit * 3;
        position: inherit;
    }
}

.secondaryLayout {

    .title {
        color: $color-primary-enterprise;
    }

    .goNext {
        color: $color-primary-enterprise;
    }
}

.btnSecondaryLayout {
    background-color: $color-primary-enterprise;
    border-color: $color-primary-enterprise;

    &:hover {
        background-color: $color-secondary-enterprise-hover;
        border-color: $color-secondary-enterprise-hover;
    }
}