@import "../../libs/styles/main.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  h1 {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 600;
    color: $color-valencia;
  }

  button {
    max-width: 18rem;
    align-self: flex-end;
  }
}

.inputBox {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  input, fieldset, div {
    max-width: 18rem;
    width: 100%;
  }
}

.radioBox {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}