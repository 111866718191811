@import '../../../../libs/styles/main.scss';

.formWrapper {
  & input:focus {
    border: solid 1px $color-primary-enterprise;
  }

  h2 > b {
    color: $color-primary-enterprise;
  }

  & > div > button,
  & > div > button:hover,
  & > div > a:hover {
    border-color: $color-primary-enterprise;
    background-color: $color-primary-enterprise;
  }

  & > div > a {
    border-color: $color-primary-enterprise;
    color: $color-primary-enterprise;
  }
}

.forgotPasswordLink {
  text-align: right;
  display: block;
  margin: $general-spacing-unit 0 $general-spacing-unit * 2;
  font-weight: 700;
  color: $color-primary-enterprise;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
