@import "../../../../libs/styles/main.scss";

.wrapper {
    h2>b {
        color: $color-primary-enterprise;
    }

    button,
    button:hover {
        background-color: $color-primary-enterprise;
        border-color: $color-primary-enterprise;
    }
}