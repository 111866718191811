@import "../../libs/styles/main.scss";

.title {
  font-weight: 700;
  font-size: 50px;
  color: #222b45;
  line-height: 50px;
  margin: 0 0 16px;
  outline: none;

  &.small {
    font-size: 32px;
    line-height: 38px;
  }

  &.centered {
    text-align: center;
  }

  @include breakpoint("mobile") {
    margin-bottom: 8px;
    font-size: 32px;
    line-height: 38px;
  }

  em,
  b {
    color: #1b9b9e;
    font-style: normal;
  }

  &.linebreak b {
    display: block;
  }
}
