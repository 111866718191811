@import "../../../../libs/styles/main.scss";

.wrapper {
  background-color: darken($color-catskill, 2.5%);
  width: calc(100% + #{$general-spacing-unit * 6});
  margin-left: $general-spacing-unit * 3 * -1;
  padding: $general-spacing-unit * 3 $general-spacing-unit * 3;
  box-sizing: border-box;

  p {
    margin: 0 0 $general-spacing-unit * 2;
    font-size: 18px;
    line-height: 24px;
    color: $color-plantation;
  }

  img,
  svg {
    width: 48px;
    height: 48px;
    float: left;
    margin-right: $general-spacing-unit * 2;
  }
}
