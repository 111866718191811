@import "../../libs/styles/main.scss";

.wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba($color-cloud-burst, 0.25);
  z-index: 4;
  visibility: hidden;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &.isOpen {
    opacity: 1;
    visibility: visible;
  }
}

.container {
  border-radius: $general-spacing-unit * 3;
  background: #fff;
  flex: 0;
  padding: $general-spacing-unit * 5;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 50px 0 rgba(27, 155, 158, 0.1),
    0 2px 20px 0 rgba(151, 151, 151, 0.1);
  z-index: 4;
  visibility: hidden;
  opacity: 0;
  width: 470px;
  max-width: calc(100vw - (#{$general-spacing-unit} * 8));
  box-sizing: border-box;

  @include breakpoint("mobile") {
    padding: $general-spacing-unit * 3;
  }

  &.isOpen {
    opacity: 1;
    visibility: visible;
  }

  p {
    margin: 0;
  }
}

.closeButton {
  font-size: 0;
  position: absolute;
  right: $general-spacing-unit * 5;
  top: $general-spacing-unit * 5;
  width: 40px;
  height: 40px;
  border: 0;
  background: transparent;
  padding: 0;
  cursor: pointer;

  @include breakpoint(mobile) {
    width: $general-spacing-unit * 4;
    height: $general-spacing-unit * 4;
    right: $general-spacing-unit * 2;
    top: $general-spacing-unit * 3;
  }

  svg {
    transform: rotate(45deg);
  }
}

.title {
  font-weight: 700;
  font-size: 32px;
  margin: 0 0 24px;

  @include breakpoint("mobile") {
    max-width: calc(100% - 40px);
    font-size: 24px;
    line-height: 32px;
  }
}

.url {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #1b9b9e;
  max-width: 100%;
  max-height: 28px * 2;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  text-decoration: none;
}

.socialShareText {
  color: $color-cloud-burst;
  font-size: 18px;
  margin: $general-spacing-unit * 3 0 $general-spacing-unit * 2;
}

.secondaryLayout {
  a {
    color: $color-primary-enterprise;
  }

  button:nth-child(4) {
    border-color: $color-primary-enterprise;
    background-color: $color-primary-enterprise;

    &:hover {
      border-color: $color-secondary-enterprise-hover;
      background-color: $color-secondary-enterprise-hover;
    }
  }
}