@import "../../../../libs/styles/main.scss";

.wrapper {
  display: inline-grid;
  gap: $general-spacing-unit * 3;
  width: 100%;

  @include breakpoint("mobile") {
    grid-template-columns: 1fr;
  }
}