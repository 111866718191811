@import "../../libs/styles/main.scss";

.url {
  border-radius: 5px;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  flex: 1;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 2px solid #1b9b9e;
  box-sizing: border-box;
  white-space: nowrap;
  transition: color ease 0.15s, background-color ease 0.15s;
  background: #1b9b9e;
  color: #fff;
  height: 56px;
  line-height: 52px;

  &:hover {
    border-color: darken(#1b9b9e, 7.5%);
    background: darken(#1b9b9e, 7.5%);
  }

  @include breakpoint("mobile") {
    max-width: 100%;
  }
}

.fakeButton {
  border-radius: 5px;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  color: #fff;
  height: 56px;
  line-height: 52px;
  background: #1b9b9e;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  cursor: default;

  img,
  svg {
    display: block;
    margin-right: $general-spacing-unit;
    width: 26px;
    height: 26px;
  }
}

.icon {
  display: block;
  margin-right: $general-spacing-unit;
  width: 26px;
  height: 26px;
}

.inkoinWrapper {
  border-left: 2px solid rgba($color-white, 0.65);
  line-height: 26px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: $general-spacing-unit * 1.5;
  margin-left: $general-spacing-unit * 1.5;

  img,
  svg {
    margin-right: $general-spacing-unit;
  }
}
