@import "../../../../libs/styles/main.scss";

.wrapper {

    & input {
        &:focus {
            border: solid 1px $color-primary-enterprise;
        }
    }

    &>h2>b,
    a {
        color: $color-primary-enterprise;
    }

    &>button {
        background-color: $color-primary-enterprise;
        border-color: $color-primary-enterprise;
    }

    &>button:hover {
        background-color: $color-primary-enterprise;
    }

    & label::before {
        border-color: $color-primary-enterprise;
    }
}