@import "../../../../../libs/styles/main.scss";

.paragraph {
  color: $color-plantation;
  font-size: 18px;
  line-height: 150%;
  margin: $general-spacing-unit * 2 0;
}

.cancelButton {
  margin-top: $general-spacing-unit;
  text-align: center;
  height: 56px;
  line-height: 56px;
  font-weight: 700;
  font-size: 18px;
  font-family: $font-source-sans;
  border: 0;
  cursor: pointer;
  width: 100%;
  color: $color-bali-hai;
  background: transparent;
  transition: color ease 0.25s;

  &:hover {
    color: $color-plantation;
  }
}
