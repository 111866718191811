@import "../../../../../libs/styles/main.scss";

.wrapper {
    background-color: $color-white;
    border-radius: 20px;
    box-shadow: 0 2px 20px 0 rgb(27 155 158 / 10%), 0 2px 20px 0 rgb(151 151 151 / 10%);
    text-align: left;
    padding: $general-spacing-unit * 5;

    @include breakpoint("small-tablet-bk") {
        border-radius: 0;
        z-index: 2;
        overflow: scroll;
        position: relative;
        min-width: auto;
    }

    @include breakpoint("mobile") {
        padding: $general-spacing-unit * 7 $general-spacing-unit * 4 $general-spacing-unit * 4;
        min-height: 40vh;
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $general-spacing-unit * 4;

    @include breakpoint("mobile") {
        margin-bottom: $general-spacing-unit * 2;
    }
}

.pretension {
    display: flex;
    flex-direction: column;
    max-width: max-content;
    margin-bottom: $general-spacing-unit * 3;
}

.resume {
    font-size: 18px;
    line-height: 23px;
    color: $color-soft-dark;
    margin-bottom: $general-spacing-unit * 4;
}