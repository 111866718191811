.wrapper {
  margin: 72px 0 80px;
  overflow: hidden;
}

@media only screen and (max-width: 1279px) {
  .wrapper {
    margin: 32px 0 56px;
  }
}
