@import "../../../../libs/styles/main.scss";

.wrapper {
    display: flex;
    flex: 1;
    margin-left: $general-spacing-unit * 8;
}

.item {
    padding: 4px $general-spacing-unit * 2 $general-spacing-unit;
    font-size: 24px;
    line-height: 32px;
    flex: 1;
    font-weight: 700;
    border: 0;
    background: transparent;
    color: $color-bali-hai;
    border-bottom: solid 4px $color-white;
    font-family: $font-source-sans;
    cursor: pointer;
    transition: all .5s;
    white-space: nowrap;
}

.currentTab {
    color: $color-eastern-blue;
    border-bottom: solid 4px;
}

.secondaryLayout .currentTab {
    color: $color-primary-enterprise;
}