@import "../../libs/styles/main.scss";

.wrapper {
    background: $color-white;
    padding: $general-spacing-unit * 5;
    margin: 0 auto $general-spacing-unit * 6;
    box-sizing: border-box;
    border-radius: 20px;
    box-shadow: 0 2px 20px 0 $color-first-shadow-section,
        0 2px 20px 0 $color-second-shadow-section;
    width: 100%;
}

.name {
    font-size: 32px;
    line-height: 38px;
    text-align: left;
    margin-bottom: $general-spacing-unit * 4;

    &.secondaryLayout {
        &>b {
            color: #438FCC;
        }
    }
}

.form {
    display: flex;
    flex-wrap: wrap;
    gap: $general-spacing-unit * 2;

    // Input and Select Inputs
    &>div {
        flex: 1;
        min-width: 230px;
    }

    // First Input
    &>div:nth-child(1) {
        min-width: 288px;
    }

    // Square Button
    &>button {
        flex: 1;
        padding: 0 24px;
    }

    // Cancel button
    &>button:nth-child(5) {
        display: none;
        background-color: transparent;
        color: $color-bali-hai;
        border: none;
        margin-top: 0;
    }

    &.secondaryLayout {
        &>button {
            background-color: #326B99;
            border: #326B99;
        }
    }
}

@include breakpoint("tablet-and-below") {
    .form {
        flex-direction: column;
        gap: $general-spacing-unit * 3;
        align-items: center;

        // Input and Select Inputs
        &>div {
            width: 100%;
        }

        // Cancel button
        &>button:nth-child(5) {
            display: block;
        }
    }

    // Mobile Version
    .wrapperMobile {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-aqua-haze;
        padding: $general-spacing-unit * 3 $general-spacing-unit * 7;
        box-sizing: border-box;

        // Search button
        & button {
            max-width: 288px;
            margin: 0;
            padding: 0;
        }

        // Search button
        // &>button:hover {
        //     background-color: transparent !important;
        //     color: $color-eastern-blue !important;
        // }

        &.secondaryLayout button {
            color: #326B99;
            border: solid 2px #326B99;
        }
    }

    .closeButton {
        background: transparent;
        padding: 0;
        margin: 0;
        border: 0;
        display: flex;
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 20px;
        transform: rotateZ(45deg);
    }

    // Modal
    .modal {
        position: fixed;
        z-index: 4;
        background: $color-white;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        box-sizing: border-box;
        padding: $general-spacing-unit * 7 $general-spacing-unit * 4;

        &.secondaryLayout {
            b {
                color: #438FCC;
            }

            & button:nth-child(4) {
                background-color: #326B99;
                border: #326B99;
            }
        }
    }

}