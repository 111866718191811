@import "../../libs/styles/main.scss";

.wrapper {
  display: flex;
  align-items: center;
}

.input {
  display: block;
  color: $color-cloud-burst;
  background: transparent;
  border: none;
  position: relative;
  cursor: pointer;
  padding: 0;
  margin-right: $general-spacing-unit;

  &:before,
  &:after {
    content: "";
    display: block;
    border-radius: 100%;
  }

  &:before {
    width: $general-spacing-unit * 3;
    height: $general-spacing-unit * 3;
    box-sizing: border-box;
    background-color: #f6f7f9;
    border: 1px solid #c5cee0;
  }

  &:after {
    position: absolute;
    left: 5px;
    top: 5px;
    width: $general-spacing-unit * 1.75;
    height: $general-spacing-unit * 1.75;
  }

  &.active {
    &:before {
      border-color: $color-eastern-blue;
    }

    &:after {
      background-color: $color-eastern-blue;
    }
  }
}

.text {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
}

.secondaryLayout {
  .input {
    &.active {
      &:before {
        border-color: $color-primary-enterprise;
      }

      &:after {
        background-color: $color-primary-enterprise;
      }
    }
  }
}