@import "../../../../libs/styles/main.scss";

.wrapper {
  width: 348px;
  margin: 0 auto;

  @include breakpoint("mobile") {
    width: 100%;
  }
}
