@import "../../../libs/styles/main.scss";

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-height: 50vh;
    padding: $general-spacing-unit * 10 $general-spacing-unit * 3;

    @include breakpoint("small-tablet-bk") {
        padding: $general-spacing-unit * 4 0 0 0;
    }

    @include breakpoint("tablet-and-below") {
        padding: $general-spacing-unit * 4 0;
    }
}

.title {
    font-size: 56px;
    line-height: 64px;
    margin: 0 0 $general-spacing-unit * 4 0;

    &.secondaryLayout {
        &>b {
            color: $color-primary-enterprise;
        }
    }

    @include breakpoint("tablet") {
        font-size: 48px;
        line-height: 56px;
        margin-bottom: $general-spacing-unit * 3;
    }

    @include breakpoint("mobile") {
        font-size: 32px;
        line-height: 38px;
        margin-bottom: $general-spacing-unit;
    }
}

.description {
    font-size: 24px;
    line-height: 32px;
    color: $color-plantation;
    text-align: center;
    margin-bottom: $general-spacing-unit * 6;
    margin-top: 0;

    @include breakpoint("tablet") {
        margin-bottom: $general-spacing-unit * 5;
    }

    @include breakpoint("mobile") {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: $general-spacing-unit * 3;
    }
}

.filters {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 20vh;
    max-width: 1175px;
}

.searchBlock {
    background: $color-white;
    padding: $general-spacing-unit * 5;
    margin: 0 auto $general-spacing-unit * 6;
    box-sizing: border-box;
    border-radius: 20px;
    box-shadow: 0 2px 20px 0 $color-first-shadow-section,
        0 2px 20px 0 $color-second-shadow-section;
    width: 100%;
}

.name {
    font-size: 32px;
    line-height: 38px;
    text-align: left;
    margin-bottom: $general-spacing-unit * 4;

    &.secondaryLayout {
        &>b {
            color: $color-primary-enterprise;
        }
    }
}

.searchInputs {
    display: flex;
    gap: 16px;
    align-items: flex-end;
    flex-wrap: wrap;
}

.content {
    display: flex;
    width: 100%;
    box-sizing: border-box;
}

.filtersWrapper {
    background-color: $color-aqua-haze;
    width: 32%;
    padding: $general-spacing-unit * 3;
    max-width: 350px;
    border-radius: 10px 0 0 10px;
}

.singleFilterWrapper {
    margin-top: $general-spacing-unit * 3;
    padding-bottom: $general-spacing-unit * 3;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: solid 1px $color-light-grey;
}

.jobListWrapper {
    width: 68%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $general-spacing-unit * 3;
}

.singleJobWrapper {
    display: flex;
    flex-direction: column;
    border-bottom: solid 1px $color-catskill-white;
    width: 100%;
    text-align: left;
    padding-bottom: $general-spacing-unit * 3;
    margin-bottom: $general-spacing-unit * 5;

    @include breakpoint ("small-tablet-bk") {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.filterDescription {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filterDetails {
    display: flex;
    flex-direction: column;
}

.skeletonWrapper {
    box-sizing: border-box;
    display: flex;
}

//Mobile version
.searchBlockMob {
    background-color: $color-aqua-haze;
    padding: $general-spacing-unit * 3 $general-spacing-unit * 7;
    box-sizing: border-box;
}