@import "../../libs/styles/main.scss";

.alert {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  background: $color-eastern-blue;
  width: 100%;
  color: #fff;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: $general-spacing-unit * 8;
  margin: 0;
  transform: translateY(-100%);
  opacity: 0.5;
  transition: transform ease 0.25s, opacity ease 0.25s;

  &.visible {
    transform: none;
    opacity: 1;
  }
}

.secondaryLayout {
  background-color: #438FCC;
  height: 80px;
}

.error {
  background: $color-valencia;
}