@import "../../libs/styles/main.scss";

.wrapper {
  width: max-content;
  margin: 32px auto;
  text-align: center;
}

.title {
  font-weight: 700;
  font-size: 32px;
  color: #222b45;
  line-height: 38px;
  margin: 0 0 8px;

  b {
    color: #1b9b9e;
  }
}

.description {
  margin: 8px 0 0;
  font-size: 16px;
  color: #6c7894;
  line-height: 24px;
}