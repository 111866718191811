@import "../../libs/styles/main.scss";

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 56px;
  line-height: 56px;
  border: 0;
  width: 100%;
  padding: 0;
  font-size: 18px;
  font-family: $font-source-sans;
  font-weight: 700;
  border-radius: 5px;
  cursor: pointer;

  &:before {
    content: "";
    width: 18px;
    height: 18px;
    background: transparent center center no-repeat;
    background-size: auto 100%;
    display: block;
    margin-right: $general-spacing-unit;
  }
}

.facebookButton {
  color: $color-white;
  background: #2d54ae;

  &:before {
    background-image: url("./assets/facebook-login-icon.png");
  }
}

.googleButton {
  background: $color-lilac;
  color: $color-cloud-burst;
  box-shadow: 0 2px 2px 0 rgba(143, 143, 143, 0.28);

  &:before {
    background-image: url("./assets/google-login-icon.png");
  }
}
