@import "../../libs/styles/main.scss";

.wrapper {
  background: $color-white;
  padding: $general-spacing-unit * 5;
  margin: 0 auto $general-spacing-unit * 6;
  box-sizing: border-box;

  &.home {
    border-radius: 20px;
    max-width: 1092px;
    box-shadow: 0 2px 20px 0 rgba(27, 155, 158, 0.1),
      0 2px 20px 0 rgba(151, 151, 151, 0.1);

    @include breakpoint("mobile") {
      margin: $general-spacing-unit * 4;
      padding: $general-spacing-unit * 4;
    }
  }

  &.mobile {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    height: 100%;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    border: 0 solid $color-white;
    border-bottom: 100px;
    width: 100%;

    &.visible {
      opacity: 1;
      z-index: 2;
      visibility: visible;
    }
  }
}

.cancelButton {
  margin-top: $general-spacing-unit;
  color: $color-bali-hai;
  font-family: $font-source-sans;
  height: $general-spacing-unit * 7;
  font-weight: 700;
  width: 100%;
  border: none;
  background: transparent;
  font-size: 18px;
}

.closeButton {
  position: absolute;
  right: $general-spacing-unit * 3;
  top: $general-spacing-unit * 3;
  width: $general-spacing-unit * 4;
  height: $general-spacing-unit * 4;
  border: none;
  padding: 0;
  background: transparent;

  svg {
    position: absolute;
    transform: rotate(45deg) translate(-50%, -50%);
    transform-origin: top left;
    top: 50%;
    left: 50%;
  }
}
