@import "../../../../../libs/styles/main.scss";

.falseInput {
  text-decoration: none;
  color: $color-cloud-burst;
  position: relative;
  display: block;

  svg {
    width: 16px;
    height: auto;
    position: absolute;
    right: $general-spacing-unit * 2;
    top: $general-spacing-unit * 3;
    margin-top: $general-spacing-unit * 2.5;
    pointer-events: none;
  }
}

.falseLabel {
  display: block;
  font-weight: 700;
  font-size: 12px;
  color: $color-cloud-burst;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: $general-spacing-unit;
}

.falseField {
  background: $color-lilac;
  line-height: 54px;
  display: block;
  padding: 0 $general-spacing-unit * 3;
  font-size: 12px;
  border: 1px solid #e4e9f2;
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: $general-spacing-unit * 2;

  &.withPassword {
    letter-spacing: 5px;
  }

  &.withoutPassword {
    font-size: 18px;
    font-weight: 600;
    padding-left: $general-spacing-unit * 2;
  }
}
