@import "../../libs/styles/main.scss";

.wrapper {
  border-radius: 10px;
  background: #fff;
  overflow: hidden;
  position: relative;
  flex: 1;
  height: 100%;
  min-height: 520px;
  box-shadow: 0 2px 20px 0 rgba(27, 155, 158, 0.1),
    0 2px 20px 0 rgba(151, 151, 151, 0.1);
  display: flex;
  align-items: flex-end;
  padding: $general-spacing-unit * 3;
  box-sizing: border-box;
}

.label {
  background: #1b9b9e;
  font-size: 13px;
  line-height: 20px;
  padding: 4px 16px;
  position: absolute;
  left: 24px;
  top: 0;
  z-index: 1;
  font-weight: 700;
  color: #fff;
  border-radius: 0 0 5px 5px;
}

.link {
  display: block;
}

.image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  display: block;
  object-fit: cover;
}
