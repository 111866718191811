@import "../../libs/styles/main.scss";

.wrapper {
  position: relative;
  height: 14px;
  width: 14px;
}

.button {
  border: none;
  padding: 0;
  background: transparent;
  height: 14px;
  width: 14px;
}

.tooltip {
  position: absolute;
  left: 50%;
  bottom: 100%;
  width: 180px;
  background: $color-cloud-burst;
  color: $color-white;
  padding: $general-spacing-unit * 0.75 $general-spacing-unit;
  font-size: 12px;
  line-height: 40px;
  text-align: center;
  border-radius: 5px;
  font-weight: $font-weight-semibold;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, -10px);

  &:after {
    content: "";
    width: 10px;
    height: 10px;
    background: $color-cloud-burst;
    display: block;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: rotate(45deg);
    margin-top: -5px;
    margin-left: -5px;
  }

  p {
    line-height: 12px;
    font-size: 10px;
    margin: $general-spacing-unit * 0.25 0;
    text-transform: none;
    font-weight: 500;
  }
}

.wrapper {
  .tooltip.isOpen,
  &:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
}
