@mixin breakpoint($point) {
  @if $point == desktop {
    @media (min-width: 1280px) {
      @content;
    }
  } @else if $point == tablet {
    @media (min-width: 481px) and (max-width: 1024px) {
      @content;
    }
  } @else if $point == mobile {
    @media (max-width: 480px) {
      @content;
    }
  } @else if $point == "mobile-and-tablet" {
    @media (max-width: 1024px) {
      @content;
    }
  } @else if $point == "tablet-and-desktop" {
    @media (min-width: 481px) {
      @content;
    }
  } @else if $point == "tablet-and-below" {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $point == "mobile-and-tablet-2" {
    @media (min-width: 481px) and (max-width: 768px) {
      @content;
    }
  }
  @else if $point == "tablet-and-desktop-2" {
    @media (min-width: 768px) and (max-width: 1280px) {
      @content;
    }
  }

  // [Refatorar] - Corrects Breakpoints

  // @else if $point == "mobile" {
  //   @media (min-width: 480px) {
  //     @content;
  //   }
  // }
  // @else if $point == "tablet" {
  //   @media (min-width: 481px) and (max-width: 960px) {
  //     @content;
  //   }
  // }
  // @else if $point == "small-desktop-bk" {
  //   @media (min-width: 961px) and (max-width: 1024px) {
  //     @content;
  //   }
  // }
  // @else if $point == "large-desktop" {
  //   @media (min-width: 1024px) and (max-width: 1280px) {
  //     @content;
  //   }
  // }
 
  @else if $point == "tablet-bk" {
    @media (min-width: 481px) and (max-width: 960px) {
      @content;
    }
  }

  @else if $point == "small-tablet-bk" {
    @media (max-width: 760px) {
      @content;
    }
  }
  
  @else if $point == "small-desktop-bk" {
    @media (min-width: 961px) and (max-width: 1024px) {
      @content;
    }
  }

}

@mixin density($density) {
  @if $density == "2x" {
    @media (-webkit-min-device-pixel-ratio: 2) {
      @content;
    }
  }
}
