@import "../../../../libs/styles/main.scss";

.button {
  font-size: 20px;
  color: $color-surfie-green;
  line-height: 1;
  font-family: $font-source-sans;
  border: none;
  background: transparent;
  font-weight: 700;
  padding: 0;
  cursor: pointer;
  display: inline-block;

  @include breakpoint("mobile") {
    font-size: 13px;
    line-height: 18px;
  }
}
