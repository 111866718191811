@import "../../libs/styles/main.scss";

.wrapper {
  padding: 24px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 0 2px 20px 0 rgba(27, 155, 158, 0.1),
    0 2px 20px 0 rgba(151, 151, 151, 0.1);
  width: 100%;
  align-items: flex-start;
}

.innerWrapper {
  overflow: hidden;
}
