@import "../../../../libs/styles/main.scss";

.wrapper {
  padding: $general-spacing-unit * 3 0;
  border: 0 solid $color-catskill-white;
  border-bottom-width: 1px;
  box-sizing: border-box;
  position: relative;
}

.title {
  color: $color-plantation;
  font-size: 24px;
  margin: 0 0 $general-spacing-unit * 3;
  width: max-content;

  @include breakpoint("mobile") {
    max-width: calc(100% - $general-spacing-unit * 5);
    font-size: 18px;
    margin-bottom: $general-spacing-unit;
  }
}

.infoWrapper {
  margin: 0 0 $general-spacing-unit;
  font-size: 13px;

  @include breakpoint("mobile") {
    margin-bottom: $general-spacing-unit * 3;
  }
}

.company {
  color: #1b9b9e;
  font-weight: 600;
}

.date {
  font-weight: 600;
  color: #222b45;

  &:before {
    margin: 0 0.25em;
    content: "|";
  }
}

.description {
  margin: $general-spacing-unit 0 $general-spacing-unit * 3;
  color: $color-plantation;
  font-size: 16px;
  line-height: 24px;
  text-align: left;

  @include breakpoint("mobile") {
    margin: $general-spacing-unit * 2 0;
  }
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.tagsWrapper {
  display: flex;
  flex-direction: row;
  flex-grow: 1;

  @include breakpoint("mobile") {
    overflow-x: auto;
    padding-right: $general-spacing-unit;
  }

  >* {
    margin-left: $general-spacing-unit;

    &:first-child {
      margin-left: 0;
    }
  }
}

.link {
  background: $color-eastern-blue;
  font-size: 18px;
  height: 56px;
  line-height: 56px;
  color: $color-white;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  border-radius: 5px;
  display: block;
  width: $general-spacing-unit * 21;

  @include breakpoint("mobile") {
    height: 32px;
    line-height: 32px;
    width: $general-spacing-unit * 13;
    font-size: 13px;
  }
}

.shareWrapper {
  margin-left: 0 !important;

  @include breakpoint("mobile") {
    transform: scale(0.666);
    transform-origin: top right;
    position: absolute;
    top: $general-spacing-unit * 3;
    right: 0;
  }
}