@import "../../../../libs/styles/main.scss";

.wrapper {
}

.innerWrapper {
  font-size: 18px;
  line-height: 32px;
  color: $color-cloud-burst;

  ul {
    padding: 0;
    margin: 0 0 $general-spacing-unit * 3 0;

    li {
      display: inline-block;
      vertical-align: top;
      width: 33%;
      padding: 0 $general-spacing-unit;
      box-sizing: border-box;
      font-weight: 500;

      &:nth-child(3n) {
        padding-right: 0;
      }

      &:nth-child(3n + 1) {
        padding-left: 0;
      }

      @include breakpoint("tablet") {
        display: inline-block;
        vertical-align: top;
        width: 50%;
      }

      @include breakpoint("mobile") {
        display: block;
        width: 100%;
        padding: 0;
      }
    }

    a {
      font-weight: $font-weight-bold;
      color: $color-eastern-blue;
    }
  }

  ul + ul {
    padding-top: $general-spacing-unit * 3;
    border-top: 1px solid $color-catskill-white;
  }
}
