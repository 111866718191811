@import "../../../libs/styles/main.scss";

.wrapper {
    margin-top: $general-spacing-unit * 3;
    padding-bottom: $general-spacing-unit * 3;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: solid 1px $color-light-grey;


    &>button {
        margin: $general-spacing-unit * 3 0 0 !important;
    }

    &.secondaryLayout {
        .title {
            color: $color-primary-enterprise;
        }

        &>button {
            border: solid 1px $color-secondary-enterprise;
            color: $color-secondary-enterprise;
        }

        &>button:hover {
            color: $color-white;
            border: solid 1px $color-secondary-enterprise-hover;
            background-color: $color-secondary-enterprise-hover;
        }
    }
}

.title {
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    color: $color-surfie-green;
    margin: 0;
}

.filterGroup {

    &>div {
        margin: $general-spacing-unit * 2 0 0 0;
    }
}