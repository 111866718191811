@import "../../libs/styles/main.scss";

.wrapper {
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;

  form {
    align-items: flex-end;
    display: flex;
  }
}

.button {
  background: transparent;
  width: 38px;
  height: 38px;
  padding: 0;
  border: none;
  overflow: hidden;

  &.closeButton {
    svg {
      transform: rotate(45deg) scale(0.9);
    }

    @include breakpoint("tablet-and-desktop") {
      display: none;
    }
  }

  &.closed {
    width: 0;
  }
}

.field {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 18px;
  color: #a1abc1;
  line-height: 38px;
  height: 38px;
  border: none;
  background: transparent;
  border-bottom: 1px solid #e4e9f2;
  width: 280px;
  padding: 0;
  text-align: left;
  margin: 0 0 0 8px;
  box-sizing: border-box;
  color: $color-cloud-burst;

  &.collapsed {
    width: 0;
  }

  &::placeholder {
    color: #a1abc1;
    text-align: center;
  }
}

@media only screen and (max-width: 440px) {
  .wrapper {
    justify-content: flex-end;
    z-index: 1;
    background: #fbfcfd;
    position: absolute;
    right: 0;
    top: 21px;
    transition: right ease 0.2s, width ease 0.2s;
    width: 100%;
    justify-content: center;

    &.collapsed {
      width: 38px;
      right: 48px;
    }
  }

  .field {
    width: calc(100vw - 38px - 8px - 8px - 38px);
    transition: width ease 0.2s, margin ease 0.2s;

    &.collapsed {
      width: 0;
      margin-left: 0;
    }
  }
}
