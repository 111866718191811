@import "../../libs/styles/main.scss";

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1116px;
  margin: 0 auto $general-spacing-unit * 5;

  @include breakpoint("mobile") {
    margin-bottom: 0;
  }
}

.buttonWrapper {
  width: 348px;
  max-width: calc(100vw - #{$general-spacing-unit * 3});

  @include breakpoint("mobile") {
    padding: 0;
    width: 348px;
  }
}
