@import '../../../../libs/styles/main.scss';

.wrapper {
  & input:focus {
    border: solid 1px $color-primary-enterprise;
  }
  & > h2 > b,
  a {
    color: $color-primary-enterprise;
  }
}
