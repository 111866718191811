@import "../../libs/styles/main.scss";

.link {
  line-height: 100%;
  color: $color-white;
  text-decoration: none;
  font-weight: 700;
  border-bottom: 5px solid transparent;
  transition: border-color ease 0.15s;
  display: flex;
  align-items: center;

  @include breakpoint("desktop") {
    font-size: 3.5vmin;
    padding: 0.75vmin 0;
    margin: 0.75vmin 0;
    border-bottom-width: 0.5vmin;

    &:hover {
      opacity: 0.5;
    }
  }

  @include breakpoint("tablet") {
    font-size: 3.5vmin;
    padding: 0.75vmin 0;
    margin: 0.75vmin 0;
    border-bottom-width: 0.75vmin;
  }

  @include breakpoint("mobile") {
    font-size: 7.5vw;
    margin: 1.25vw 0;
    padding-top: 1.5vw;
    padding-bottom: 1.5vw;
    border-bottom: 1vw solid transparent;
  }

  svg {
    height: auto;

    @include breakpoint("desktop") {
      width: 3.5vmin;
      margin-right: 1.5vmin;
    }

    @include breakpoint("tablet") {
      width: 4vmin;
      margin-right: 1.5vmin;
    }

    @include breakpoint("mobile") {
      width: 7.5vw;
      margin-right: 1.5vmin;
    }
  }

  &.active {
    border-color: darken($color-eastern-blue, 10%);
  }
}
