@import "../../../../libs/styles/main.scss";

.form {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: $general-spacing-unit * 3;

  p {
    margin: 0 0 $general-spacing-unit;
    font-size: 18px;
    line-height: 28px;
  }

  button {
    margin: 0;
  }
}
