@mixin initial-state {
  transform: translateY(100%);
  opacity: 0;
}
@mixin final-state {
  transform: translateY(0);
  opacity: 1;
}

.wrapper {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 5rem;
  background-color: #E4E9F2;
  z-index: 999;

  p {
    font-size: 18px;
    color: #2E3A59;
    margin: 0;
  
    a {
      color: #1B9B9E;
      font-weight: 800;
    }
  }

  button {
    max-width: 10rem;
    max-height: 2.5rem;
    font-size: 1rem;
    margin: 0;
  }

  @media screen and (max-width: 980px) {
    flex-direction: column;
    gap: 1rem;
    height: auto;
    padding: 1rem 1.5rem;
    text-align: center;

    p {
      font-size: 14px;
    }

    button {
      max-width: 100%;
      max-height: 2.5rem;
      font-size: 1rem;
      margin: 0;
    }
  }
}


.open {
  animation: slideIn 0.7s ease forwards;
}

.close {
  animation: slideOut 0.7s ease forwards;
}

@keyframes slideIn {
  0% {
    @include initial-state;
  }
  100% {
    @include final-state;
  }
}

@keyframes slideOut {
  0% {
    @include final-state;
  }
  100% {
    @include initial-state;
  }
}
