@import "../../../../libs/styles/main.scss";

.firstSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: $general-spacing-unit * 6 $general-spacing-unit * 4;

    &>h2 b,
    &>span {
        color: $color-primary-enterprise;
    }
}

.paragraph {
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    max-width: 500px;
    color: $color-plantation;
}

.commomSection {
    padding: $general-spacing-unit * 6 $general-spacing-unit * 4;

    .leftSide {
        display: flex;
        flex-direction: column;
        align-items: center;

        .btnsDesktop {
            display: none;
        }

        b {
            color: $color-primary-enterprise;
        }
    }

    .rightSide {
        display: flex;
        flex-direction: column;
        gap: $general-spacing-unit;
        margin-top: $general-spacing-unit * 4;
        margin-bottom: $general-spacing-unit * 5;
    }

    &.secondaryLayout {
        background-color: $color-primary-enterprise;
        border-top: solid 16px #E7DF46;

        & .leftSide>span,
        & .leftSide>p,
        & .leftSide b {
            color: $color-white;
        }

        & .leftSide>p {
            color: $color-white;
        }
    }
}

.comparationSection {
    display: flex;
    gap: $general-spacing-unit;
    justify-content: center;
    align-items: flex-start;
}

.fourthSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $color-primary-enterprise;
    border-top: solid 16px #E7DF46;
    padding: $general-spacing-unit * 5 $general-spacing-unit * 3;
    box-sizing: border-box;

    &>span,
    &>p,
    & b {
        color: $color-white;
    }

    &>p {
        color: $color-white;
    }
}

@include breakpoint("mobile-and-tablet-2") {
    .contactSection {
        margin-top: $general-spacing-unit * 10;
        margin-bottom: $general-spacing-unit * 5;
        padding: 0 $general-spacing-unit * 6;
    }

    .FaqSection {
        padding: 0 $general-spacing-unit * 6;
    }

}

@include breakpoint("tablet-and-desktop-2") {

    .firstSection,
    .commomSection {
        padding: $general-spacing-unit * 6;
    }

    .commomSection {
        display: flex;
        gap: $general-spacing-unit * 5;
        justify-content: center;
        height: 650px;
        align-items: center;

        .leftSide {
            align-items: flex-start;

            .btnsDesktop {
                display: flex;
            }
        }

        .btnsMobile {
            display: none;
        }
    }

    .paragraph {
        text-align: left;
    }

    .contactSection {
        margin-top: $general-spacing-unit * 10;
        margin-bottom: $general-spacing-unit * 5;
        padding: 0 $general-spacing-unit * 6;
    }

    .fourthSection {
        padding: $general-spacing-unit * 10 $general-spacing-unit * 5;
    }

    .comparationSection {
        margin-top: $general-spacing-unit * 6;
    }

    .FaqSection {
        padding: 0 $general-spacing-unit * 6;
        margin-top: $general-spacing-unit * 10;
    }
}

@include breakpoint("desktop") {
    .firstSection {
        padding: $general-spacing-unit * 11 0 0;

        &>h2 {
            font-size: 70px;
            line-height: 70px;
        }
    }

    .commomSection {
        display: flex;
        gap: $general-spacing-unit * 10;
        justify-content: center;
        height: 800px;
        align-items: center;
        padding: 0 $general-spacing-unit * 11;

        .leftSide {
            align-items: flex-start;

            .btnsDesktop {
                display: flex;
            }
        }

        .btnsMobile {
            display: none;
        }
    }

    .paragraph {
        text-align: left;
    }

    .contactSection {
        margin-top: $general-spacing-unit * 10;
        margin-bottom: $general-spacing-unit * 5;
    }

    .fourthSection {
        padding: $general-spacing-unit * 10 $general-spacing-unit * 5;
    }

    .comparationSection {
        margin-top: $general-spacing-unit * 6;
    }
}