@import "../../../../libs/styles/main.scss";

.wrapper {
  display: grid;
  row-gap: $general-spacing-unit * 3;

  &.small {
    row-gap: $general-spacing-unit * 2;
  }
}
