@import "../../../libs/styles/main.scss";

.footer {
  padding: $general-spacing-unit * 3 0 $general-spacing-unit;
  border-top: 1px solid #efefef;
  margin: 0;
  box-sizing: border-box;
  font-size: 16px;

  > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    > div:first-child {
      flex-grow: 1;

      > button:first-child {
        margin-bottom: $general-spacing-unit * 2;
      }

      > button:nth-child(2) {
        margin: 0;
      }
    }
  }
}

.footer p {
  font-weight: 700;
  font-size: 14px;
  color: $color-soft-dark;
  margin: 0 0 $general-spacing-unit * 3;
}
