@import "../../../../libs/styles/main.scss";

.wrapper {
  margin-top: $general-spacing-unit * 3;
  margin-left: -$general-spacing-unit * 0.5;
  margin-right: -$general-spacing-unit * 0.5;
  margin-bottom: $general-spacing-unit * 2;

  @include breakpoint("tablet-and-desktop") {
    display: none;
  }
}

.title {
  font-size: 16px;
  color: $color-cloud-burst;
  line-height: 18px;
  margin: 0 0 $general-spacing-unit * 1.5 $general-spacing-unit * 0.5;
}
