@import "../../../../libs/styles/main.scss";

.title {
  font-size: 32px;
  line-height: 1;
  color: $color-cloud-burst;
  margin: 0;

  @include breakpoint("mobile") {
    font-size: 24px;
    line-height: 1;
  }
}
