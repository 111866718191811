@import "../../libs/styles/main.scss";

.wrapper {
  padding: $general-spacing-unit * 3;
  box-sizing: border-box;
  background-color: $color-white;
  border-radius: $general-spacing-unit;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 0 2px 20px 0 rgba(27, 155, 158, 0.1),
    0 2px 20px 0 rgba(151, 151, 151, 0.1);
}

.image {
  display: block;
  margin: 0 auto 24px;
  height: 145px;
  width: auto;

  @include breakpoint(mobile) {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}

.title {
  font-size: 24px;
  line-height: 32px;
  color: #222b45;
  margin: 0 0 16px 0;
}

.infoWrapper {
  margin: 0 0 $general-spacing-unit;
  font-size: 13px;
}

.company {
  color: #1b9b9e;
  font-weight: 600;
}

.date {
  font-weight: 500;
  color: #222b45;
}

.date:before {
  margin: 0 0.25em;
  content: "|";
}

.description {
  display: none;
  display: -webkit-box;
  text-overflow: ellipsis;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  font-size: 16px;
  color: #253c45;
  line-height: 24px;
  margin: 0 0 $general-spacing-unit * 2;
  overflow: hidden;
  box-orient: vertical;
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
}

.location {
  margin-bottom: $general-spacing-unit * 2;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex: 1;
  align-items: flex-start;
}

.footer {
  display: flex;
  flex-direction: row;
}
