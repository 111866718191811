@import "../../../../libs/styles/main.scss";

$header-height: 80px;

.spacer {
  height: $header-height;
  width: 1px;
  display: block;
}

.wrapper {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $color-primary-enterprise;
  z-index: 2;
  box-shadow: 0 2px 20px 0 rgba(27, 155, 158, 0);
  transition: box-shadow ease 0.25s;

  &.floating {
    box-shadow: 0 2px 20px 0 rgba(27, 155, 158, 0.1);
  }
}

.container {
  height: $header-height;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  box-sizing: border-box;
  width: 1092px;

  @include breakpoint("tablet") {
    width: 100%;
    padding: 0 $general-spacing-unit * 3;
  }
}

.link {
  width: max-content;
  text-align: center;
  ;
}

.leftActions,
.rightActions {
  display: flex;
  width: 56px;
}

.rightActions {
  justify-content: flex-end;
}

.backButton {
  background: transparent;
  border: 0;
  height: 56px;
  width: 56px;
  cursor: pointer;
}