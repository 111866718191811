@import "../../libs/styles/main.scss";

.wrapper {
    background-color: $color-aqua-haze;
    width: 32%;
    padding: $general-spacing-unit * 3;
    max-width: 350px;
    border-radius: 10px 0 0 10px;

    &>button {
        margin-top: $general-spacing-unit * 4;
    }

    &.secondaryLayout {
        background-color: $color-heavy-white;

        &>button {
            color: #326B99;
        }
    }
}

.title {
    line-height: 24px;
    text-align: left;
    margin: 0 0 $general-spacing-unit * 4;
}

@include breakpoint("tablet-and-below") {
    .wrapper {
        display: none;
    }
}