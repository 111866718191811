@import "../../libs/styles/main.scss";

@mixin wrapper {
  min-height: calc(100vh - 365px);
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 1rem;

  button {
    width: 19rem;
    max-height: 3.5rem;
  }

  .title {
    color: $color-cloud-burst;
    margin: 0;
  
    b {
      color: $color-eastern-blue;
    }
  }
  
  .text {
    color: $color-cloud-burst;
  
    p {
      margin: 0;
    }
  }
}

.desktopWrapper {
  @include wrapper;

  .title {
    font-size: 3rem;
  }
  
  .text {
    p {
      line-height: 1.75rem;
      font-size: 1.25rem;
    }
  }
}

.mobileWrapper {
  @include wrapper;
  padding-top: 2rem;

  .title {
    font-size: 2.25rem;
  }
  
  .text {
    p {
      line-height: 1.75rem;
      font-size: 1.05rem;
    }
  }
}