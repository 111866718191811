@import "../../../../libs/styles/main.scss";

.searchForm {
  border-radius: 10px;
  background: $color-white;
  padding: $general-spacing-unit * 3 $general-spacing-unit * 5;
  box-sizing: border-box;
  margin: 0 0 $general-spacing-unit * 3;
  display: inline-grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 24px;
  flex-direction: row;
  align-items: center;
  width: 100%;
  box-shadow: 0 2px 20px 0 rgba(27, 155, 158, 0.1),
    0 2px 20px 0 rgba(151, 151, 151, 0.1);

  @include breakpoint("mobile") {
    grid-template-columns: 1fr;
    padding: $general-spacing-unit * 3;
  }
}

.closeJobDescription {
  font-weight: 400;
}
