@import "../../libs/styles/main.scss";

.wrapper {
  max-width: 624px;
  margin: $general-spacing-unit * 4 auto 0;
  color: $color-cloud-burst;

  h1 {
    font-size: 32px;
    line-height: 38px;
    margin-top: 0;
  }

  h2,
  p {
    font-size: 18px;
    line-height: 28px;
  }

  @include breakpoint("mobile-and-tablet") {
    max-width: none;
    padding: 0 $general-spacing-unit * 9;
  }

  @include breakpoint("mobile") {
    padding: 0 $general-spacing-unit * 7;
  }
}

.preffix {
  font-size: 16px;
  line-height: 20px;
  border-bottom: 1px solid $color-bali-hai;
  padding-bottom: 25px;
  margin-bottom: $general-spacing-unit * 4;
  display: block;
  color: $color-eastern-blue;
  text-transform: uppercase;
  font-weight: 700;
}
