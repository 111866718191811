@import '../../libs/styles/main.scss';

.wrapper {
  background: $color-eastern-blue
    calc(100% - #{$general-spacing-unit} * 20 - 20vmin) center no-repeat;
  background-size: $general-spacing-unit * 50 auto;
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform ease 0.25s, opacity ease 0.25s;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  opacity: 0.7;
  transform: translateY(-100%);

  &.isVisible {
    opacity: 1;
    transform: translateY(0);
    transition: transform ease 0.25s, opacity ease 0.25s;
  }

  &.isHidden {
    display: none;
  }

  @include breakpoint('mobile-and-tablet') {
    background-image: none !important;
  }
}

.closeButton {
  background: transparent;
  width: 38px;
  height: 38px;
  padding: 0;
  border: none;
  overflow: hidden;
  position: absolute;
  right: $general-spacing-unit * 6;
  top: $general-spacing-unit * 6;
  cursor: pointer;
  transition: opacity ease 0.15s;

  @include breakpoint('mobile-and-tablet') {
    right: $general-spacing-unit;
    top: $general-spacing-unit * 2.5;
  }

  &:hover {
    opacity: 0.5;
  }

  svg {
    display: block;
    margin: auto;
    transform: rotate(45deg);
  }
}

.navigation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  padding: 0 18vmin;
  box-sizing: border-box;

  @include breakpoint('tablet') {
    padding: $general-spacing-unit * 10;
  }

  @include breakpoint('mobile') {
    padding: $general-spacing-unit * 6;
  }
}

.logoutButton {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  right: $general-spacing-unit * 8;
  bottom: $general-spacing-unit * 8;

  button {
    background-color: transparent;
    padding: 0;
    color: $color-white;
    font-size: 24px;
    border: none;
    font-weight: 700;
    font-family: $font-source-sans;
    cursor: pointer;

    & svg {
      margin-right: 8px;
      font-weight: 600;
    }
  }

  @media (max-width: 768px) {
    right: 43%;
  }
}

.text {
  text-align: right;
  color: $color-white;
  position: absolute;
  bottom: 100%;
  right: 0;
  width: 100%;
  font-size: 16px;
  margin-bottom: $general-spacing-unit * 2;
}

.split {
  margin: $general-spacing-unit * 3 0;
  background: $color-white;
  display: block;
  height: 2px;
  width: 100%;

  @include breakpoint('mobile') {
    margin: 2vw 0;
  }
}

.icon {
  margin-right: 10px;
  width: 28px;
}
