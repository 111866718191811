@import "../../../../libs/styles/main.scss";

.wrapper {
  border-radius: 10px;
  background: $color-white;
  box-shadow: 0 2px 20px 0 rgba(27, 155, 158, 0.1),
    0 2px 20px 0 rgba(151, 151, 151, 0.1);
  padding: $general-spacing-unit * 5 $general-spacing-unit * 3;
  box-sizing: border-box;
  text-align: center;
  margin: $general-spacing-unit * 2 0 $general-spacing-unit * 4;
  position: relative;
}

.title {
  font-weight: $font-weight-semibold;
  font-size: 24px;
  line-height: 1;
  color: $color-cloud-burst;
  margin: 0 0 $general-spacing-unit * 2;
}

.subtitle {
  font-weight: $font-weight-semibold;
  font-size: 16px;
  line-height: 1;
  color: $color-cloud-burst;
  margin: 0;
}

.backButton {
  font-size: 16px;
  border: none;
  background: transparent;
  color: $color-eastern-blue;
  font-family: $font-source-sans;
  font-weight: $font-weight-bold;
  position: absolute;
  left: $general-spacing-unit * 5;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: opacity ease 0.25s;

  &:hover {
    opacity: 0.75;
  }

  svg {
    height: 10px;
    width: auto;
    margin-right: $general-spacing-unit;
  }
}
