@import "../../libs/styles/main.scss";

.wrapper {
  p {
    display: block;
    font-weight: 700;
    font-size: 12px;
    color: $color-cloud-burst;
    line-height: 16px;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: $general-spacing-unit;
  }
}

.checkBoxFlex {
  display: flex;
  gap: 1rem;
}

.error {
  font-size: 12px;
  color: #d72e2f;
  line-height: 16px;
  margin-top: 2px;
}