@import "../../../../libs/styles/main.scss";

.wrapper {
  position: relative;
  text-align: right;
  padding: $general-spacing-unit;
  flex: 1;
  min-width: max-content;

  &.highlighted {
    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 10px;
      height: 10px;
      background: $color-valencia;
      display: block;
      border-radius: 10px;
      z-index: 0;
    }
  }
}

.secondaryLayout {
  .value {
    color: $color-primary-enterprise;
  }
}

.value {
  font-weight: $font-weight-bold;
  font-size: 20px;
  color: $color-eastern-blue;
  line-height: 20px;
  text-decoration: underline;
  position: relative;
  z-index: 0;
  padding-right: $general-spacing-unit;
}

.input {
  appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}